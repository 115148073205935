import { cn } from "@/lib/utils";

export const TabButton = ({
  title,
  id,
  handleActiveTab,
  active,
}: {
  title: string;
  id: string;
  handleActiveTab: (id: string) => void;
  active: boolean;
}) => {
  return (
    <button
      onClick={() => handleActiveTab(id)}
      className={cn(
        "px-4 py-2 rounded-full leading-[1.5] font-sairaSemiCondensed font-[500]",
        active ? "bg-royal-blue text-white" : "bg-[#0000001A]"
      )}
    >
      {title}
    </button>
  );
};
