/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRouter } from "next/navigation";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "sonner";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import _debounce from "lodash/debounce";

import { Form } from "@/components/ui/form";
import { IDict } from "@/components/other/constants";
import FormGroupWrapper from "./FormGroupWrapper";
import { cn } from "@/lib/utils";
import AppText from "@/components/other/AppText";
import AppButton from "@/components/buttons/AppButton";
import { checkZippopotam, getGeocodingAddress, preparePayloadRAQ } from "@/components/form/helpers";
import {
  FieldCalendar,
  FieldDropdown,
  FieldDropdownIndustries,
  FieldFallbackIndustries,
  FieldGoogleAddress,
  FieldOptionsCards,
  FieldPhone,
  FieldText,
  FieldTextArea,
} from "./FormElements";
import { createDynamicSchemaSupportThirdTab } from "./schemaHelper";
import { FormFooterRecaptchaPolicy } from "../recaptcha/FormFooterRecaptchaPolicy";
import { COUNTRY_SHORT_NAME } from "./constants";

interface ICheckBox {
  [key: string]: boolean;
}

/**
 * Form component for requesting quotes
 * @component
 * @param {Object} props - Component props
 * @param {any} props.tab - Tab configuration data
 * @param {IDict} props.dict - Dictionary for translations/text
 * @param {string} props.zipName - Name of the zip code field
 * @param {string} props.countryName - Name of the country field
 * @returns {JSX.Element} Rendered form component
 */
const FormTabRequestQuote = ({
  tab,
  dict,
  zipName,
  countryName,
}: {
  tab: any;
  dict: IDict;
  zipName: string;
  countryName: string;
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const formSchema = z.object({});

  const [selectedPlace, setSelectedPlaceBuy] = useState<google.maps.places.PlaceResult | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [dynamicFormSchema, setDynamicFormSchema] = useState<z.ZodSchema>(formSchema);
  const [radioCards, setRadioCards] = useState<{ [key: string]: string }>(() => {
    const defaults = tab.radioButtonsDefaultValues || {};
    return Object.fromEntries(
      Object.entries(defaults)
        .filter(([, v]) => v !== undefined)
        .map(([k, v]) => [k, String(v)])
    );
  });
  const [checkBoxCard, setCheckBoxCard] = useState<ICheckBox>(tab.checkboxesDefaultValues || {});

  const siteAddress = useRef("");
  const googlePlacesTouched = useRef(false);

  const router = useRouter();

  const formDefaultValues = useMemo(() => {
    const res: any = { cards: "", [tab?.industryFallback?.name || "Other Industry"]: "" };

    (tab.groups || []).forEach((group: any) => {
      (group.rows || []).forEach((row: any) => {
        (row.fields || []).forEach((field: any) => {
          res[field.name] = "";
        });
      });
    });

    return res;
  }, [tab]);

  const form = useForm<z.infer<typeof dynamicFormSchema>>({
    resolver: zodResolver(dynamicFormSchema),
    defaultValues: formDefaultValues,
    mode: "onChange",
    reValidateMode: "onChange",
  });

  /**
   * Fetches and sets address data from selected Google Place
   * @async
   */
  const getSiteData = async () => {
    if (selectedPlace?.formatted_address) {
      const geoRes = await getGeocodingAddress(selectedPlace?.formatted_address);

      form.setValue("site_address_country", geoRes.country);
      form.setValue("site_address_state", geoRes.state);
      form.setValue("site_address_zip", geoRes.zipCode);
      form.setValue("site_address_city", geoRes.city);

      if (geoRes.address) {
        if (geoRes.streetNumber) {
          siteAddress.current = `${geoRes.streetNumber} ${geoRes.address}`;
        } else {
          siteAddress.current = `${geoRes.address}`;
        }
      } else if (geoRes.formatted_address) {
        siteAddress.current = geoRes.formatted_address;
      } else {
        siteAddress.current = geoRes.neighborhood;
      }

      setTimeout(() => {
        form.trigger("site_address_country");
        form.trigger("site_address_state");
        form.trigger("site_address_zip");
        form.trigger("site_address_city");
      }, 500);
    }
  };

  useEffect(() => {
    if (selectedPlace && selectedPlace?.formatted_address) {
      getSiteData();
      form.setValue("Address", "valid", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    } else {
      if (googlePlacesTouched.current) {
        form.setValue("Address", "", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlace]);

  useEffect(() => {
    if (dynamicFormSchema) {
      setTimeout(() => {
        form.trigger("site_address_zip");
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dynamicFormSchema]);

  const watchIndustry = form.watch("Industries");
  const watchZipName = form.watch(zipName);
  const watchCountryName = form.watch(countryName);

  /**
   * Updates form schema based on current form state and validation rules
   */
  const updateSchema = () => {
    const baseSchema = createDynamicSchemaSupportThirdTab(tab.groups);

    const formValues = form.getValues();

    const updatedSchema = z.object({
      ...baseSchema,
      [tab?.industryFallback?.name || "Other Industry"]:
        watchIndustry === "Other"
          ? z
              .string()
              .min(tab?.industryFallback?.min || 2, tab?.industryFallback?.minError || "Minimum 2 characters")
              .max(tab?.industryFallback?.max || 50, tab?.industryFallback?.maxError || "Maximum 50 characters")
          : z.string().optional(),
      site_address_zip: z
        .string()
        .optional()
        .refine(
          async (value: any) => {
            // Handle case when zip code or country is empty
            // if (
            //   !value ||
            //   value.trim() === "" ||
            //   !formValues.site_address_country ||
            //   formValues.site_address_country.trim() === ""
            // )
            //   return true;

            // get country code
            const countryCode = COUNTRY_SHORT_NAME[formValues.site_address_country.toLowerCase().trim()];

            // if country code is not found, return false
            if (!countryCode) {
              form.setError("site_address_zip", { message: "Invalid ZIP code" });
              return false;
            }

            // remove all spaces and dashes from zip code
            let newZip = value.replaceAll(" ", "").replaceAll("-", "");
            let isValidZip = true;

            if (countryCode === "us") {
              if (newZip.length === 5 || newZip.length === 9) {
                newZip = newZip.slice(0, 5);
              } else {
                isValidZip = false;
              }
            } else if (countryCode === "ca") {
              if (newZip.length <= 6 && newZip.length >= 3) {
                newZip = newZip.slice(0, 3);
              } else {
                isValidZip = false;
              }
            }

            // if flag is false, return false
            if (!isValidZip) {
              form.setError("site_address_zip", { message: "Invalid ZIP code" });
              return false;
            }

            // check if zip code is valid with zippopotam api
            const isValidZippopotam = await checkZippopotam(newZip, countryCode);

            if (!isValidZippopotam.valid) {
              form.setError("site_address_zip", { message: "Invalid ZIP code" });
              return false;
            }

            // check if state matches - needs additional logic, watching state changes
            // if (isValidZippopotam.data?.places?.[0]?.["state"]) {
            //   if (isValidZippopotam.data?.places?.[0]?.["state"] !== formValues.site_address_state) {
            //     form.setError("site_address_zip", { message: "ZIP code doesn't match state" });
            //     return false;
            //   }
            // }

            // if everything passes return true
            return true;
          },
          {
            message: "Invalid ZIP code",
            // path: ["site_address_zip"],
          }
        ),
    });

    setDynamicFormSchema(updatedSchema);
  };

  useEffect(() => {
    watchIndustry !== "" && updateSchema();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchIndustry]);

  /**
   * Debounced function to update schema
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(
    _debounce(() => {
      updateSchema();
    }, 500),
    []
  );

  useEffect(() => {
    debounceFn();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchZipName, watchCountryName]);

  useEffect(() => {
    const createDynamicSchema = () => {
      const zObj = createDynamicSchemaSupportThirdTab(tab.groups);

      const formSchema = z
        .object({
          ...zObj,
        })
        .refine(
          (data) => {
            if (data.Industries.toLowerCase() === "other") {
              return (
                !!data[tab?.industryFallback?.name || "Other Industry"] &&
                data[tab?.industryFallback?.name || "Other Industry"].trim() !== ""
              );
            }
            return true;
          },
          {
            message: "Other Industry is required when 'Other' is selected",
            path: [tab?.industryFallback?.name || "Other Industry"],
          }
        );

      setDynamicFormSchema(formSchema);
    };

    createDynamicSchema();
  }, [tab]);

  /**
   * Handles blur event for delivery address field
   */
  const deliveryAddressBlur = () => {
    googlePlacesTouched.current = true;
  };

  /**
   * Handles changes to delivery address input
   * @param {ChangeEvent<HTMLInputElement>} e - Change event
   */
  const handleDeliveryAddressOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      setSelectedPlaceBuy(null);
    }
  };

  /**
   * Handles checkbox state changes for option cards
   * @param {string} item - Checkbox identifier
   * @param {boolean} checked - New checkbox state
   */
  const handleCheckboxes = (item: string, checked: boolean) => {
    const tempCheckBoxes: any = { ...checkBoxCard, [item]: checked };
    const tempRadioButtons = { ...radioCards };

    setCheckBoxCard(tempCheckBoxes);

    const isRadioChecked = Object.keys(tempRadioButtons).filter((item: string) => !!tempRadioButtons[item]);
    const isCheckboxChecked = Object.keys(tempCheckBoxes).filter((item: string) => !!tempCheckBoxes[item]);

    form.setValue("cards", isRadioChecked.length > 0 || isCheckboxChecked.length > 0 ? "valid" : "");
    form.trigger("cards");
  };

  /**
   * Updates radio button state and validates card selection
   * @param {string} group - Radio button group identifier
   * @param {string} value - Selected radio button value
   * @param {boolean} del - Whether to delete the selection
   */
  const updateRadioButtons = (group: string, value: string, del: boolean) => {
    const temp = { ...radioCards };
    if (del) {
      temp[group] = "";

      setRadioCards(temp);
    } else {
      temp[group] = value;
      setRadioCards(temp);
    }

    const isRadioChecked = Object.keys(temp).filter((item: string) => !!temp[item]);

    const isCheckboxChecked: any = Object.keys(checkBoxCard).filter((item: string) => !!checkBoxCard[item]);

    form.setValue("cards", isRadioChecked.length > 0 || isCheckboxChecked.length > 0 ? "valid" : "");
    form.trigger("cards");
  };

  /**
   * Handles form submission with reCAPTCHA verification
   * @async
   */
  async function onSubmit() {
    if (!executeRecaptcha) {
      toast.error("Error submitting request - reCAPTCHA not loaded");
      return;
    }

    setSubmitting(true);

    const token = await executeRecaptcha("submit_request_quote");

    const formValues = form.getValues();

    const recaptchaResponse = await fetch("/api/recaptcha", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token }),
    });

    const recaptchaData = await recaptchaResponse.json();

    if (!recaptchaData.success) {
      console.log(recaptchaData);
      toast.error("reCAPTCHA verification failed");
      setSubmitting(false);
      return;
    }

    console.log("reCAPTCHA verification successful", recaptchaData);

    const payload = await preparePayloadRAQ(
      formValues,
      radioCards,
      checkBoxCard,
      siteAddress?.current,
      tab?.industryFallback?.name || "Other Industry"
    );

    try {
      const response = await fetch("/api/request-quote", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      // request is valid if data is empty string
      if (data.error || data.message) {
        console.log(data);
        toast.error("Error submitting request");
        setSubmitting(false);
      } else {
        router.push("/request-quote/thank-you");
      }
    } catch (error) {
      console.log(error);
      setSubmitting(false);
    }
  }

  /**
   * Triggers form submission by clicking hidden submit button
   */
  const handleFormSubmit = () => {
    const btn = document.getElementById("targetSubmit");

    btn && btn.click();
  };

  return (
    <>
      <div className="lg:col-span-10 lg:col-start-2 lg:px-10 pt-[50px] col-span-4 px-6 bg-white">
        <Form {...form}>
          <form className="flex flex-col bg-white" autoComplete="off" onSubmit={form.handleSubmit(onSubmit)}>
            {(tab.groups || []).map((group: any, index: number) => (
              <FormGroupWrapper
                key={group.id}
                groupHeading={group.heading}
                borderNumber={index + 1}
                groupDescription={group.description}
                noBorder={index === tab.groups.length - 1}
                noBottomPadding={group.groupType === "fieldGroupOptionCards"}
              >
                {group.groupType === "fieldGroupOptionCards" ? (
                  <FieldOptionsCards
                    group={group}
                    key={group.id}
                    control={form.control}
                    name="cards"
                    handleCheckboxes={handleCheckboxes}
                    checkBoxCard={checkBoxCard}
                    updateRadioButtons={updateRadioButtons}
                    radioCardsState={radioCards}
                  />
                ) : (
                  (group.rows || []).map((row: any) => (
                    <div key={row.id} className="lg:flex-row flex flex-col gap-6">
                      {row.fields?.length &&
                        row.fields.map((field: any) => {
                          if (field.fieldType === "fieldText") {
                            return (
                              <FieldText
                                key={field.id}
                                control={form.control}
                                name={field.name}
                                placeholder={field.placeholder}
                                loading={false}
                                label={field.label}
                              />
                            );
                          } else if (field.fieldType === "fieldPhone") {
                            return (
                              <FieldPhone
                                key={field.id}
                                control={form.control}
                                name={field.name}
                                placeholder={field.placeholder || "Enter your phone number..."}
                                loading={false}
                                label={field.label}
                              />
                            );
                          } else if (field.fieldType === "fieldCalendar") {
                            return (
                              <FieldCalendar
                                key={field.id}
                                control={form.control}
                                name={field.name}
                                placeholder={field.placeholder}
                                loading={false}
                                label={field.label}
                              />
                            );
                          } else if (field.fieldType === "fieldGoogleAddress") {
                            return (
                              <FieldGoogleAddress
                                key={field.id}
                                control={form.control}
                                name={field.name}
                                placeholder={field.placeholder}
                                loading={false}
                                label={field.label}
                                deliveryAddressBlur={deliveryAddressBlur}
                                setSelectedPlaceBuy={setSelectedPlaceBuy}
                                handleDeliveryAddressOnChange={handleDeliveryAddressOnChange}
                              />
                            );
                          } else if (field.fieldType === "fieldTextArea") {
                            return (
                              <FieldTextArea
                                key={field.id}
                                control={form.control}
                                name={field.name}
                                loading={false}
                                placeholder={field.placeholder}
                              />
                            );
                          } else if (field.fieldType === "fieldDropdown") {
                            if (field.name === "Industries") {
                              // special case of fieldDropdown
                              return (
                                <div key={field.id} className="flex flex-col flex-1 gap-6">
                                  <FieldDropdownIndustries
                                    control={form.control}
                                    name={field.name}
                                    placeholder={field.placeholder}
                                    label={field.label}
                                    options={field.options}
                                  />
                                  <FieldFallbackIndustries
                                    watchIndustry={watchIndustry}
                                    control={form.control}
                                    name={tab?.industryFallback?.name || "Other Industry"}
                                    placeholder={field.placeholder}
                                    label={field.label}
                                  />
                                </div>
                              );
                            } else {
                              return (
                                <FieldDropdown
                                  key={field.id}
                                  control={form.control}
                                  name={field.name}
                                  placeholder={field.placeholder}
                                  label={field.label}
                                  options={field.options}
                                />
                              );
                            }
                          } else if (field.fieldType === "fieldEmail") {
                            return (
                              <FieldText
                                key={field.id}
                                control={form.control}
                                name={field.name}
                                placeholder={field.placeholder}
                                loading={false}
                                label={field.label}
                              />
                            );
                          }
                          return null;
                        })}
                    </div>
                  ))
                )}
              </FormGroupWrapper>
            ))}

            {/******************* SUBMIT BUTTON ************************ */}
            <div className="bg-white flex flex-row-reverse flex-wrap justify-start px-[10] mt-10">
              {submitting ? (
                <button
                  id="button-request-quote-buy"
                  className={`bg-black-20 text-black-60 px-10 disabled:cursor-not-allowed py-3
               transition-all duration-200 rounded-md outline-none gap-3 w-fit select-none flex items-center`}
                  disabled
                >
                  <div
                    className={`inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid 
                  border-current border-e-transparent align-[-0.125em] 
                  motion-reduce:animate-[spin_1.5s_linear_infinite]`}
                  ></div>
                  <AppText type={"BUTTON_SMALL"} className={cn("transition whitespace-nowrap opacity-60")}>
                    {dict.submitting}
                  </AppText>
                </button>
              ) : (
                <>
                  <button id="targetSubmit" className="hidden" aria-label="submit target" type="submit"></button>

                  <AppButton
                    intent="primary"
                    label={dict.submit}
                    type="button"
                    className="px-10"
                    onMouseDown={handleFormSubmit}
                  />
                </>
              )}
            </div>
          </form>
        </Form>
        <FormFooterRecaptchaPolicy />
      </div>
    </>
  );
};

export default FormTabRequestQuote;
