const demoContentPayloadForRichTextPreview = {
  data: {},
  content: [
    {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: "Safe and secure weather-resistant work environments",
                  nodeType: "text",
                },
              ],
              nodeType: "paragraph",
            },
          ],
          nodeType: "list-item",
        },
        {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: "Large collaboration and common area",
                  nodeType: "text",
                },
              ],
              nodeType: "paragraph",
            },
          ],
          nodeType: "list-item",
        },
        {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: "Private office capability ",
                  nodeType: "text",
                },
              ],
              nodeType: "paragraph",
            },
          ],
          nodeType: "list-item",
        },
      ],
      nodeType: "unordered-list",
    },
    {
      data: {},
      content: [
        {
          data: {},
          marks: [],
          value: "",
          nodeType: "text",
        },
      ],
      nodeType: "paragraph",
    },
  ],
  nodeType: "document",
};

export const componentDataMockExample = {
  label: "Our solutions",
  heading: "Solutions for every need",
  productOfferingsArray: [
    {
      id: "1",
      internalLabel: "Product Offering - Mobile Offices",
      tabLabel: "Mobile Offices",
      imageSrc: "/placeholders/no-preview.png",
      content: demoContentPayloadForRichTextPreview,
      ctaLabel: "View all",
      ctaLink: "/work-meet-sell/mobile-offices/1",
    },
    {
      id: "2",
      internalLabel: "Product name 2 - Mobile Offices",
      tabLabel: "Product name 2",
      imageSrc: "/placeholders/no-preview.png",
      content: demoContentPayloadForRichTextPreview,
      ctaLabel: "View all",
      ctaLink: "/work-meet-sell/mobile-offices/2",
    },
    {
      id: "3",
      internalLabel: "Product name 3 - Mobile Offices",
      tabLabel: "Banana 3",
      imageSrc: "/placeholders/no-preview.png",
      content: demoContentPayloadForRichTextPreview,
      ctaLabel: "View all",
      ctaLink: "/work-meet-sell/mobile-offices",
    },
    {
      id: "4",
      internalLabel: "Product name 4 - Mobile Offices",
      tabLabel: "Product name 4",
      imageSrc: "/placeholders/no-preview.png",
      content: demoContentPayloadForRichTextPreview,
      ctaLabel: "View all",
      ctaLink: "/work-meet-sell/mobile-offices",
    },
    {
      id: "5",
      internalLabel: "Product name 5 - Mobile Offices",
      tabLabel: "Product name 5",
      imageSrc: "/placeholders/no-preview.png",
      content: demoContentPayloadForRichTextPreview,
      ctaLabel: "View all",
      ctaLink: "/work-meet-sell/mobile-offices",
    },
    {
      id: "6",
      internalLabel: "Product name 6 - Mobile Offices",
      tabLabel: "Product name 6",
      imageSrc: "/placeholders/no-preview.png",
      content: demoContentPayloadForRichTextPreview,
      ctaLabel: "View all",
      ctaLink: "/work-meet-sell/mobile-offices",
    },
  ],
};
