import AppText from "@/components/other/AppText";
import { cn } from "@/lib/utils";

const FormGroupWrapper = ({
  borderNumber = 1,
  noBorder = false,
  groupHeading,
  groupDescription,
  children,
  noBottomPadding,
}: {
  borderNumber: number;
  noBorder?: boolean;
  groupHeading: string;
  groupDescription?: string;
  children: React.ReactNode;
  noBottomPadding?: boolean;
}) => {
  const heading = groupHeading.split("*").map((item) => item.trim());

  return (
    <div className="flex gap-[18px]">
      <div className=" sm:flex flex-col items-center hidden">
        <div className="h-8 w-8 flex items-center justify-center rounded-full border-[1px] border-[#333]">
          {borderNumber}
        </div>
        <div className={cn("w-[1px] flex-1", noBorder ? "bg-transparent" : "bg-[#333]")} />
      </div>
      <div className="flex-1 flex flex-col pb-[60px] gap-[12px]">
        <AppText type="TITLE_MEDIUM" className="flex flex-col">
          {heading.map((item: string) => (
            <span key={item}>{item}</span>
          ))}
        </AppText>
        {groupDescription && (
          <AppText type="BODY_MEDIUM" className={cn(noBottomPadding ? "pb-0" : "pb-3")}>
            {groupDescription}
          </AppText>
        )}
        {children}
      </div>
    </div>
  );
};

export default FormGroupWrapper;
