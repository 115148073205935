/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { useEffect, useRef, useState } from "react";
import { ChevronRight } from "lucide-react";
import Image from "next/image";

import { Select } from "../other/Select";
import Pagination from "../other/Pagination";
import { ITEMS_PER_PAGE } from "../other/constants";
import ContentLayout from "../layouts/ContentLayout";
import { SearchInput } from "../other/SearchInput";
import AppText from "../other/AppText";
import CustomLink from "../other/CustomLink";
import { cn } from "@/lib/utils";
import { AppIconButton } from "../buttons/AppIconButton";

import { WS_PLACEHOLDER_BLUR_DATA } from "@/mocks/constants";

interface ICaseStudy {
  id?: string;
  dict: { [key: string]: string };
  industries: any;
  solutions: any;
  list: any;
  totalSum: number;
  storybook?: boolean;
  paddingBottom?: string;
}

const CaseStudyList: React.FC<ICaseStudy> = ({
  dict,
  industries,
  solutions,
  list,
  totalSum,
  id,
  storybook,
  paddingBottom,
}) => {
  const [cards, setCards] = useState<any>(list);
  const [allIndustries] = useState<{ name: string; value: string }[] | null>(industries);
  const [allSolutions] = useState<{ name: string; value: string }[] | null>(solutions);
  const [selectedIndustry, setSelectedIndustry] = useState<any>(industries[0]);
  const [selectedSolution, setSelectedSolution] = useState<any>(solutions[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [total, setTotal] = useState<number>(totalSum);
  const [triggerSearchClear, setTriggerSearchClear] = useState(0);
  const [loading, setLoading] = useState(false);
  const scrollRef: any = useRef();
  const doSequential = useRef(false);

  const lastPage = Math.ceil(total / ITEMS_PER_PAGE);
  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };

  const fetchData = (page: number) => {
    if (!doSequential.current) return;

    const top = scrollRef?.current?.offsetTop;
    window.scrollTo({ top: top - 100, behavior: "smooth" });

    (async () => {
      try {
        setLoading(true);
        const aiSysID = selectedIndustry.value === "allIndustries" ? "" : selectedIndustry.value;
        const asSysID = selectedSolution.value === "allSolutions" ? "" : selectedSolution.value;

        const res = await fetchContentful(aiSysID, asSysID, page, searchQuery, dict.read_more);

        if (res.error) {
          console.log(res.error || res.error.message);
          // ! throw toast?!
        }

        setTotal(res.total);
        setCards(res.cardsList);
      } catch (err) {
        console.log("Error occurred when fetching filters");
        console.log(err);
      } finally {
        setLoading(false);
      }
    })();
  };

  useEffect(() => {
    fetchData(currentPage - 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    setTimeout(() => {
      doSequential.current = true;
    }, 1000);
  }, []);

  useEffect(() => {
    // fetchData(currentPage - 1)

    setCurrentPage(1);
    fetchData(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, selectedIndustry, selectedSolution]);

  const popularIndustries = () => {
    const popular = allIndustries?.slice(1, 5);

    return (popular || []).map((pop) => (
      <button
        id="button-case-study-list"
        key={pop.value}
        onClick={() => setIndustry(pop.value)}
        className="px-4 py-[6px] rounded-[5px] border-[1px] border-slate-10 outline-none focus-visible:outline-dijon hover:bg-black-5"
      >
        <AppText type="BUTTON_SMALL">{pop.name}</AppText>
      </button>
    ));
  };

  const setIndustry = (value: string) => {
    const filtered = (allIndustries || []).find((ind: any) => ind.value === value);

    if (filtered && filtered.value !== selectedIndustry.value) {
      setSelectedIndustry(filtered);
      setSelectedSolution(allSolutions?.[0] || {});
      setTriggerSearchClear((prev) => prev + 1);
      handleSearch("");
    }
  };

  const handleSearchString = () => {
    if (searchQuery) {
      return `"${searchQuery}"`;
    }

    let res = "";

    const ind = selectedIndustry.value !== "allIndustries" ? selectedIndustry.name : "";
    const sol = selectedSolution.value !== "allSolutions" ? selectedSolution.name : "";

    if (ind && sol) {
      res = `${ind} ${dict.and} ${sol}`;
    } else if (ind) {
      res = ind;
    } else {
      res = sol;
    }

    return res;
  };

  return (
    <ContentLayout bgColorClass="bg-white" id={id} paddingBottom={paddingBottom}>
      {!storybook && (
        <div className="sm:gap-6 flex flex-col gap-4 pb-8" ref={scrollRef}>
          <div className="sm:flex-row sm:gap-6 sm:items-center flex flex-col justify-between gap-4">
            <div className="w-full sm:max-w-[386px] ">
              <Select
                selectedItem={selectedIndustry}
                setSelected={setSelectedIndustry}
                options={industries}
                disabled={!allIndustries || !selectedIndustry}
              />
            </div>

            <div className="w-full sm:max-w-[386px] ">
              {allSolutions && !!selectedSolution && (
                <Select
                  selectedItem={selectedSolution}
                  setSelected={setSelectedSolution}
                  options={solutions}
                  disabled={!allSolutions || !selectedSolution}
                />
              )}
            </div>
            <div className="w-full sm:max-w-[386px] ">
              <SearchInput onSearch={handleSearch} clearSearch={triggerSearchClear} />
            </div>
          </div>
          <div className="flex text-[14px]">
            {searchQuery ? (
              cards.length ? (
                <>
                  <span>
                    {`Showing ${(currentPage - 1) * ITEMS_PER_PAGE + 1}-${
                      (currentPage - 1) * ITEMS_PER_PAGE + cards.length
                    }  of ${total} results for`}
                  </span>
                  <span className="font-semibold">&nbsp;&apos;{searchQuery}&apos;</span>
                </>
              ) : null
            ) : (
              total > 0 && (
                <span>
                  {total} {total === 1 ? dict.result : dict.results}
                </span>
              )
            )}
          </div>
        </div>
      )}

      {loading ? (
        <>
          <div className="flex items-center">
            <span className="searchDots bg-white rounded-full h-3 w-3 m-2 animate-[dot_1s_ease-in-out_infinite]"></span>
            <span className="searchDots bg-white rounded-full h-3 w-3 m-2 animate-[dot_1s_ease-in-out_infinite]"></span>
            <span className="searchDots bg-white rounded-full h-3 w-3 m-2 animate-[dot_1s_ease-in-out_infinite]"></span>
          </div>
        </>
      ) : !cards.length && !total ? (
        <div className="flex flex-col">
          <AppText type="HEADLINE_SMALL" className="mb-4">
            {`${dict.sorryNoResults}  ${handleSearchString()}`}
          </AppText>
          <AppText type="BODY_MEDIUM" className="text-black-80 mb-14">
            {dict.tryDifferentIndustryAndSolution}
          </AppText>
          <AppText type="TITLE_LARGE" className="mb-6">
            {dict.filterIndustries}
          </AppText>

          <div className="flex flex-wrap gap-2">{popularIndustries()}</div>
        </div>
      ) : (
        <div className="max-w-[1328px] mx-auto grid grid-cols-1 2xl:grid-cols-3 sm:grid-cols-2 gap-4 xl:gap-10 md:gap-6 ">
          {cards.map((study: any) => {
            return (
              <CustomLink
                key={study.id}
                href={study.articleUrl}
                className="flex flex-col overflow-hidden transition h-[100%] group"
              >
                <div className="relative w-full overflow-hidden aspect-[16/9]">
                  {/** main card image */}
                  <Image
                    fill
                    placeholder="blur"
                    blurDataURL={study.placeholder}
                    sizes="(max-width: 640px) 100vw,(max-width: 1536px) 50vw, 450px"
                    className="group-hover:scale-105 object-cover transition"
                    src={study.imgSrc}
                    alt={study.imgAlt}
                  />

                  {study.brandSrc && (
                    <div className="bottom-4 left-4 absolute lg:w-[159px] lg:h-[82px] w-[130px] h-[67px] bg-white flex justify-center items-center">
                      <Image
                        className="object-cover w-full"
                        sizes="100vw"
                        width={0}
                        height={0}
                        src={study.brandSrc}
                        alt={study.brandAlt}
                      />
                    </div>
                  )}
                </div>

                <div className="flex flex-col items-start flex-1 py-4">
                  {study.label && (
                    <AppText className={cn("mb-2 text-royal-blue")} type="LABEL_LARGE">
                      {study.label}
                    </AppText>
                  )}

                  <AppText className={cn("line-clamp-2 mb-4")} type="TITLE_LARGE">
                    {study.title}
                  </AppText>

                  <AppText className={cn("line-clamp-3 mb-4")} type="BODY_MEDIUM">
                    {study.content}
                  </AppText>

                  <AppIconButton
                    tabIndex={-1}
                    intent="ghost"
                    label={study.buttonLabel}
                    icon={<ChevronRight size={20} aria-label="chevron right icon" />}
                    iconOnRightSide
                    className={cn("mt-auto")}
                  />
                </div>
              </CustomLink>
            );
          })}
        </div>
      )}

      {!loading && total > 12 && (
        <div className="flex justify-center px-6 py-[48px] mt-8">
          <Pagination currentPage={currentPage} lastPage={lastPage} setCurrentPage={setCurrentPage} />
        </div>
      )}
    </ContentLayout>
  );
};

export default CaseStudyList;

const fetchContentful = async (aiSysID: string, asSysID: string, skip: number, query: string, read_more: string) => {
  const payload = {
    content_type: "caseStudy",
    include: 1,
    skip: skip * 12,
    limit: 12,
    "fields.associatedIndustry.sys.id": aiSysID,
    "fields.associatedSolution.sys.id": asSysID,
    query,
    select: [
      "sys.id",
      "fields.title",
      "fields.featuredImage",
      "fields.excerpt",
      "fields.slug",
      "fields.associatedIndustry",
      "fields.featuredBrand",
    ],
  };

  try {
    const data = await fetch("/api/contentful", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });

    const res = await data.json();

    if (res.error) {
      return { cardsList: [], total: 0, error: res.error.message };
    }

    const cardsList = await Promise.all(
      (res.items || []).map(async (item: any) => {
        const _brandSrc = item.fields?.featuredBrand?.[0]?.thumbnails?.transformBaseUrl;
        const _imgSrc = item.fields?.featuredImage?.[0]?.thumbnails?.transformBaseUrl;

        const tmp = {
          id: item.sys.id || "",
          brandSrc: _brandSrc ? `${_brandSrc}?io=transform:fit,height:82,width:159` : "",
          // brandSrc: _brandSrc ?? "/placeholders/no-preview.png",
          imgSrc: _imgSrc ?? "/placeholders/no-preview.png",
          placeholder: WS_PLACEHOLDER_BLUR_DATA,
          placeholderBrand: WS_PLACEHOLDER_BLUR_DATA,
          title: item.fields?.title || "",
          label: item.fields?.associatedIndustry?.fields?.title || "",
          content: item.fields?.excerpt || "",
          articleUrl: `/resources/case-studies/${item.fields?.slug}`,
          buttonLabel: read_more,
          type: "caseStudy",
        };

        return tmp;
      })
    );

    return { cardsList, total: res.total };
  } catch (error: any) {
    console.log(error);

    return { cardsList: [], total: 0, error: error.message };
  }
};
