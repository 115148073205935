import React from "react";
import { FormLabel } from "@/components/ui/form";
import { cn } from "@/lib/utils";

const CustomFormLabel = ({ label, className }: { label: string; className?: string }) => {
  return (
    <FormLabel className={cn("font-saira text-14 font-[400] tracking-[-0.1px] leading-[1.7]", className)}>
      {label}
    </FormLabel>
  );
};

export default CustomFormLabel;
