export const CASE_STUDY_CAROUSEL_BREAKPOINTS = {
  300: {
    slidesPerView: 1,
    spaceBetween: 12,
    slidesPerGroup: 1,
  },
  640: {
    slidesPerView: 2,
    spaceBetween: 20,
    slidesPerGroup: 1,
  },
  768: {
    slidesPerView: 2,
    spaceBetween: 20,
    slidesPerGroup: 1,
  },
  1024: {
    slidesPerView: 2.2,
    spaceBetween: 40,
    slidesPerGroup: 1,
  },
  1280: {
    slidesPerView: 2.4,
    spaceBetween: 40,
    slidesPerGroup: 1,
  },
  1536: {
    slidesPerView: 2.8,
    spaceBetween: 40,
    slidesPerGroup: 1,
  },
  1920: {
    slidesPerView: 4,
    spaceBetween: 40,
    slidesPerGroup: 1,
  },
};
