/* eslint-disable @typescript-eslint/no-explicit-any */
import { KeyboardEvent, useRef } from "react";
import { X } from "lucide-react";

import AppText from "./AppText";
import { cn } from "@/lib/utils";

export const CustomizationsDots = ({ visibleTooltip, setVisibleTooltip, idx, dot, isMobile, closeTooltip }: any) => {
  const dotRef = useRef<HTMLDivElement>(null);

  const handleDotClick = () => {
    const parent = document.getElementById("dotsParent_packageLevels");
    if (dotRef.current && parent) {
      const { left: parentLeft } = parent.getBoundingClientRect();
      const { left, top } = dotRef.current.getBoundingClientRect();
      const diff = left - parentLeft;

      setVisibleTooltip({ idx, isLeft: diff > 326, top });
    }
  };

  const handleDotKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleDotClick();
    }
  };

  return (
    <>
      <div
        aria-label="info dot button"
        role="button"
        tabIndex={0}
        key={dot.id}
        ref={dotRef}
        onClick={handleDotClick}
        onKeyDown={handleDotKeyDown}
        style={{
          top: `${dot.position.top}%`,
          left: `${dot.position.left}%`,
        }}
        className="ring-1 focus:ring-dijon ring-offset-1 ring-offset-transparent ring-transparent absolute w-6 h-6 overflow-visible rounded-full outline-none"
      >
        <div className="bg-[#F8DD75] z-9 animation-pulse fade-in absolute top-0 left-0 w-6 h-6 rounded-full cursor-pointer" />

        {visibleTooltip && visibleTooltip.idx === idx && (
          <Tooltip
            heading={dot.heading}
            subheading={dot.subheading}
            footer={dot.footer}
            isLeft={visibleTooltip.isLeft}
            isMobile={isMobile}
            top={visibleTooltip.top}
            handleTooltipClose={() => closeTooltip()}
          />
        )}
      </div>
    </>
  );
};

const Tooltip = ({ subheading, heading, footer, handleTooltipClose, isMobile, isLeft, top }: any) => {
  return (
    <div
      style={{ top: isMobile ? `${Math.round(top)}px` : "" }}
      className={cn(
        `cursor-default flex-col z-50
    bg-black-10 border-l-[2px] border-navy-blue pl-8 pt-4 pr-4 pb-2 
    w-max md:max-w-[326px] max-w-[310px] 
    shadow-[10px_10px_34px_-17px_rgba(0,0,0,0.75)]`,
        isMobile
          ? "fixed left-[50%] -translate-x-1/2"
          : isLeft
          ? "-top-[20px] absolute animate-in fade-in duration-500 right-[30px] -translate-x-[10px]"
          : "-top-[20px] absolute animate-in fade-in duration-500 left-[30px] translate-x-[10px]"
      )}
    >
      <div className="flex items-start justify-between">
        <AppText type={"BODY_MEDIUM_EMPHASIS"} className="mb-2">
          {heading}
        </AppText>
        {isMobile && (
          <button
            id="button-customizations-dots"
            className="hover:bg-black-40 p-[2px] transition outline-none focus:outline-none ring-1 ring-transparent focus:ring-dijon  "
            onClick={(e) => {
              e.stopPropagation();
              handleTooltipClose();
            }}
          >
            <X size={16} aria-label="x icon" />
          </button>
        )}
      </div>

      <AppText type={"BODY_SMALL"} className="mb-2">
        {subheading}
      </AppText>

      <AppText type={"BUTTON_SMALL"} className=" font-[500]">
        {footer}
      </AppText>
    </div>
  );
};
