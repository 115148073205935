/* eslint-disable @typescript-eslint/no-explicit-any */

export function customDebounce(fn: any, ms: number) {
  let timer: any;

  return () => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      timer = null;

      // @ts-expect-error: select props
      // eslint-disable-next-line prefer-rest-params
      fn.apply(this, arguments);
    }, ms);
  };
}
