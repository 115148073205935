"use client";
/* eslint-disable @next/next/no-img-element */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from "react";
import { AppButton } from "../buttons/AppButton";

import { IInfoCard } from "./ContentInfoCards";
import { TITLE_MEDIUM } from "@/styles";
import { cn } from "@/lib/utils";

interface IContentInfoCardsButtonsCC {
  card: IInfoCard;
  bg: "dark" | "light" | undefined;
}

const ContentInfoCardsButtonsCC = ({ card, bg }: IContentInfoCardsButtonsCC) => {
  const cardCtaLabel = card.heading;
  const cardCtaLink = card.cardLink;

  const isSupposedToOpenChatInsteadOfLink = cardCtaLink === "{{chat}}" || cardCtaLink === "#open-integrated-chat-box";
  const isSupposedToOpenTelLink = cardCtaLink.startsWith("tel:");

  const cardCtaCustomLink = isSupposedToOpenChatInsteadOfLink ? undefined : cardCtaLink;

  const onClickHandler = (e: any) => {
    e.preventDefault();
    if (isSupposedToOpenChatInsteadOfLink) {
      console.log(`Opening: Chat`);
      const button = document.querySelector("#embeddedMessagingConversationButton") as HTMLButtonElement;
      button?.click();
    } else if (isSupposedToOpenTelLink) {
      // Open tel link
      console.log(`Opening: Call`);
      if (cardCtaCustomLink) {
        document.location = cardCtaCustomLink;
      }
    } else {
      if (cardCtaCustomLink) {
        document.location = cardCtaCustomLink;
      }
    }
  };

  return (
    <AppButton
      bg={bg}
      intent="ghost"
      label={cardCtaLabel}
      key={card.id}
      onClick={onClickHandler}
      className={cn(TITLE_MEDIUM, bg === "dark" ? "text-white" : "text-navy-blue")}
    />
  );
};

export default ContentInfoCardsButtonsCC;
