"use client";

/* eslint-disable @next/next/no-img-element */
import Image from "next/image";

import AppText from "../other/AppText";
import { AppIconButton } from "../buttons/AppIconButton";

import { cn } from "@/lib/utils";
import CustomLink from "../other/CustomLink";
import { ChevronRight, Download } from "lucide-react";
import { TITLE_SMALL } from "@/styles";
import { formatDateCustom } from "@/lib/formatDateCustom";

export interface ICard {
  imgSrc: string;
  imgAlt: string;
  label?: string;
  date?: string;
  title?: string;
  content?: string;
  articleUrl: string;
  buttonLabel: string;
  brandSrc?: string;
  brandAlt?: string;
  className?: string;
  type: string;
  darkBg?: boolean;
  centeredContent?: boolean;
  ctaDownloadIcon?: boolean;
}

/**
 * ! industry card lives in a grid 4 per row (max width)
 * ! all other cards are 3 per row
 */

export const Card = ({
  imgSrc,
  imgAlt = "image",
  title,
  label,
  date,
  content,
  articleUrl,
  buttonLabel,
  brandSrc,
  brandAlt,
  className,
  type,
  darkBg,
  centeredContent,
  ctaDownloadIcon,
}: ICard) => {
  const isTitle =
    type === "blog" ||
    type === "caseStudy" ||
    type === "esg" ||
    type === "solution" ||
    type === "search" ||
    type === "doc";
  const isGhost = type !== "blog";
  const isContent = type === "caseStudy" || type === "solution" || type === "esg";

  return (
    <CustomLink href={articleUrl} className={cn(`flex flex-col overflow-hidden transition h-[100%] group`, className)}>
      <div className={`relative w-full overflow-hidden`}>
        {/** main card image */}
        <div className="relative">
          <Image
            className={cn(
              "object-cover w-full group-hover:scale-105 transition aspect-[1.75] md:aspect-[1.5] xl:aspect-[1.75]",
              isGhost && "h-[306px] sm:max-w-[540px]",
              type === "industry" && "max-h-[217px] aspect-1",
              type === "solution" && "max-h-[240px] aspect-1 md:aspect-1 xl:aspect-1",
              type === "search" && "sm:max-h-[238px]",
              type === "esg" && "sm:max-w-full h-full"
            )}
            sizes="(max-width: 640px) 100vw,(max-width: 1024px) 50vw, 700px"
            width={0}
            height={0}
            src={imgSrc || ""}
            alt={imgAlt}
          />
        </div>

        {/**  small brand image inside main image  */}
        {type === "caseStudy" && brandSrc && (
          <div className="bottom-4 left-4 absolute lg:w-[159px] lg:h-[82px] w-[130px] h-[67px] bg-white flex justify-center items-center">
            <Image
              className="object-cover w-full"
              sizes="100vw"
              width={0}
              height={0}
              src={brandSrc}
              alt={brandAlt || "brand"}
            />
          </div>
        )}
      </div>

      <div
        className={cn("flex flex-col items-start flex-1", isGhost ? "py-4" : "py-6", centeredContent && "items-center")}
      >
        {/**  some cards have label under main image */}
        {(type === "caseStudy" || type === "search" || type === "esg") && label && (
          <AppText className={cn("mb-2 text-royal-blue", darkBg && "text-mellow")} type="LABEL_LARGE">
            {label}
          </AppText>
        )}
        {/**  blog cards have date under main image */}
        {type === "blog" && date && (
          <AppText className={cn("mb-2", darkBg && "text-white font-thin")} type="BODY_SMALL">
            {formatDateCustom(date)}
          </AppText>
        )}
        {/**  industry cards don't have title */}
        {isTitle && title && (
          <AppText
            className={cn("line-clamp-2 mb-4", darkBg && "text-white", centeredContent && "text-center")}
            type={isGhost ? "SUB_HEADING_MEDIUM" : "BODY_LARGE_EMPHASIS"}
          >
            {title}
          </AppText>
        )}
        {/**   caseStudy and solution cards have content */}
        {isContent && content && (
          <AppText
            className={cn("line-clamp-3 mb-4", darkBg && "text-white", centeredContent && "text-center")}
            type="BODY_MEDIUM"
          >
            {content}
          </AppText>
        )}

        <AppIconButton
          tabIndex={-1}
          intent="ghost"
          label={buttonLabel}
          icon={
            ctaDownloadIcon ? (
              <Download size={16} aria-label="download icon" />
            ) : (
              <ChevronRight size={20} aria-label="chevron right icon" />
            )
          }
          iconOnRightSide
          className={cn("whitespace-normal text-start", TITLE_SMALL, darkBg && "text-white")}
          bg={darkBg ? "dark" : "light"}
        />
      </div>
    </CustomLink>
  );
};
