/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import Image from "next/image";
import { ChevronRight } from "lucide-react";

import ContentLayout from "@/components/layouts/ContentLayout";
import { AppButton } from "@/components/buttons/AppButton";
import AppText from "@/components/other/AppText";
import { cn } from "@/lib/utils";
// import { Play } from "@/assets/Icons";
import RichTextRenderer from "../other/RichTextRenderer";
import { colorsMap, fallbackColors } from "../other/constants";
import { AppIconButton } from "../buttons/AppIconButton";
import { IMediaAndTextImage } from "./MediaAndText";

export interface IQuoteRequest {
  id: string;
  image?: IMediaAndTextImage;
  videoUrl?: string;
  label: string;
  heading: string;
  subheading: string;
  content: any;
  primaryCtaLabel?: string;
  primaryCtaLink?: string;
  ghostCtaLabel?: string;
  ghostCtaLink?: string;
  secondaryCtaLabel?: string;
  secondaryCtaLink?: string;
  background: string;
  mediaAlign: string;
  richText?: boolean;
  paddingBottom?: string;
}

const ImageBlock = ({ image }: { image: IMediaAndTextImage }) => (
  <div className="lg:col-span-7 relative flex items-center col-span-4 aspect-[1.67]">
    <Image
      src={image.url}
      alt={image.alt}
      placeholder="blur"
      blurDataURL={image.placeholder}
      fill
      sizes="(max-width: 1024px) 100vw, 900px"
      className="object-cover transition"
    />
  </div>
);

const QuoteRequest: React.FC<IQuoteRequest> = ({
  id,
  image,
  videoUrl,
  label,
  heading,
  subheading,
  content,
  primaryCtaLabel,
  primaryCtaLink,
  ghostCtaLabel,
  ghostCtaLink,
  secondaryCtaLabel,
  secondaryCtaLink,
  background,
  mediaAlign,
  richText,
  paddingBottom,
}) => {
  const { labelColor, textColor, bgColorClass, bg, border } = colorsMap[background] ?? fallbackColors;

  const VideoBlock = () => (
    <div className="lg:col-span-7 relative flex items-center col-span-4 overflow-hidden">
      <video
        // ref={videoRef}
        // onClick={onVideoClick}
        // onPlaying={() => setIsPlaying(true)}
        // onPause={() => setIsPlaying(false)}
        className={"min-h-[191px] w-full h-full object-cover"}
        controls={true}
        preload="auto"
        playsInline={true}
      >
        <source src={videoUrl} type="video/mp4" />
      </video>
    </div>
  );

  return (
    <ContentLayout
      bgColorClass={bgColorClass}
      innerClass={cn(
        richText
          ? "py-8 px-4 sm:px-6 lg:px-6 xl:px-6 2xl:px-6 sm:py-8"
          : mediaAlign === "Left"
          ? "lg:pl-0 lg:pr-[80px] xl:pl-0 xl:pr-[80px] 2xl:pl-[104px] 2xl:pr-[104px] overflow-hidden"
          : "lg:pr-0 lg:pl-[80px] xl:pr-0 xl:pl-[80px] 2xl:pr-[104px] 2xl:pl-[104px] overflow-hidden"
      )}
      paddingBottom={paddingBottom}
      id={id}
    >
      <div className="lg:grid-cols-12 grid grid-cols-4 gap-10">
        {mediaAlign === "Left" && image && <ImageBlock image={image} />}

        {mediaAlign === "Left" && videoUrl && <VideoBlock />}
        <div
          className={cn(
            "lg:col-span-5 col-span-4 flex items-center",
            mediaAlign === "Right" && "order-last lg:order-first"
          )}
        >
          <div className={cn("border-l-2 pl-8", border)}>
            {label && (
              <AppText className={labelColor} type="LABEL_LARGE">
                {label}
              </AppText>
            )}

            {heading && (
              <AppText className={cn("mt-2", textColor)} as="h2" type="HEADLINE_SMALL">
                {heading}
              </AppText>
            )}
            {subheading && (
              <AppText className={cn("mt-4 mb-4", textColor)} type="SUB_HEADING_SMALL">
                {subheading}
              </AppText>
            )}
            {content && (
              <RichTextRenderer
                richTextDocument={content}
                id={`rt-${id}`}
                locations
                className={cn("text-sm mt-4", textColor)}
              />
            )}
            {(primaryCtaLabel || secondaryCtaLabel || ghostCtaLabel) && (
              <div className="sm:gap-6 sm:flex-row flex flex-col gap-4 mt-8">
                {primaryCtaLabel && primaryCtaLink && (
                  <AppButton bg={bg} label={primaryCtaLabel} intent="primary" link={primaryCtaLink} />
                )}

                {secondaryCtaLabel && secondaryCtaLink && (
                  <AppButton bg={bg} label={secondaryCtaLabel} intent="secondary" link={secondaryCtaLink} />
                )}
                {ghostCtaLabel && ghostCtaLink && (
                  <AppIconButton
                    intent="ghost"
                    label={ghostCtaLabel}
                    link={ghostCtaLink}
                    icon={<ChevronRight size={20} aria-label="chevron right icon" />}
                    iconOnRightSide
                    bg={bg}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {mediaAlign === "Right" && image && <ImageBlock image={image} />}
        {mediaAlign === "Right" && videoUrl && <VideoBlock />}
      </div>
    </ContentLayout>
  );
};

export default QuoteRequest;
