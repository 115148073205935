"use client";

import React from "react";
import { EmblaOptionsType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import Image from "next/image";

import AppText from "./AppText";
import { cn } from "@/lib/utils";
import { colorsMap, fallbackColors } from "./constants";
import CtaVersion from "../buttons/CtaVersion";
import ContentLayout from "../layouts/ContentLayout";
import { DotButton, useDotButton } from "../embla/emblaCarouselDotButton";

export interface IImageCarousel {
  images: { url: string; alt: string; placeholder: string }[];
  id: string;
  heading?: string;
  subheading?: string;
  background?: string;
  paddingBottom?: string;
  ctas: {
    ctaLabel: string;
    ctaLink: string;
    intent: "secondary" | "primary" | "ghost" | undefined;
    background: string;
    hoverBackground: string;
    textColor: string;
  }[];
}

const OPTIONS: EmblaOptionsType = { loop: true, startIndex: 1 };

const ImageCarousel: React.FC<IImageCarousel> = ({
  images,
  heading,
  ctas,
  subheading,
  background,
  id,
  paddingBottom,
}) => {
  const { textColor, bgColorClass } = colorsMap[background || "navy-blue"] ?? fallbackColors;

  const [emblaRef, emblaApi] = useEmblaCarousel(OPTIONS);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);

  return (
    <ContentLayout bgColorClass={bgColorClass} paddingBottom={paddingBottom}>
      <div className="scroll-smooth relative flex flex-col items-center overflow-hidden" id={id}>
        {(heading || subheading) && (
          <div className="flex flex-col items-center gap-5 lg:gap-[1.875rem]">
            {heading && (
              <AppText type="HEADLINE_MEDIUM" className={cn("text-center text-pretty", textColor)}>
                {heading}
              </AppText>
            )}

            {subheading && (
              <AppText type="SUB_HEADING_MEDIUM" className={cn("max-w-[48rem] text-center text-pretty", textColor)}>
                {subheading}
              </AppText>
            )}
          </div>
        )}

        <div className="pt-5 lg:pt-[3.125rem]">
          <div className="relative px-0 max-w-[70rem]">
            <div className="overflow-hidden" ref={emblaRef}>
              <div className="backface-hidden touch-pan-y touch-pinch-zoom flex -ml-4">
                {images.map((slide, idx) => (
                  <div className="pl-4 min-w-0 flex-[0_0_100%] relative" key={idx}>
                    <Image
                      src={slide.url}
                      alt={slide.alt || "image"}
                      sizes="(max-width: 64rem) 100vw, 70rem"
                      width={0}
                      height={0}
                      placeholder="blur"
                      blurDataURL={slide.placeholder}
                      className="object-cover w-full  aspect-[2.06] xl:aspect-[2.25]"
                      // className="object-cover w-full h-[10.75rem] lg:h-[28rem] 2xl:h-[31rem]"
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="flex justify-center gap-2 pt-5">
              {scrollSnaps.map((_, index) => (
                <DotButton
                  key={index}
                  onClick={() => onDotButtonClick(index)}
                  className={cn("w-2 m-1 h-2 bg-black-20 rounded-full", index === selectedIndex && "bg-navy-blue")}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="lg:pt-20 flex flex-row flex-wrap items-center justify-center gap-4 pt-10">
          {ctas.map((cta) => (
            <CtaVersion key={cta.ctaLink} {...cta} className="px-9" />
          ))}
        </div>
      </div>
    </ContentLayout>
  );
};

// export default ImageGallery;
export default React.memo(ImageCarousel);
