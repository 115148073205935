/* eslint-disable @typescript-eslint/no-explicit-any */
export default class APIClient {
  private hostUrl: string | undefined = "";

  public constructor() {
    // NEXT_PUBLIC_API_HOST must be set in .env file
    this.hostUrl = process.env.NEXT_PUBLIC_API_HOST || ""; // if no API host set the request should be sent to the same host
  }

  private async doRequest(method: string, endpoint: string, body?: any, headers?: { [header: string]: string }) {
    const url = `${this.hostUrl}${(endpoint && !endpoint.startsWith("/") && "/") || ""}${endpoint}`;
    const response = await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      const error = await response.json();
      const errorMessage = error.message
        ? error.message
        : `Request failed: [${method} ${url}]\nStatus: ${response.status} ${response.statusText}`;
      throw new Error(errorMessage);
    }

    const data = await response.json();
    return data;
  }

  public async get(endpoint: string, headers?: { [header: string]: string }) {
    return this.doRequest("GET", endpoint, undefined, headers);
  }

  public async post(endpoint: string, body?: any, headers?: { [header: string]: string }) {
    return this.doRequest("POST", endpoint, body, headers);
  }

  public async put(endpoint: string, body?: any, headers?: { [header: string]: string }) {
    return this.doRequest("PUT", endpoint, body, headers);
  }

  public async delete(endpoint: string, headers?: { [header: string]: string }) {
    return this.doRequest("DELETE", endpoint, undefined, headers);
  }
}
