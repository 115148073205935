/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { useState } from "react";
import AppText from "@/components/other/AppText";
import { AppButton } from "@/components/buttons/AppButton";
import { cn } from "@/lib/utils";
import Image from "next/image";
import Carousel from "@/components/other/Carousel";
import { SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { useMediaQuery } from "@/hooks/mediaQuery";
import Dropdown from "@/components/other/Dropdown";
import ContentLayout from "../layouts/ContentLayout";
import RichTextRenderer from "./RichTextRenderer";
import { Check, ChevronDown } from "lucide-react";

type UnitConfiguratorTabSection = {
  heading: string;
  configurations: string[];
};

interface ProductData {
  id: string;
  label: string;
  heading: string;
  subheading: string;
  tabs: any[];
  paddingBottom?: string;
}

const UnitConfigurator: React.FC<ProductData> = ({ label, heading, subheading, tabs, id, paddingBottom }) => {
  const [selectedTab, setSelectedTab] = useState<any>(tabs[0]);
  const [selectedProduct, setSelectedProduct] = useState<any>(
    selectedTab?.images?.[0] ?? { url: "", alt: "", placeholder: "" }
  );
  const slidesPerView = selectedTab?.images?.length ?? 0;
  const isSmallScreen = useMediaQuery("(max-width: 1024px)");
  const replaceTabsWithDropdown: boolean = useMediaQuery("(max-width: 640px)") && tabs.length > 2;

  if ((tabs?.length ?? 0) === 0) {
    console.log(tabs, "In UnitConfigurator tabs are empty!!! Can't render section!");
    return null;
  }

  const onTabSelect = (tabId: string) => {
    const tab = tabs.find((t) => t.id === tabId);
    if (tab) {
      setSelectedTab(tab);

      if (!tab.componentUnitComparisonTab) {
        setSelectedProduct(tab.images[0]);
      }
    }
  };

  const renderTabs = ({ tabLabel, tabId }: { tabLabel: string; tabId: string }) => {
    const isSelected = tabId === selectedTab.id;

    return (
      <button
        key={tabId}
        onClick={() => onTabSelect(tabId)}
        className={cn(
          "px-4 font-sairaSemiCondensed py-2 rounded-[100px] outline-mellow font-[500] text-14  leading-[1.7]",
          isSelected ? "bg-royal-blue text-white" : "bg-black-10 text-black hover:bg-black-20 "
        )}
      >
        {tabLabel}
      </button>
    );
  };

  const renderImage = (imageObj: { url: string; alt: string; placeholder: string }, index: number) => {
    return (
      <SwiperSlide key={index} className="w-[96px] min-h-[96px] max-h-[200px] lg:max-h-full relative aspect-[16/9]">
        <Image
          onClick={() =>
            setSelectedProduct({ url: imageObj.url, alt: imageObj.alt, placeholder: imageObj.placeholder })
          }
          src={imageObj.url}
          alt={imageObj.alt}
          sizes="(max-width: 1024px) 100vw, 300px"
          fill
          placeholder="blur"
          blurDataURL={imageObj.placeholder}
          className="lg:object-cover object-contain w-full h-full"
        />
      </SwiperSlide>
    );
  };

  const renderTabSection = (s: UnitConfiguratorTabSection) => {
    return (
      <div key={s.heading} className={"mt-[40px] pl-[16px] flex flex-col border-l-[1px] border-l-black-40"}>
        <AppText type={"BODY_MEDIUM_EMPHASIS"}>{s.heading}</AppText>

        <div className={"mt-2 flex flex-wrap gap-y-4 gap-x-8"}>
          {s.configurations.map((item, index) => (
            <div key={index} className="flex items-center">
              {/* <CheckMark /> */}

              <Check size={20} color="#0058AC" className="min-w-[20px]" aria-label="check icon" />

              <AppText className="ml-2" type="BODY_SMALL_EMPHASIS">
                {item}
              </AppText>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <ContentLayout id={id} innerClass="sm:pt-20 pt-12 sm:pb-0 pb-0">
        <div className={"flex flex-col items-center"} id="our-range">
          {label && (
            <AppText type="LABEL_LARGE" className="text-royal-blue text-center">
              {label}
            </AppText>
          )}

          {heading && (
            <AppText type="HEADLINE_MEDIUM" className="mt-2 text-center">
              {heading}
            </AppText>
          )}

          {subheading && (
            <AppText type="SUB_HEADING_MEDIUM" as="p" className="mt-4 text-center">
              {subheading}
            </AppText>
          )}
        </div>
      </ContentLayout>

      <div className="w-full">
        {replaceTabsWithDropdown ? (
          <Dropdown
            className={"mt-[48px]"}
            items={tabs.map((t) => ({ label: t.tabLabel, id: t.id }))}
            selectedItem={{ label: selectedTab.tabLabel, id: selectedTab.id }}
            onItemClick={onTabSelect}
          />
        ) : tabs.length > 1 ? (
          <ContentLayout innerClass="sm:py-0 py-0 items-center justify-center flex">
            <div className="mt-[48px] max-w-[80%] gap-4 flex flex-wrap justify-center items-center w-full flex-1">
              {[...tabs].map((t) => renderTabs({ tabLabel: t.tabLabel, tabId: t.id }))}
            </div>
          </ContentLayout>
        ) : null}
      </div>

      {/** tab content */}
      {selectedTab.componentUnitComparisonTab ? (
        <>
          <ContentLayout
            innerClass="sm:pb-20 pb-12 sm:pt-0 pt-0"
            paddingBottom={selectedTab.useCases && selectedTab.useCases.length ? "" : paddingBottom}
          >
            {/** separator */}
            <div className={"mt-[48px] hidden sm:block bg-black-20 h-[2px] w-full"} />

            {/** tab content */}
            <div className="flex flex-row justify-center">
              <RichTextRenderer
                richTextDocument={selectedTab.content}
                id={`rt-lowerPart-${id}`}
                locations
                className="mt-[3.125rem] max-w-[736px] text-pretty lg:text-center"
              />
            </div>

            <div className="flex mt-8">
              <div className="flex justify-center flex-1">
                <div className="relative w-full aspect-[16/9] max-w-[25rem]">
                  <Image
                    src={selectedTab.unit1ImageSrc}
                    alt={selectedTab.unit1ImageAlt}
                    sizes="(max-width: 1024px) 50vw, 664px"
                    fill
                    placeholder="blur"
                    blurDataURL={selectedTab.unit1ImagePlaceholder}
                    className="object-cover w-full"
                  />
                </div>
              </div>
              <div className="flex justify-center flex-1">
                <div className="relative w-full aspect-[16/9] max-w-[25rem]">
                  <Image
                    src={selectedTab.unit2ImageSrc}
                    alt={selectedTab.unit2ImageAlt}
                    sizes="(max-width: 1024px) 50vw, 664px"
                    fill
                    placeholder="blur"
                    blurDataURL={selectedTab.unit2ImagePlaceholder}
                    className="object-cover w-full"
                  />
                </div>
              </div>
            </div>

            <div className="lg:mt-20 flex flex-row mt-8">
              <div className="flex justify-center flex-1">
                <AppText type="TITLE_MEDIUM" className="text-pretty text-center">
                  {selectedTab.unit1Name}
                </AppText>
              </div>
              <div className="flex justify-center flex-1">
                <AppText type="TITLE_MEDIUM" className="text-pretty text-center">
                  {selectedTab.unit2Name}
                </AppText>
              </div>
            </div>

            <div className="lg:mt-12 mt-6">
              {(selectedTab?.comparisons || []).map((item: any) => (
                <div key={item.id} className="mt-5">
                  <div className="flex items-center">
                    <div className="flex-grow bg bg-black-20 h-[1px]"></div>
                    <div className="flex-grow-0 mx-8">
                      <AppText type="BODY_SMALL_EMPHASIS" className="text-pretty text-black-40 font-sairaSemiCondensed">
                        {item.comparison}
                      </AppText>
                    </div>
                    <div className="flex-grow bg bg-black-20 h-[1px]"></div>
                  </div>

                  <div className="flex flex-row pt-2">
                    <div className="flex justify-center flex-1 px-4">
                      <AppText type="BODY_SMALL" className="text-pretty text-center">
                        {item.unit1}
                      </AppText>
                    </div>
                    <div className="flex justify-center flex-1 px-4">
                      <AppText type="BODY_SMALL" className="text-pretty text-center">
                        {item.unit2}
                      </AppText>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </ContentLayout>

          {/** common use cases block */}
          {selectedTab.useCases && selectedTab.useCases.length && (
            <CommonUseCases
              useCases={selectedTab.useCases}
              useCaseTitle={selectedTab.useCaseTitle}
              paddingBottom={paddingBottom}
            />
          )}
        </>
      ) : (
        <ContentLayout innerClass="sm:pb-[80px] pb-[48px] sm:pt-0 pt-0">
          {/** separator */}
          <div className={"mt-[48px] hidden sm:block bg-black-20 h-[2px] w-full"} />
          <div className="lg:mt-12 flex flex-col mt-4">
            <div className="max-w-full lg:max-w-[70%]">
              <AppText type="TITLE_LARGE" className="text-pretty my-4" as="h3">
                {selectedTab.heading}
              </AppText>
            </div>

            <div className="max-lg:flex-col-reverse lg:gap-10 flex flex-1 gap-6">
              <div className="flex flex-col flex-[0.5] mr-[40px]">
                <RichTextRenderer richTextDocument={selectedTab.content} id={`rt-${id}`} locations />

                {selectedTab.sections.map(renderTabSection)}

                {!!selectedTab.footNote && (
                  <AppText type="CAPTION_MEDIUM" className="mt-8">
                    {selectedTab.footNote}
                  </AppText>
                )}

                {typeof selectedTab.ctaLabel === "string" && typeof selectedTab.ctaLink === "string" && (
                  <AppButton
                    label={selectedTab.ctaLabel}
                    intent="secondary"
                    link={selectedTab.ctaLink}
                    className={"mt-10"}
                  />
                )}
              </div>

              <div className="relative flex flex-[0.5] flex-col justify-start items-center overflow-hidden">
                {!isSmallScreen && (
                  <div className="w-[80%] relative aspect-[16/9]">
                    <Image
                      src={selectedProduct.url}
                      alt={selectedProduct.alt}
                      sizes="(max-width: 1024px) 0vw, 520px"
                      fill
                      placeholder="blur"
                      blurDataURL={selectedProduct.placeholder}
                      className="object-contain w-full max-h-[323px]"
                    />
                  </div>
                )}

                {selectedTab.images.length ? (
                  (selectedTab.images.length > 1 && !isSmallScreen) || isSmallScreen ? (
                    <div className={"relative w-full flex justify-center max-lg:justify-end flex-col mt-6"}>
                      <Carousel
                        key={slidesPerView}
                        wrapperClassName={cn("flex flex-1 relative ")}
                        loop={true}
                        slidesPerView={slidesPerView}
                        slidesPerGroup={1}
                        spaceBetween={16}
                        modules={[Pagination]}
                        rewind={true}
                        pagination={isSmallScreen && { el: ".swiper-custom-pagination" }}
                        paginationType={"sideArrows"}
                        breakpoints={{
                          320: { slidesPerView: 1 },
                          640: { slidesPerView: 1 },
                          1024: { slidesPerView: Math.max(slidesPerView - 1, 1) },
                          1280: { slidesPerView: slidesPerView },
                          1536: { slidesPerView: slidesPerView },
                        }}
                      >
                        {selectedTab.images.map((img: any, index: number) => renderImage(img, index))}
                      </Carousel>
                      {isSmallScreen && (
                        <div className="swiper-custom-pagination flex items-end justify-center w-full pb-3 mt-6" />
                      )}
                    </div>
                  ) : null
                ) : null}
              </div>
            </div>
          </div>
        </ContentLayout>
      )}
    </>
  );
};

export default React.memo(UnitConfigurator);

const CommonUseCases = ({
  useCases,
  useCaseTitle,
  paddingBottom,
}: {
  useCases: any[];
  useCaseTitle: string;
  paddingBottom: string | undefined;
}) => (
  <ContentLayout bgColorClass="bg-black-5" paddingBottom={paddingBottom || ""}>
    <AppText type="SUB_HEADING_MEDIUM" className="text-pretty text-center">
      {useCaseTitle}
    </AppText>

    <div className="lg:mt-12 mt-6">
      {(useCases || []).map((item: any) => {
        const colorLeft = item.comparison1Colour === "yellow" ? "bg-mellow" : "bg-royal-blue";
        const colorRight = item.comparison2Colour === "yellow" ? "bg-mellow" : "bg-royal-blue";

        return (
          <div key={item.id} className="mt-1">
            <div className="flex items-center">
              <div className="flex-grow bg bg-black-20 h-[1px]"></div>
              <div className="flex-grow-0 mx-8">
                <AppText type="BODY_SMALL_EMPHASIS" className="text-pretty text-black-40 font-sairaSemiCondensed">
                  {item.comparison}
                </AppText>
              </div>
              <div className="flex-grow bg bg-black-20 h-[1px]"></div>
            </div>

            <div className="flex flex-row pt-2">
              <div className="flex flex-col items-center justify-center flex-1 gap-2 px-2">
                <div className={cn("w-7 h-7 rounded-full flex items-center justify-center", colorLeft)}>
                  <ChevronDown className="text-white" />
                </div>
                <AppText type="BODY_SMALL" className="text-pretty w-fit">
                  {item.comparison1Text}
                </AppText>
              </div>
              <div className="flex flex-col items-center justify-center flex-1 gap-2 px-2">
                <div className={cn("w-7 h-7 rounded-full flex items-center justify-center", colorRight)}>
                  <ChevronDown className="text-white" />
                </div>
                <AppText type="BODY_SMALL" className="text-pretty w-fit">
                  {item.comparison2Text}
                </AppText>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  </ContentLayout>
);
