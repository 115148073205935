import { create } from "zustand";

interface IFormData {
  formData: { [key: string]: string };
  formDataThirdTab: { [key: string]: string };
  setFormData: (data: { [key: string]: string }) => void;
  setStepAndFormDataThirdTab: (step: number, data: { [key: string]: string }) => void;
  step: number;
  setStep: (step: number) => void;
  setStepAndFormData: (step: number, data: { [key: string]: string }) => void;
  resetForm: () => void;
}

export const useFormData = create<IFormData>((set) => ({
  step: 1,
  formData: {},
  formDataThirdTab: {},
  setFormData: (data: { [key: string]: string }) => set((state) => ({ formData: { ...state.formData, ...data } })),
  setStepAndFormDataThirdTab: (step: number, data: { [key: string]: string }) =>
    set((state) => {
      return { step: step, formDataThirdTab: { ...state.formDataThirdTab, ...data } };
    }),
  setStep: (step: number) => set(() => ({ step: step })),
  setStepAndFormData: (step: number, data: { [key: string]: string }) =>
    set((state) => {
      return { step: step, formData: { ...state.formData, ...data } };
    }),
  resetForm: () => set(() => ({ formData: {}, formDataThirdTab: {}, step: 1 })),
}));
