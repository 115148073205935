import AppText from "@/components/other/AppText";
import { cn } from "@/lib/utils";
import React from "react";

export interface IStepper {
  steps: string[];
  currentStepIndex: number;
  className?: string;
}

const StepperServices: React.FC<IStepper> = ({ steps, currentStepIndex, className }) => {
  return (
    <>
      {/** mobile */}
      <div
        className={cn(
          "flex flex-col-reverse sm:flex-row lg:hidden justify-between items-start gap-6   py-[40px]",
          className
        )}
      >
        <div className="flex flex-col items-start">
          <AppText type="BODY_MEDIUM" className="text-royal-blue uppercase">
            Step {currentStepIndex} of {steps.length}
          </AppText>
          <AppText type="BODY_MEDIUM_EMPHASIS">{steps[currentStepIndex]}</AppText>
        </div>
        <div className="flex flex-row items-center">
          {steps.map((_, index) => {
            return (
              <React.Fragment key={index}>
                <div
                  className={cn(
                    index <= currentStepIndex && "rounded-full border-[1px] border-solid border-royal-blue "
                  )}
                >
                  <div
                    className={cn(
                      "m-0 p-0 w-[42px] h-[42px]",
                      "flex text-base justify-center items-center",
                      "bg-navy-blue-10 text-royal-blue rounded-full",
                      index <= currentStepIndex && "bg-royal-blue text-white m-1"
                    )}
                  >
                    {index + 1}
                  </div>
                </div>
                {index != steps.length - 1 && <span className={cn("p-0 m-0 w-[24px] h-[1px] bg-royal-blue")}></span>}
              </React.Fragment>
            );
          })}
        </div>
      </div>

      {/** desktop */}
      <div className={cn("hidden justify-center items-center lg:flex py-[40px]", className)}>
        {steps.map((stepTitle, index) => {
          return (
            <React.Fragment key={index}>
              <div
                className={cn(
                  "flex bg-navy-blue-10 rounded-md py-1 px-4",
                  index <= currentStepIndex - 1 && "bg-royal-blue text-white"
                )}
              >
                <AppText
                  type="BODY_MEDIUM_EMPHASIS"
                  className={cn(
                    "flex bg-navy-blue-10 text-royal-blue  rounded-md py-1 px-4 cursor-pointer",
                    index <= currentStepIndex - 1 && "bg-royal-blue text-white"
                  )}
                >
                  {stepTitle}
                </AppText>
              </div>
              {index != steps.length - 1 && <span className="p-0 m-0 w-6 h-[1px] bg-royal-blue"></span>}
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

export default StepperServices;
