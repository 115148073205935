/* eslint-disable @typescript-eslint/no-explicit-any */
import { format } from "date-fns";
import { fromAddress } from "react-geocode";

import getDataFromCookie from "@/components/utmData/utmHelper";
import { ORIGINAL_SERVICE_NAME, RECORD_MAPPING, ZIP_CODE_API_URL } from "./constants";

interface GeocodingResponse {
  results: {
    geometry: {
      location: {
        lat: number;
        lng: number;
      };
    };
    formatted_address: string;
    address_components: any;
  }[];
  status: string;
}
interface FormValues {
  "First Name": string;
  "Last Name": string;
  "Company Name": string;
  "Email Address": string;
  Phone: string;
  "Additional Information"?: string;
  "Delivery Date"?: Date;
  Duration?: string;
  "Desired Delivery Date"?: Date;
  Industries?: string;
  site_address_city?: string;
  site_address_state?: string;
  site_address_zip?: string;
  "Product Interest*"?: string;
  "Project Zip/Postal Code*"?: string;
  "Phone Number*"?: string;
  Email?: string;
  [key: string]: string | Date | undefined;
}

interface UnitData {
  fields?: {
    name?: string;
    obo?: string;
    price?: string;
    unitNumber?: string;
    productType?: string;
    branch?: {
      sys?: {
        id: string;
      };
    };
  };
}

interface BranchEntry {
  sys?: {
    id: string;
  };
  fields?: {
    city?: string;
    state?: string;
    name?: string;
  };
}

interface ParsedUnitData {
  name?: string;
  obo?: string;
  price?: string;
  unitNumber?: string;
  productType?: string;
  unitLocation?: string;
  branchName?: string;
}

interface RadioCards {
  [key: string]: string;
}

interface CheckBoxCard {
  [key: string]: boolean;
}

interface TemporaryData {
  Key: string;
  Name: string;
  Value: string;
}

interface RequestSupportData {
  Portal_First_Name__c?: string;
  Portal_Last_Name__c?: string;
  Portal_Email_Address__c?: string;
  Portal_Phone_Number__c?: string;
  Requested_country?: string;
  Requested_state?: string;
  Requested_zip?: string;
  Requested_city?: string;
  Request_Type__c?: string;
  units?: string;
  prefDate?: string;
  New_Site_state?: string;
  New_Site_zip?: string;
  New_Site_city?: string;
  New_Site_country?: string;
  [key: string]: any; // For other dynamic properties
}

/**
 * Retrieves and parses geocoding information from a formatted address string
 * @param selectedPlaceFormattedAddress - The formatted address string to geocode
 * @param saveState - Whether to save the state information to localStorage
 * @returns Parsed address components including city, state, zip code, etc.
 */
export const getGeocodingAddress = async (selectedPlaceFormattedAddress: string, saveState: boolean = false) => {
  const data = (await fromAddress(selectedPlaceFormattedAddress)) as GeocodingResponse;

  let city = "",
    state = "",
    zipCode = "",
    address = "",
    streetNumber = "",
    neighborhood = "",
    country = "";
  const components = data.results[0].address_components || [];
  const formatted_address = data.results[0].formatted_address as string;

  for (const target of components) {
    const types = target.types || [];

    if (types.includes("locality")) {
      city = target.long_name;
    } else if (types.includes("administrative_area_level_1")) {
      state = target.long_name;

      if (saveState) {
        const stateData = JSON.stringify({ long: target.long_name, short: target.short_name });
        localStorage.setItem("siteState", stateData);
      }
    } else if (types.includes("postal_code")) {
      zipCode = target.long_name;
    } else if (types.includes("route")) {
      address = target.long_name;
    } else if (types.includes("neighborhood")) {
      neighborhood = target.long_name;
    } else if (types.includes("street_number")) {
      streetNumber = target.long_name;
    } else if (types.includes("country")) {
      country = target.long_name;
    }
  }

  return {
    city,
    state,
    zipCode,
    address,
    streetNumber,
    neighborhood,
    country,
    formatted_address,
  };
};

// ! todo: make env variables read from server
const OwnerId = "3D00G8c000006wyqj";
const RequesterC = "0058c00000CpVJOAA3";

/**
 * Prepares the payload for a Request A Quote Buy form submission
 * @param values - Form values from the submission
 * @param unit - Unit information object
 * @param siteAddress - The site address string
 * @returns Formatted payload object for the API
 */
export const preparePayloadRAQBuy = async (values: FormValues, unit: ParsedUnitData, siteAddress: string) => {
  const temporaryData: { [key: string]: string }[] = [
    {
      Key: "",
      Name: "Delivery Date",
      Value: values["Delivery Date"] ? format(values["Delivery Date"], "MM/dd/yyyy") : "",
    },

    {
      Key: "",
      Name: "Duration",
      Value: "I'd like to purchase a unit instead of rent",
    },

    {
      Key: "",
      Name: "Product Unit Number",
      Value: unit.unitNumber || "",
    },
    {
      Key: "",
      Name: "Product Branch",
      Value: unit.branchName || "",
    },
    {
      Key: "",
      Name: "Product Name",
      Value: unit.name || "",
    },
    {
      Key: "",
      Name: "Product Price",
      Value: unit.price || "",
    },
    {
      Key: "",
      Name: "Product Location",
      Value: unit.unitLocation || "",
    },
    {
      Key: "",
      Name: "Interests",
      Value: values["Additional Information"] || "",
    },
  ];

  const payload = {
    Purchase_Decision__c: "Purchase",
    Purchase__c: true,
    "Quote Type": "Purchase",
    Type: 100,
    Token: "ed548cf1-3106-461b-ba68-fa98b3f5bd0a",
    Campaign: "Online Showroom",
    IsDisplayAd: false,
    Quote_Type__c: "Sale - Used",
    FirstName: values["First Name"],
    LastName: values["Last Name"],
    Company: values["Company Name"],
    Email: values["Email Address"],
    Phone: values.Phone,
    SiteAddress1: "N/A",
    Site_Address__c: "N/A",
    Site_City__c: "N/A",
    Site_State__c: "N/A",
    Site_Zip__c: "N/A",
    Fax: "",
    SendDebugEmail: false,
    DebugSource: null,
    Requester__c: RequesterC,
    OwnerId,
    SiteZip: "N/A",
    Data: temporaryData.filter((item: any) => !!item.Value),
    LeadQA_Additional_Information__c: values["Additional Information"],
    Interest: values["Additional Information"],
    LeadQA_Requested_Delivery_Date__c: "",
    ga_campaign: "",
    ga_content: "",
    ga_medium: "",
    ga_source: "",
    ga_term: "",
  };

  payload.SiteAddress1 = siteAddress || "N/A";
  payload.Site_Address__c = siteAddress || "N/A";
  payload.Site_City__c = values.site_address_city || "N/A";
  payload.Site_State__c = values.site_address_state || "N/A";
  payload.Site_Zip__c = values.site_address_zip || "N/A";
  payload.SiteZip = values.site_address_zip || "N/A";

  // Send UTM Data
  const utmDataFromCookie = getDataFromCookie();

  if (utmDataFromCookie.utm_campaign) {
    payload.ga_campaign = utmDataFromCookie.utm_campaign;
  }
  if (utmDataFromCookie.utm_content) {
    payload.ga_content = utmDataFromCookie.utm_content;
  }
  if (utmDataFromCookie.utm_medium) {
    payload.ga_medium = utmDataFromCookie.utm_medium;
  }
  if (utmDataFromCookie.utm_source) {
    payload.ga_source = utmDataFromCookie.utm_source;
  }
  if (utmDataFromCookie.utm_term) {
    payload.ga_term = utmDataFromCookie.utm_term;
  }

  return payload;
};

/**
 * Parses unit data for the Request A Quote Buy form
 * @param unit - Raw unit data object
 * @param Entry - Entry data containing branch information
 * @returns Parsed unit data object
 */
export const parseData_RAQBuy_unitData = (unit: UnitData, Entry: BranchEntry[]): ParsedUnitData => {
  const tmp: any = {
    name: unit.fields?.name,
    obo: unit.fields?.obo,
    price: unit.fields?.price,
    unitNumber: unit.fields?.unitNumber,
    productType: unit.fields?.productType,
  };

  const branchId = unit.fields?.branch?.sys?.id;

  if (branchId) {
    for (const target of Entry || []) {
      const subId = target.sys?.id;

      if (subId === branchId) {
        tmp.unitLocation = `${target.fields?.city}, ${target.fields?.state}`;
        tmp.branchName = target.fields?.name;

        break;
      }
    }
  }

  return tmp;
};

/**
 * Prepares the payload for a standard Request A Quote form submission
 * @param values - Form values from the submission
 * @param radioCards - Selected radio card values
 * @param checkBoxCard - Selected checkbox values
 * @param siteAddress - The site address string
 * @param industryFallback - Fallback industry value
 * @returns Formatted payload object for the API
 */
export const preparePayloadRAQ = async (
  values: FormValues,
  radioCards: RadioCards,
  checkBoxCard: CheckBoxCard,
  siteAddress: string,
  industryFallback: string
) => {
  const temporaryData: TemporaryData[] = [
    {
      Key: "",
      Name: "Duration",
      Value: values.Duration || "",
    },
    {
      Key: "",
      Name: "Delivery Date",
      Value: values["Desired Delivery Date"] ? format(values["Desired Delivery Date"], "MM/dd/yyyy") : "",
    },
    {
      Key: "",
      Name: "Interest",
      Value: values["Additional Information"] || "",
    },
    {
      Key: "",
      Name: "Industry",
      Value: (values.Industries !== "Other" ? values.Industries : String(values[industryFallback])) ?? "",
    },
    {
      Key: "",
      Name: "Product Industry",
      Value: values.Industries === "Other" ? String(values[industryFallback] || "") : String(values.Industries || ""),
    },
  ];

  const boxesValues = Object.keys(checkBoxCard)
    .map((key) => (checkBoxCard[key] ? key : null))
    .filter(Boolean);

  if (boxesValues.length > 0) {
    const join = boxesValues.join("; ");
    temporaryData.push({
      Key: "",
      Name: "Product Type - Size",
      Value: `Store & Secure - ${join}`,
    });
  }

  for (const key in radioCards) {
    if (radioCards[key]) {
      temporaryData.unshift({
        Key: "",
        Name: "Product Type - Size",
        Value: `${key} - ${radioCards[key]}`,
      });
    }
  }

  const payload = {
    Type: 100,
    Token: "ed548cf1-3106-461b-ba68-fa98b3f5bd0a",
    Campaign: "WillScot Website",
    IsDisplayAd: false,
    FirstName: values["First Name"],
    LastName: values["Last Name"],
    Company: values["Company Name"],
    SiteAddress1: "N/A",
    Site_Address__c: "N/A",
    Site_City__c: "N/A",
    Site_State__c: "N/A",
    Site_Zip__c: "N/A",
    Phone: values.Phone,
    Fax: "",
    Email: values["Email Address"],
    SendDebugEmail: false,
    DebugSource: null,
    Industry: values.Industries !== "Other" ? values.Industries : values[industryFallback],
    Requester__c: RequesterC,
    OwnerId,
    SiteZip: "N/A",
    Data: temporaryData.filter((item: any) => !!item.Value),
    ga_campaign: "",
    ga_content: "",
    ga_medium: "",
    ga_source: "",
    ga_term: "",
  };

  payload.SiteAddress1 = siteAddress || "N/A";
  payload.Site_Address__c = siteAddress || "N/A";
  payload.Site_City__c = values.site_address_city || "N/A";
  payload.Site_State__c = values.site_address_state || "N/A";
  payload.Site_Zip__c = values.site_address_zip || "N/A";
  payload.SiteZip = values.site_address_zip || "N/A";

  // Send UTM Data
  const utmDataFromCookie = getDataFromCookie();

  if (utmDataFromCookie.utm_campaign) {
    payload.ga_campaign = utmDataFromCookie.utm_campaign;
  }
  if (utmDataFromCookie.utm_content) {
    payload.ga_content = utmDataFromCookie.utm_content;
  }
  if (utmDataFromCookie.utm_medium) {
    payload.ga_medium = utmDataFromCookie.utm_medium;
  }
  if (utmDataFromCookie.utm_source) {
    payload.ga_source = utmDataFromCookie.utm_source;
  }
  if (utmDataFromCookie.utm_term) {
    payload.ga_term = utmDataFromCookie.utm_term;
  }

  return payload;
};

/**
 * Prepares the payload for a column-based Request A Quote form submission
 * @param values - Form values from the submission
 * @returns Formatted payload object for the API
 */
export const preparePayloadRAQColumnForm = async (values: FormValues) => {
  const temporaryData: { [key: string]: string }[] = [
    {
      Key: "",
      Name: "Interest",
      Value: values["Additional Information"] || "N/A",
    },

    {
      Key: "",
      Name: "Product Type - Size",
      Value: values["Product Interest*"] || "N/A",
    },
  ];

  const payload = {
    Type: 100,
    Token: "ed548cf1-3106-461b-ba68-fa98b3f5bd0a",
    Campaign: "WillScot Website",
    IsDisplayAd: false,
    FirstName: values["First Name*"] || "N/A",
    LastName: values["Last Name*"] || "N/A",
    Company: values["Company Name"] || "N/A",
    Site_Zip__c: values["Project Zip/Postal Code*"],
    Phone: values["Phone Number*"],
    Fax: "",
    Email: values["Email"],
    SendDebugEmail: false,
    DebugSource: null,
    Industry: "N/A",
    Requester__c: RequesterC,
    OwnerId,
    SiteZip: values["Project Zip/Postal Code*"],
    Data: temporaryData.filter((item: any) => !!item.Value),
    LeadQA_Requested_Delivery_Date__c: "",
    ga_campaign: "",
    ga_content: "",
    ga_medium: "",
    ga_source: "",
    ga_term: "",
  };

  // Send UTM Data
  const utmDataFromCookie = getDataFromCookie();

  if (utmDataFromCookie.utm_campaign) {
    payload.ga_campaign = utmDataFromCookie.utm_campaign;
  }
  if (utmDataFromCookie.utm_content) {
    payload.ga_content = utmDataFromCookie.utm_content;
  }
  if (utmDataFromCookie.utm_medium) {
    payload.ga_medium = utmDataFromCookie.utm_medium;
  }
  if (utmDataFromCookie.utm_source) {
    payload.ga_source = utmDataFromCookie.utm_source;
  }
  if (utmDataFromCookie.utm_term) {
    payload.ga_term = utmDataFromCookie.utm_term;
  }

  return payload;
};

/**
 * Prepares the payload for a support request submission
 * @param requestData - Request data object containing form values
 * @returns Formatted payload object for the Salesforce API
 */
export const preparePayloadRequestSupport = (requestData: RequestSupportData) => {
  // backend provides Request_Type__c as long name, but we need to map it to original service name
  const originalServiceName = ORIGINAL_SERVICE_NAME[requestData["Request_Type__c"] || ""];
  const RecordTypeId = RECORD_MAPPING[originalServiceName]; // old logic, continue with originalServiceName name from here

  const remappedRequestData = doTheRemapping(requestData, originalServiceName);

  const payload = {
    Operation: "create",
    ObjectType: "case",
    ExternalID: [],
    sObjects: {
      sObject: [{ ...remappedRequestData }],
    },
  };

  if (RecordTypeId && RecordTypeId !== "N/A") {
    payload.sObjects.sObject[0]["RecordTypeId"] = RecordTypeId;
  }

  return payload;
};

/**
 * Remaps request payload fields to match Salesforce field requirements
 * @param payload - Original request payload
 * @param originalServiceName - Original service name for mapping
 * @returns Remapped payload object
 */
const doTheRemapping = (payload: RequestSupportData, originalServiceName: string): Record<string, unknown> => {
  const newPayload = {
    ...payload,
  };

  // ! we need to use country short name, but it comes from UI form as long name
  // ! when fetching all info about place, we save long name and short name in storage
  // ! and here we check (line 119) if the long name matches to value we get from hook form
  const savedState = localStorage.getItem("siteState") || "";
  const stateData = JSON.parse(savedState);

  // Rule 1: Concatenate Portal_First_Name__c & Portal_Last_Name__c to SuppliedName
  const firstNameAndLastName = [];
  if (typeof payload.Portal_First_Name__c === "string") {
    firstNameAndLastName.push(payload.Portal_First_Name__c);
  }
  if (typeof payload.Portal_Last_Name__c === "string") {
    firstNameAndLastName.push(payload.Portal_Last_Name__c);
  }

  const SuppliedName = firstNameAndLastName.join(" "); // Trick to remove spaces if one of the names is missing

  delete newPayload.Portal_First_Name__c;
  delete newPayload.Portal_Last_Name__c;
  delete newPayload.nonexistent; // ...?! TODO: check and remove

  newPayload.SuppliedName = SuppliedName;

  /****************************************************************/

  // Rule 2: Remove Portal_Email_Address__c and Portal_Phone_Number__c
  const SuppliedEmail = payload.Portal_Email_Address__c;
  const SuppliedPhone = payload.Portal_Phone_Number__c;
  // delete newPayload.Portal_Email_Address__c;
  delete newPayload.Portal_Phone_Number__c;
  newPayload.SuppliedEmail = SuppliedEmail;
  newPayload.SuppliedPhone = SuppliedPhone;

  /****************************************************************/

  // Rule 3: Remove Requested_country, Requested_state, Requested_zip, Requested_city
  const SiteCountryWeb = payload.Requested_country;
  const SiteStateWeb = payload.Requested_state === stateData?.long ? stateData?.short : "";
  const SiteZipWeb = payload.Requested_zip;
  const SiteCityWeb = payload.Requested_city;

  delete newPayload.Requested_country;
  delete newPayload.Requested_state;
  delete newPayload.Requested_zip;
  delete newPayload.Requested_city;

  newPayload.Site_Country_Web__c = SiteCountryWeb;
  newPayload.Site_State_Web__c = SiteStateWeb;
  newPayload.Site_Zip_Web__c = SiteZipWeb;
  newPayload.Site_City_Web__c = SiteCityWeb;
  // newPayload.Site_Street_Web__c = SiteStreetWeb;

  /****************************************************************/

  // Rule 4: Remap units
  if (payload?.units) {
    const Unit__c = payload?.units;
    // const Unit__c = payload?.units?.[0];
    delete newPayload.units;
    newPayload.Unit__c = Unit__c;
  }

  /****************************************************************/

  // Rule 5: Remove prefDate, add Requested_Pick_Up_Date__c
  if (payload.prefDate) {
    const Requested_Pick_Up_Date__c = payload.prefDate;
    delete newPayload.prefDate;
    // Format date to ISO string but only use the YYYY-MM-DD part
    newPayload.Requested_Pick_Up_Date__c = new Date(Requested_Pick_Up_Date__c).toISOString().split("T")[0];
  }

  /****************************************************************/
  // Rule 6:
  newPayload.OwnerId = "00G8c000006wyqjEAA"; // Hardcoded for now
  newPayload.Type = "Feature Request"; // Hardcoded for now

  if (originalServiceName === "Service") {
    newPayload.Reason = "Complex functionality"; // Hardcoded for now
  }

  // Rule 6: USE Case_Issue__c and set it to "Request" !!! SUPER IMPORTANT !!! Or else Request_Type__c will be overwritten
  newPayload.Case_Issue__c = "Request";

  /****************************************************************/

  // Rule 7: Remove Request_Type__c WEB-1335
  newPayload.Subject = `${originalServiceName} - WS Website`; //originalServiceName is mapped to correct names instead of Request_Type__c
  delete newPayload.Request_Type__c; // this still exists in payload, but we don't need it

  newPayload.Origin = "Web";
  /****************************************************************/

  // Rule 8: replace address fields with correct ones
  if (newPayload.New_Site_state && payload.New_Site_zip && payload.New_Site_city) {
    newPayload.New_Site_State__c = newPayload.New_Site_state;
    newPayload.New_Site_Zip__c = payload.New_Site_zip;
    newPayload.New_Site_City__c = payload.New_Site_city;

    delete newPayload.New_Site_state;
    delete newPayload.New_Site_zip;
    delete newPayload.New_Site_city;
    delete newPayload.New_Site_country;
  }

  /****************************************************************/

  // Parse data from cookie to get utm values from cookie
  const utm_source = document.cookie
    .split("; ")
    .find((row) => row.startsWith("utm_source"))
    ?.split("=")[1];

  const utm_medium = document.cookie
    .split("; ")
    .find((row) => row.startsWith("utm_medium"))
    ?.split("=")[1];

  const utm_campaign = document.cookie
    .split("; ")
    .find((row) => row.startsWith("utm_campaign"))
    ?.split("=")[1];

  const utm_term = document.cookie
    .split("; ")
    .find((row) => row.startsWith("utm_term"))
    ?.split("=")[1];

  const utm_content = document.cookie
    .split("; ")
    .find((row) => row.startsWith("utm_content"))
    ?.split("=")[1];

  if (utm_source) {
    newPayload.utm_source__c = utm_source;
    newPayload.ga_source = utm_source;
  }
  if (utm_medium) {
    newPayload.utm_medium__c = utm_medium;
    newPayload.ga_medium = utm_medium;
  }
  if (utm_campaign) {
    newPayload.utm_campaign__c = utm_campaign;
    newPayload.ga_campaign = utm_campaign;
  }
  if (utm_term) {
    newPayload.utm_term__c = utm_term;
    newPayload.ga_term = utm_term;
  }
  if (utm_content) {
    newPayload.utm_content__c = utm_content;
    newPayload.ga_content = utm_content;
  }

  // *** 🧙🏼‍♂️ Magic Remapping END 🪄 ***
  return newPayload;
};

interface ZippopotamResponse {
  "post code": string;
  country: string;
  "country abbreviation": string;
  places: Array<{
    "place name": string;
    longitude: string;
    state: string;
    "state abbreviation": string;
    latitude: string;
  }>;
}

/**
 * Validates a zip code using the Zippopotam API
 * @param zip - Zip code to validate
 * @param country - Country code for the zip code
 * @returns Object containing validation status and API response data
 */
export const checkZippopotam = async (
  zip: string,
  country: string
): Promise<{
  valid: boolean;
  data: ZippopotamResponse | null;
}> => {
  try {
    const res = await fetch(`${ZIP_CODE_API_URL}/${country}/${zip}`, { cache: "no-store" });
    if (!res.ok) return { valid: false, data: null };

    const data = await res.json();

    return { valid: res.ok, data };
  } catch (error) {
    return { valid: false, data: null };
  }
};
