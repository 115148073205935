/* eslint-disable @typescript-eslint/no-explicit-any */

"use client";

import React from "react";
import ContentLayout from "@/components/layouts/ContentLayout";
import Image from "next/image";

import AppText from "@/components/other/AppText";

import AppButton from "@/components/buttons/AppButton";
import CustomLink from "@/components/other/CustomLink";
import { AppIconButton } from "../buttons/AppIconButton";
import RichTextRenderer from "./RichTextRenderer";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../ui/accordion";
import { cn } from "@/lib/utils";
import { componentDataMockExample } from "@/mocks/productOfferingsMocks";
import { ChevronRight } from "lucide-react";
import { SUB_HEADING_MEDIUM } from "@/styles";

interface ProductOfferingsProps {
  data?: {
    label: string;
    heading: string;
    productContent: any;
    productOfferingsArray: {
      id: string;
      internalLabel: string;
      tabLabel: string;
      imageSrc: string;
      placeholder: string;
      imgAlt: string;
      content: any;
      ctaLabel: string;
      ctaLink: string;
    }[];
    paddingBottom?: string;
  };
}

const ProductOfferings: React.FC<ProductOfferingsProps> = (props) => {
  const [activeAccordion, setActiveAccordion] = React.useState<string | null>();
  const { label, heading, productOfferingsArray } = props?.data ?? componentDataMockExample ?? {};

  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);
  const selectedItem: any = productOfferingsArray?.[selectedItemIndex] ?? productOfferingsArray?.[0] ?? {};

  const productContent = props?.data?.productContent;

  return (
    <ContentLayout bgColorClass="bg-white" paddingBottom={props?.data?.paddingBottom}>
      <div className="flex flex-col justify-center mb-12 max-w-[736px]">
        {label && (
          <AppText type={"LABEL_LARGE"} className="text-royal-blue mb-2">
            {label}
          </AppText>
        )}

        {heading && (
          <AppText type={"HEADLINE_MEDIUM"} className="font-bold">
            {heading}
          </AppText>
        )}

        {productContent && (
          <RichTextRenderer productOfferings richTextDocument={productContent} locations className="text-sm" />
        )}
      </div>

      <div className={"lg:hidden flex flex-col col-span-4 lg:col-span-6 lg:mr-[40px]"}>
        <Accordion
          type="single"
          collapsible
          className="flex flex-col w-full gap-4"
          onValueChange={(e) => setActiveAccordion(e)}
        >
          {(productOfferingsArray ?? []).map((section: any) => (
            <AccordionItem
              value={section.id}
              key={section.id}
              className={cn("border-b-2 px-6", activeAccordion === section.id && "border-l-mellow")}
            >
              <AccordionTrigger
                size={24}
                className={cn(
                  "underline-none overflow-hidden font-sairaSemiCondensed text-ellipsis w-full pt-0 hover:no-underline text-[20px] font-[600] uppercase tracking-[0.5px]"
                )}
              >
                {section.tabLabel}
              </AccordionTrigger>
              <AccordionContent className="py-0">
                <div className="grid grid-cols-12">
                  <Image
                    src={section.imageSrc || "/placeholders/no-preview.png"}
                    sizes="100vw"
                    width={0}
                    height={0}
                    className="object-cover w-full col-span-6 mb-4"
                    alt={section.imgAlt}
                  />
                </div>

                <RichTextRenderer
                  richTextDocument={section.content}
                  id={section.id}
                  locations
                  className={cn("text-sm")}
                />

                <AppIconButton
                  intent={"secondary"}
                  label={section.ctaLabel}
                  link={section.ctaLink}
                  icon={<ChevronRight size={20} aria-label="chevron right icon" />}
                  iconOnRightSide
                  className={"mt-[24px] mb-8"}
                />
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>

      <div className="lg:grid lg:grid-cols-12 xl:gap-10 hidden grid-cols-4 gap-6">
        <div className="col-span-4 lg:col-span-8 flex flex-col rounded-[3px] w-full">
          <div className="relative mb-4">
            <Image
              src={selectedItem.imageSrc || "/placeholders/no-preview.png"}
              sizes="(max-width: 1024px) 50vw, 900px"
              width={0}
              height={0}
              className="object-cover w-full max-h-[412px]"
              alt={selectedItem.imgAlt}
            />
          </div>

          <AppText type={"TITLE_MEDIUM"} className="mb-2">
            {selectedItem.internalLabel}
          </AppText>

          <RichTextRenderer
            richTextDocument={selectedItem.content}
            id={selectedItem.id}
            locations
            className={"text-sm mb-6"}
          />
          {selectedItem.ctaLink && selectedItem.ctaLabel && (
            <CustomLink href={selectedItem.ctaLink} lang={"en"} className="w-fit">
              <AppButton intent={"secondary"} tabIndex={-1} label={selectedItem.ctaLabel} />
            </CustomLink>
          )}
        </div>

        {/* right side buttons */}
        <div className="flex flex-col col-span-4 gap-2">
          {(productOfferingsArray ?? []).map((item, idx) => {
            if (item.ctaLink) {
              return (
                <CustomLink key={idx} href={item.ctaLink} className="ml-auto">
                  <AppIconButton
                    intent="ghost"
                    onMouseOver={() => {
                      setSelectedItemIndex(idx);
                    }}
                    label={item.tabLabel}
                    icon={<ChevronRight size={20} aria-label="chevron right icon" />}
                    iconOnRightSide
                    className={cn(SUB_HEADING_MEDIUM, "text-right whitespace-break-spaces")}
                    linkClass="ml-auto"
                  />
                </CustomLink>
              );
            } else {
              return (
                <div className="ml-auto" key={idx}>
                  <AppIconButton
                    intent="ghost"
                    onMouseOver={() => {
                      setSelectedItemIndex(idx);
                    }}
                    label={item.tabLabel}
                    icon={<ChevronRight size={20} aria-label="chevron right icon" />}
                    iconOnRightSide
                    className={cn(SUB_HEADING_MEDIUM, "text-right whitespace-break-spaces")}
                    linkClass="ml-auto"
                  />
                </div>
              );
            }
          })}
        </div>
      </div>
    </ContentLayout>
  );
};

export default React.memo(ProductOfferings);
