"use client";
import React from "react";
import { Swiper } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css";
import { SwiperOptions } from "swiper/types";
import { ArrowLeft, ArrowRight, ChevronLeft, ChevronRight } from "lucide-react";

import { useSwiper } from "@/hooks/useSwiper";
import ArrowPagination from "@/components/other/ArrowPagination";
import { AppIconButton } from "@/components/buttons/AppIconButton";
import { cn } from "@/lib/utils";

interface CarouselProps {
  children: React.ReactNode;
  className?: string;
  wrapperClassName?: string;
  paginationType:
    | "pageSelect"
    | "arrowSlider"
    | "arrowsNew"
    | "sideArrows"
    | "arrowSliderProductFeature"
    | "customizations"
    | "none";
  bg?: "dark" | "light" | undefined;
  cardsOverflow?: boolean;
  withoutWrapper?: boolean;
}

function Carousel({
  children,
  wrapperClassName,
  className,
  paginationType,
  bg,
  cardsOverflow,
  withoutWrapper,
  ...restProps
}: CarouselProps & Omit<SwiperOptions, "onSwiper" | "onInit" | "onSlideChange" | "onBreakpoint">) {
  const { swiperRef, onSwiperChange, currentPage, onNextPageClick, onPrevPageClick, pageCount } = useSwiper();

  function renderPagination() {
    if (paginationType === "none" || pageCount <= 1) {
      return null;
    }

    if (paginationType === "sideArrows") {
      return (
        <div className="absolute left-0 right-0">
          <div className=" flex justify-between w-full p-1">
            <AppIconButton
              onClick={onPrevPageClick}
              icon={<ChevronLeft size={20} aria-label="chevron left icon" />}
              intent={"secondary"}
              size={"md"}
              title={"previous"}
              className={
                "bg-white hover:!bg-black-10 active:!bg-black-20 shadow-lg border-none outline-none w-[32px] h-[32px] p-1.5 z-10"
              }
            />
            <AppIconButton
              onClick={onNextPageClick}
              icon={<ChevronRight size={20} aria-label="chevron right icon" />}
              intent={"secondary"}
              size={"md"}
              title={"next"}
              className={
                "bg-white hover:!bg-black-10 active:!bg-black-20 shadow-lg border-none outline-none w-[32px] h-[32px] p-1.5 z-10"
              }
            />
          </div>
        </div>
      );
    }

    if (paginationType === "arrowSlider") {
      return (
        <div className="absolute top-0 left-0 flex flex-col justify-center w-full h-full">
          <div
            className={`flex justify-between w-full mx-auto xl:max-w-screen-2xl lg:max-w-screen-xl sm:max-w-screen-md md:max-w-screen-lg
             px-6 sm:px-[76px] xl:px-[202px] 2xl:px-[330px]`}
          >
            <AppIconButton
              onClick={onPrevPageClick}
              icon={<ChevronLeft size={20} aria-label="chevron left icon" />}
              intent={"secondary"}
              size={"md"}
              title={"previous"}
              className={
                "bg-white hover:!bg-black-10 active:!bg-black-20 shadow-lg border-none outline-none focus:ring-0 focus:ring-offset-0 w-[32px] h-[32px] p-1.5 z-10"
              }
            />
            <AppIconButton
              onClick={onNextPageClick}
              icon={<ChevronRight size={20} aria-label="chevron right icon" />}
              intent={"secondary"}
              size={"md"}
              title={"next"}
              className={
                "bg-white hover:!bg-black-10 active:!bg-black-20 shadow-lg border-none outline-none focus:ring-0 focus:ring-offset-0 w-[32px] h-[32px] p-1.5 z-10"
              }
            />
          </div>
        </div>
      );
    }

    if (paginationType === "arrowSliderProductFeature") {
      return (
        <div className="absolute top-0 left-0 flex flex-col justify-center w-full h-full pb-10">
          <div className="w-full flex justify-between mx-auto xl:max-w-screen-2xl lg:max-w-screen-xl sm:max-w-screen-md md:max-w-screen-lg sm:py-[80px] py-[48px] px-6 sm:px-[72px] xl:px-[200px] 2xl:px-[328px]">
            <AppIconButton
              onClick={onPrevPageClick}
              icon={<ChevronLeft size={20} aria-label="chevron left icon" />}
              intent={"secondary"}
              size={"md"}
              title={"previous"}
              className={
                "bg-white hover:!bg-black-10 active:!bg-black-20 shadow-lg border-none outline-none focus:ring-0 focus:ring-offset-0 w-[32px] h-[32px] p-1.5 z-10"
              }
            />
            <AppIconButton
              onClick={onNextPageClick}
              icon={<ChevronRight size={20} aria-label="chevron right icon" />}
              intent={"secondary"}
              size={"md"}
              title={"next"}
              className={
                "bg-white hover:!bg-black-10 active:!bg-black-20 shadow-lg border-none outline-none focus:ring-0 focus:ring-offset-0 w-[32px] h-[32px] p-1.5 z-10"
              }
            />
          </div>
        </div>
      );
    }

    if (paginationType === "arrowsNew") {
      return (
        <>
          <div className="md:justify-center absolute top-0 left-0 z-10 flex flex-col justify-end h-full px-4 py-4">
            <AppIconButton
              onClick={onPrevPageClick}
              bg={bg}
              icon={<ArrowLeft size={20} aria-label="arrow left icon" />}
              intent={"secondary"}
              title={"previous"}
              className="px-2"
            />
          </div>
          <div className="md:justify-center absolute top-0 right-0 z-10 flex flex-col justify-end h-full px-4 py-4">
            <AppIconButton
              onClick={onNextPageClick}
              bg={bg}
              icon={<ArrowRight size={20} aria-label="arrow right icon" />}
              intent={"secondary"}
              title={"next"}
              className="px-2"
            />
          </div>
        </>
      );
    }

    if (paginationType === "pageSelect") {
      return (
        <ArrowPagination
          className={"justify-end mt-[40px] pr-4"}
          currentPage={currentPage}
          pageCount={pageCount}
          onPrevPageClick={onPrevPageClick}
          onNextPageClick={onNextPageClick}
          bg={bg}
        />
      );
    }
  }

  if (withoutWrapper) {
    return (
      <Swiper
        className={className}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        onInit={onSwiperChange}
        onSlideChange={onSwiperChange}
        onBreakpoint={onSwiperChange}
        {...restProps}
      >
        {children}
      </Swiper>
    );
  }
  return (
    <>
      <div className={cn("relative", wrapperClassName, cardsOverflow && "lg:w-overflow-right")}>
        <Swiper
          className={className}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          onInit={onSwiperChange}
          onSlideChange={onSwiperChange}
          onBreakpoint={onSwiperChange}
          {...restProps}
        >
          {children}
        </Swiper>
      </div>
      {renderPagination()}
    </>
  );
}

export default React.memo(Carousel);
