/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React from "react";
import ContentLayout from "../layouts/ContentLayout";
import RichTextRenderer from "./RichTextRenderer";
import AppText from "./AppText";
// import { Play } from "@/assets/Icons";
import Image from "next/image";
import { cn } from "@/lib/utils";

interface IFullVideoWidth {
  id: string;
  label?: string;
  heading: string;
  subheading?: string;
  content?: any;
  type: string;
  mediaDesktop: string;
  mediaMobile: string;
  placeholder?: string;
  mediaDesktopAlt: string;
  mediaMobileAlt: string;
  videoPoster?: string;
  leftAlign: boolean;
}

const FullWidthVideo = ({
  id,
  label,
  heading,
  subheading,
  content,
  type,
  mediaDesktop,
  mediaMobile,
  mediaDesktopAlt,
  mediaMobileAlt,
  placeholder,
  videoPoster,
  leftAlign,
}: IFullVideoWidth) => {
  // const videoRef = useRef<HTMLVideoElement>(null);
  // const [isPlaying, setIsPlaying] = useState(false);

  // async function onVideoClick() {
  //   if (videoRef.current?.paused) {
  //     await videoRef.current?.play();
  //   } else {
  //     videoRef.current?.pause();
  //   }
  // }

  return (
    <ContentLayout bgColorClass="bg-white">
      <div className={cn("flex flex-col max-w-[736px]", leftAlign ? "justify-start" : "mx-auto justify-center")}>
        {label && (
          <AppText type={"LABEL_LARGE"} className={cn("text-royal-blue mb-2", !leftAlign && "text-center")}>
            {label}
          </AppText>
        )}
        <AppText type={"HEADLINE_MEDIUM"} className={cn("mb-4", !leftAlign && "text-center")}>
          {heading}
        </AppText>
        {subheading && (
          <AppText type={"SUB_HEADING_SMALL"} className={cn("mb-4", !leftAlign && "text-center")}>
            {subheading}
          </AppText>
        )}

        <RichTextRenderer
          richTextDocument={content}
          id={`rt-${id}`}
          locations
          className={cn("mb-10 text-sm", !leftAlign && "text-center")}
        />
      </div>
      {type === "video" ? (
        <div className=" relative">
          <video
            className="object-fill w-full"
            controls={true}
            preload="auto"
            // ref={videoRef}
            // onClick={onVideoClick}
            // onPlaying={() => setIsPlaying(true)}
            // onPause={() => setIsPlaying(false)}
            muted={false}
            autoPlay={false}
            loop={true}
            playsInline={true}
            poster={videoPoster}
          >
            <source src={mediaDesktop} type="video/mp4" />
          </video>
          {/* {!isPlaying && (
            <div
              onClick={onVideoClick}
              className={
                "w-14 h-14 bg-black-20 bg-opacity-80 cursor-pointer rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
              }
            >
              <Play />
            </div>
          )} */}
        </div>
      ) : (
        <div>
          <div className="lg:block relative hidden">
            <Image
              src={mediaDesktop || "/placeholders/no-preview.png"}
              alt={mediaDesktopAlt}
              sizes="100vw"
              fill
              placeholder="blur"
              blurDataURL={placeholder}
              className="object-cover w-full"
            />
          </div>
          <div className="lg:hidden relative block">
            <Image
              src={mediaMobile || "/placeholders/no-preview.png"}
              alt={mediaMobileAlt}
              sizes="100vw"
              fill
              placeholder="blur"
              blurDataURL={placeholder}
              className="object-cover w-full"
            />
          </div>
        </div>
      )}
    </ContentLayout>
  );
};

export default FullWidthVideo;
