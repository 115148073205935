/* eslint-disable @typescript-eslint/no-explicit-any */

import { Listbox, Transition } from "@headlessui/react";
import { Check, ChevronDown } from "lucide-react";

export interface IOption {
  name: string;
  value: string;
}

interface IMultiselect {
  selectedItems: IOption[] | [];
  setSelectedItems: any;
  allLabel: string;
  selectedLabel: string;
  allItems: IOption[] | [];
}

function isSelected(value: string, all: IOption[] | []) {
  return all.find((el) => el.value === value) ? true : false;
}

const Multiselect = ({ selectedItems, setSelectedItems, allLabel, selectedLabel, allItems }: IMultiselect) => {
  function handleChange(value: string[] | []) {
    if (value.length) {
      const newSelections = [];

      for (const id of value) {
        newSelections.push(allItems.find((el) => el.value === id));
      }

      setSelectedItems(newSelections);
    } else {
      setSelectedItems([]);
    }
  }

  const _selectedItems = selectedItems.map((sel: IOption) => sel.value);

  return (
    <Listbox as="div" value={_selectedItems} onChange={handleChange} multiple>
      {() => (
        <>
          <div className="relative">
            <span className="inline-block w-full rounded-md shadow-sm">
              <Listbox.Button
                className={`focus-visible:outline-dijon sm:text-sm sm:leading-5 
              relative w-full h-12 py-2 pl-3 pr-10 text-left transition duration-150 ease-in-out bg-white border 
              border-black-20 cursor-default rounded-[3px]
              font-saira text-16 font-[500] leading-[1.75] tracking-[-0.1px]`}
              >
                <span className="block truncate">
                  {selectedItems.length < 1 ? allLabel : `${selectedLabel} (${selectedItems.length})`}
                </span>

                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronDown />
                </span>
              </Listbox.Button>
            </span>
            <Transition
              as="div"
              unmount={false}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="top-2 left-2 right-2 z-10 absolute border-black-20 border-[1px] !w-auto bg-white rounded-md overflow-hidden shadow-[0px_3px_8px_rgba(0,0,0,0.3)]"
            >
              <Listbox.Options
                static
                className="max-h-80 focus:outline-none sm:text-sm sm:leading-5 z-1 px-2 py-4 overflow-auto text-base leading-6"
              >
                {allItems.map((item: any) => {
                  const selected = isSelected(item.value, selectedItems);

                  return (
                    <Listbox.Option key={item.value} value={item.value}>
                      {({ active }) => (
                        <div
                          className={`${
                            active ? "text-navy-blue" : "text-gray-900"
                          } cursor-default select-none relative py-2 px-4 flex items-center gap-4`}
                        >
                          {selected ? (
                            <div className="h-5 w-5 border-black bg-black border-[1px] rounded-[4px]">
                              <Check stroke="white" size={20} className="-mt-[1px] -ml-[1px]" aria-label="check icon" />
                            </div>
                          ) : (
                            <div className="h-5 w-5 border-black border-[1px] rounded-[4px]" />
                          )}
                          <span
                            className={`${
                              selected ? "font-[500]" : "font-[400]"
                            } block truncate font-saira text-16 leading-[1.75] tracking-[-0.1px]`}
                          >
                            {item.name}
                          </span>
                        </div>
                      )}
                    </Listbox.Option>
                  );
                })}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default Multiselect;
