/* eslint-disable @next/next/no-img-element */
import React, { useEffect } from "react";
import useEmblaCarousel from "embla-carousel-react";

import styles from "./styles.module.css";

export const EmblaVerticalCarousel = ({
  slides,
  options,
  onSlideClick,
  currentPage,
}: {
  slides: string[];
  options: { [key: string]: string | boolean };
  onSlideClick: (index: number) => void;
  currentPage: number;
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  useEffect(() => {
    const cp = currentPage - 1;

    emblaApi?.scrollTo(cp);
  }, [currentPage, emblaApi]);

  return (
    <div className="lg:col-span-3 lg:flex-col lg:grid-cols-1 flex flex-row col-span-4">
      <div className="h-full overflow-hidden aspect-[1.7]" ref={emblaRef}>
        <div className={styles.emblaContainer}>
          {slides.map((thumbnail: string, idx: number) => (
            <div
              className="flex-1 w-full aspect-[1.7]"
              key={idx}
              style={{
                paddingTop: idx === 0 ? "16px" : "0", // Add padding to the last slide
              }}
            >
              <img
                onClick={() => onSlideClick(idx)}
                src={thumbnail}
                alt={"thumbnail"}
                className="aspect-[1.7] object-cover"
                style={{ border: idx === currentPage - 1 ? "2px solid #0284c7" : "" }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const EmblaHorizontalCarousel = ({
  slides,
  options,
  onSlideClick,
  currentPage,
}: {
  slides: string[];
  options: { [key: string]: string | boolean };
  onSlideClick: (index: number) => void;
  currentPage: number;
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  useEffect(() => {
    const cp = currentPage - 1;

    emblaApi?.scrollTo(cp);
  }, [currentPage, emblaApi]);

  return (
    <section className={styles.emblaHorizontal}>
      <div className={styles.emblaHorizontalViewport} ref={emblaRef}>
        <div className={styles.emblaHorizontalContainer}>
          {slides.map((thumbnail: string, idx: number) => (
            <div className={styles.emblaHorizontalSlide} key={idx}>
              <img
                onClick={() => onSlideClick(idx)}
                src={thumbnail}
                alt={"thumbnail"}
                className="aspect-[1.7] object-cover select-none"
                style={{ border: idx === currentPage - 1 ? "2px solid #0284c7" : "" }}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
