/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ExpandMore24 } from "@/assets/Icons";

interface Select {
  name: string;
  value: string;
}

export interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  selectedItem: Select;
  setSelected: React.Dispatch<React.SetStateAction<Select>> | ((category: Select) => void);
  disabled: boolean;
  options: Select[];
  // dict: { [key: string]: string };
}

const Select = ({ selectedItem, setSelected, options, disabled }: SelectProps) => {
  const handleChange = (value: string) => {
    const sel = options.find((item) => item.value === value);

    sel && setSelected(sel);
  };
  return (
    <Listbox value={selectedItem.value} onChange={handleChange} name="category" disabled={disabled}>
      <div className="relative">
        <Listbox.Button
          className={`focus:outline-none focus-visible:border-royal-blue border-1 border-black-20 
        focus-visible:ring-2 focus-visible:ring-royal-blue focus-visible:ring-opacity-75 
        focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm relative 
        w-full h-12 py-2 pl-3 pr-10 text-left bg-white border rounded-lg cursor-default
        font-saira text-16 font-[500] leading-[1.75] tracking-[-0.1px]`}
        >
          <span className="block truncate">{selectedItem.name}</span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ExpandMore24 aria-hidden="true" />
          </span>
        </Listbox.Button>
        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Listbox.Options className="max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg">
            {options.map((item) => (
              <Listbox.Option
                key={item.value}
                className={({ active, selected }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${selected && "text-royal-blue"} ${
                    active && "bg-black-10"
                  }`
                }
                value={item.value}
              >
                {() => (
                  <span className={`block truncate font-saira text-16 font-[400] leading-[1.75] tracking-[-0.1px]`}>
                    {item.name}
                  </span>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

Select.displayName = "Select";

export { Select };
