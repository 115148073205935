/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { ChevronRight, Search } from "lucide-react";

import { IDict } from "../other/constants";
import { CustomButton } from "./CustomButton";
import { CustomInputLocation, CustomInputUnit } from "./CustomInput";
import { ControlledCustomSelect } from "./CustomSelect";
import { AppIconButton } from "../buttons/AppIconButton";

import { APIProvider } from "@vis.gl/react-google-maps";
import { DISTANCE, PRICE, UNITS } from "./constants";

let addressInterval: any;

const SalesForm = ({ dict }: { dict: IDict }) => {
  const [activeBtn, setActiveBtn] = useState("location");
  const [distance, setDistance] = useState<string | undefined>("within50miles");
  const [unitType, setUnitType] = useState<string | undefined>();
  const [price, setPrice] = useState<string | undefined>();
  const [selectedPlace, setSelectedPlace] = useState<any>(null);
  const [unitId, setUnitId] = useState<string>("");
  const [clearAddressInput, setClearAddressInput] = useState(0);
  const errorMessageShown = useRef(false);
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const { push } = useRouter();

  useEffect(() => {
    clearInterval(addressInterval);
    if (!selectedPlace) return;

    if (selectedPlace?.name && Object.keys(selectedPlace).length === 1) {
      setClearAddressInput((prev) => prev + 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlace]);

  const handleSearch = () => {
    const params = new URLSearchParams(searchParams);

    if (activeBtn === "unit") {
      unitId && params.set("unit-id", unitId);
    } else {
      // ! collect params
      unitType && params.set("unit-type", unitType);
      price && params.set("price-range", price);

      // ! selectedPlace and distance are valid filters only if both have value
      if (selectedPlace && distance) {
        let lat, lng, name;

        if (selectedPlace.fromBrowser) {
          lat = selectedPlace.coords.lat;
          lng = selectedPlace.coords.lng;
          name = selectedPlace.formatted_address;
        } else {
          lat = selectedPlace.geometry?.location?.lat();
          lng = selectedPlace.geometry?.location?.lng();
          name = selectedPlace.formatted_address;
        }

        const coordinates = `${lat},${lng}`;

        params.set("distance", distance || "");
        params.set("coordinates", coordinates);
        params.set("name", name || "");
      }
    }
    // ! push to search results
    push(`${pathname}/search?${params.toString()}`);
  };

  const handleAllUnits = () => {
    const params = new URLSearchParams(searchParams);
    params.set("all-units", "true");

    // ! special case - get all units
    // push(`${pathname}/search?${params.toString()}`);
    push(`${window.location.pathname}/search`);
  };

  // ! controlled inputs - group into one obj?!
  const handleFilterChange = (value: string, name: string) => {
    if (name === "distance") {
      setDistance(value);
    } else if (name === "unitType") {
      setUnitType(value);
    } else if (name === "priceRange") {
      setPrice(value);
    }
  };

  // ! search button state
  const isSearchDisabled = () => {
    if (selectedPlace && distance) return false;
    if (unitType || price || unitId) return false;
    return true;
  };

  const handleLocationOnChange = () => {
    setSelectedPlace(null);
    errorMessageShown.current = false;
  };

  const handleAddressBlur = () => {
    addressInterval = setTimeout(() => {
      checkAfterBlur();
    }, 500);
  };

  const checkAfterBlur = () => {
    if (!selectedPlace) {
      if (!errorMessageShown.current) {
        errorMessageShown.current = true;

        setClearAddressInput((prev) => prev + 1);
      }
    }
  };

  const handleUnitIdKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && unitId) {
      handleSearch();
    }
  };

  return (
    <div className="max-w-full w-full flex flex-col lg:max-w-[500px] bg-[#00000033] p-4 lg:p-8">
      <div className="lg:flex-row lg:gap-6 flex flex-col items-center gap-4">
        <CustomButton
          label={dict.searchByLocation}
          setActiveBtn={() => setActiveBtn("location")}
          active={activeBtn === "location"}
        />
        <CustomButton
          label={dict.searchByUnit}
          setActiveBtn={() => setActiveBtn("unit")}
          active={activeBtn === "unit"}
        />
      </div>

      {activeBtn === "location" ? (
        <div className="lg:gap-6 flex flex-col gap-4 mt-10 mb-10">
          <APIProvider apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API!}>
            <CustomInputLocation
              clearAddressInput={clearAddressInput}
              handleAddressBlur={handleAddressBlur}
              placeholder={dict.locationInputPlaceholder}
              setSelectedPlace={setSelectedPlace}
              handleLocationOnChange={handleLocationOnChange}
              useMyLocationTooltip={dict.useMyLocation}
            />

            <ControlledCustomSelect
              value={distance}
              title="distance dropdown"
              name="distance"
              dict={dict}
              data={DISTANCE}
              onChange={handleFilterChange}
              placeholder="distanceMiles"
              darkBg
            />

            <div className="lg:flex-row gap-x-6 gap-y-4 flex flex-col">
              <ControlledCustomSelect
                title="unit dropdown"
                value={unitType}
                name="unitType"
                dict={dict}
                data={UNITS}
                onChange={handleFilterChange}
                placeholder="unitType"
                darkBg
              />

              <ControlledCustomSelect
                title="price dropdown"
                value={price}
                name="priceRange"
                dict={dict}
                data={PRICE}
                onChange={handleFilterChange}
                placeholder="priceRange"
                darkBg
              />
            </div>
          </APIProvider>
        </div>
      ) : (
        <div className="mt-12 mb-10">
          <CustomInputUnit
            value={unitId}
            onChange={setUnitId}
            placeholder={dict.unitInputPlaceholder}
            handleUnitIdKeyDown={handleUnitIdKeyDown}
          />
        </div>
      )}

      <div className="flex flex-wrap items-center gap-6 mt-auto">
        <AppIconButton
          bg={"dark"}
          iconOnRightSide
          icon={<Search size={16} />}
          intent={"primary"}
          label={dict.search}
          title={dict.searchTooltip}
          disabled={isSearchDisabled()}
          className="gap-2"
          onClick={handleSearch}
        />

        <AppIconButton
          bg={"dark"}
          iconOnRightSide
          icon={<ChevronRight size={20} aria-label="chevron right icon" />}
          intent={"ghost"}
          label={dict.viewAllUnits}
          title={dict.viewAllUnitsTooltip}
          className="after:left-0 gap-0 px-0"
          onClick={handleAllUnits}
        />
      </div>
    </div>
  );
};

export default SalesForm;
