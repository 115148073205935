export const formatDateCustom = (date: string) => {
  if (!date) return "";

  const d = new Date(date);

  const options = {
    year: "numeric" as const,
    month: "short" as const,
    day: "numeric" as const,
  };

  return d.toLocaleDateString("en", options);
};
