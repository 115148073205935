"use client";

import React, { useState } from "react";
import AppText from "./AppText";
import ContentLayout from "../layouts/ContentLayout";
import { TabButton } from "./TabButton";
import { AppButton } from "../buttons/AppButton";
import Image from "next/image";

interface ICase {
  id: string;
  title: string;
  slug: string;
  excerpt: string;
  image: string;
  placeholder: string;
  imgAlt: string;
}

interface ISolutionUseCases {
  id: string;
  label: string;
  heading: string;
  subheading: string;
  useCases: ICase[];
  findOutMore: string;
  paddingBottom?: string;
}

const SolutionUseCases: React.FC<ISolutionUseCases> = ({
  id,
  label,
  heading,
  subheading,
  useCases,
  findOutMore,
  paddingBottom,
}) => {
  const [activeTab, setActiveTab] = useState(useCases[0]);

  const handleActiveTab = (tabId: string) => {
    const newTab = useCases.find((item) => item.id === tabId);

    if (newTab) setActiveTab(newTab);
  };

  return (
    <ContentLayout bgColorClass="bg-white" id={id} paddingBottom={paddingBottom}>
      <div className="lg:flex-row flex flex-col gap-8">
        <div className="flex-1">
          {label && (
            <AppText type="LABEL_LARGE" className="text-royal-blue mb-2">
              {label}
            </AppText>
          )}
          <AppText type="HEADLINE_MEDIUM">{heading}</AppText>
        </div>

        {subheading && (
          <AppText type="SUB_HEADING_MEDIUM" className="flex-1">
            {subheading}
          </AppText>
        )}
      </div>

      <div className="h-[1px] bg-[#ccc] w-full my-4"></div>

      <div className="flex flex-wrap gap-2 mb-8">
        {(useCases || []).map((item: ICase) => (
          <TabButton
            key={item.id}
            title={item.title}
            id={item.id}
            handleActiveTab={handleActiveTab}
            active={activeTab?.id === item.id}
          />
        ))}
      </div>

      <div className="lg:flex-row flex flex-col justify-between gap-10 my-6">
        <AppText type="BODY_MEDIUM" className="flex-1 max-w-[1034px]">
          {activeTab?.excerpt}
        </AppText>

        <AppButton intent="secondary" label={findOutMore} link={`/${activeTab?.slug}`} />
      </div>

      {activeTab?.image && (
        <div className="relative w-full aspect-[16/9]">
          <Image
            src={activeTab.image}
            alt={activeTab?.imgAlt || "image"}
            className="object-cover w-full aspect-[16/9]"
            sizes="(max-width: 1536px) 100vw, 1400px"
            fill
            placeholder="blur"
            blurDataURL={activeTab.placeholder}
          />
        </div>
      )}
    </ContentLayout>
  );
};

export default SolutionUseCases;
