/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { useEffect, useState } from "react";
import ContentLayout from "../layouts/ContentLayout";
import AppText from "./AppText";
import { cn } from "@/lib/utils";
import RichTextRenderer from "./RichTextRenderer";
import { TabButton } from "./TabButton";
import Image from "next/image";
import { CustomizationsDots } from "./CustomizationsDots";
import { useMediaQuery } from "@/hooks/mediaQuery";

interface IPackageLevels {
  label: string;
  id: string;
  heading: string;
  content: any;
  tabs: any;
  paddingBottom?: string;
}

interface ITab {
  id: string;
  tabLabel: string;
  content: any;
  image: string;
  placeholder: string;
  imgAlt: string;
  dots: string;
}

export interface IDot {
  id: string;
  heading: string;
  subheading: string;
  footer: string;
  position: { top: number; left: number };
}

const PackageLevels = ({ label, id, heading, content, tabs, paddingBottom }: IPackageLevels) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [visibleTooltip, setVisibleTooltip] = useState<{ idx: number; isLeft: boolean; top: number } | null>(null);

  const isMobile = useMediaQuery("(max-width: 640px)");

  const handleActiveTab = (tabId: string) => {
    const newTab = tabs.find((item: any) => item.id === tabId);

    if (newTab) setActiveTab(newTab);
  };

  const handleTooltip = () => setVisibleTooltip(null);

  const handleDotsParentClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    if (target && target.id === "dotsParent") setVisibleTooltip(null);
  };

  const handleKeyDown = (ev: KeyboardEvent) => {
    if (ev.key === "Escape") {
      setVisibleTooltip(null);
    }
  };

  const mainScroll = () => {
    setVisibleTooltip(null);
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("scroll", mainScroll);

    return () => {
      removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("scroll", mainScroll);
    };
  }, []);

  return (
    <ContentLayout id={id} paddingBottom={paddingBottom}>
      <div className="max-w-[736px] pt-8 pb-[31px] items-center mx-auto">
        {label && (
          <AppText type={"LABEL_LARGE"} className={cn("text-center text-royal-blue mb-2")}>
            {label}
          </AppText>
        )}

        {heading && (
          <AppText type={"HEADLINE_MEDIUM"} as="h2" className={cn("text-center mb-4")}>
            {heading}
          </AppText>
        )}

        {content && (
          <RichTextRenderer richTextDocument={content} id={`rt-${id}`} locations className={cn("text-center")} />
        )}
      </div>

      <div className="flex flex-wrap justify-center gap-2 mb-10">
        {(tabs || []).map((item: ITab) => (
          <TabButton
            key={item.id}
            title={item.tabLabel}
            id={item.id}
            handleActiveTab={handleActiveTab}
            active={activeTab.id === item.id}
          />
        ))}
      </div>

      <div className="relative">
        <div className="no-scrollbar relative overflow-visible overflow-x-auto overflow-y-auto">
          {/** background image  */}
          <div className="w-[100%] relative ">
            <Image
              src={activeTab.image}
              alt={activeTab.imgAlt}
              className="industryCategoryHeader object-cover w-full"
              sizes="(max-width: 1536px) 100vw, 1536px"
              width={0}
              height={0}
              placeholder="blur"
              blurDataURL={activeTab.placeholder}
            />
          </div>

          {visibleTooltip !== null && (
            <div onClick={handleTooltip} className="fixed top-0 bottom-0 left-0 right-0 z-20 bg-transparent" />
          )}

          {/** dots */}
          <div
            onClick={handleDotsParentClick}
            id="dotsParent_packageLevels"
            className="absolute inset-0 w-[100%] z-30 overflow-visible"
          >
            {activeTab.dots.map((dot: IDot, idx: number) => {
              return (
                <CustomizationsDots
                  key={dot.id}
                  visibleTooltip={visibleTooltip}
                  setVisibleTooltip={setVisibleTooltip}
                  idx={idx}
                  dot={dot}
                  isMobile={isMobile}
                  closeTooltip={handleTooltip}
                />
              );
            })}
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};

export default PackageLevels;
