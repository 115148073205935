/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";
import React from "react";
import { Swiper } from "swiper/react";
import { useSwiper } from "@/hooks/useSwiper";
import { SwiperOptions } from "swiper/types";
import ArrowPagination from "@/components/other/ArrowPagination";
import { AppIconButton } from "@/components/buttons/AppIconButton";
import "swiper/css";
import "swiper/css/pagination";
import { cn } from "@/lib/utils";
import { ArrowLeft, ArrowRight, ChevronLeft, ChevronRight } from "lucide-react";

interface ProductDetailsHeaderSectionCustomCarouselProps {
  children: React.ReactNode;
  className?: string;
  wrapperClassName?: string;
  paginationType: "pageSelect" | "arrowSlider" | "arrowsNew" | "sideArrows" | "none";
  bg?: "dark" | "light" | undefined;
  cardsOverflow?: boolean;
  withoutWrapper?: boolean;
  videoPauseOnChange?: boolean;
  swiperData?: {
    swiperRef: any;
    onSwiperChange: any;
    currentPage: any;
    onNextPageClick: any;
    onPrevPageClick: any;
    pageCount: any;
  };
}

function ProductDetailsHeaderSectionCustomCarousel(
  props: ProductDetailsHeaderSectionCustomCarouselProps &
    Omit<SwiperOptions, "onSwiper" | "onInit" | "onSlideChange" | "onBreakpoint">
) {
  const {
    children,
    wrapperClassName,
    className,
    paginationType,
    bg,
    cardsOverflow,
    withoutWrapper,
    swiperData,
    videoPauseOnChange,
    ...restProps
  } = props;

  const internalSwiper = useSwiper();
  const { swiperRef, onSwiperChange, currentPage, onNextPageClick, onPrevPageClick, pageCount } =
    swiperData ?? internalSwiper;

  function renderPagination() {
    if (paginationType === "none" || pageCount <= 1) {
      return null;
    }

    if (paginationType === "sideArrows") {
      return (
        <div className="absolute left-0 right-0">
          <div className=" flex justify-between w-full p-1">
            <AppIconButton
              onClick={onPrevPageClick}
              icon={<ChevronLeft size={20} aria-label="chevron left icon" />}
              intent={"secondary"}
              size={"md"}
              title={"previous"}
              className={
                "bg-white hover:!bg-black-10 active:!bg-black-20 shadow-lg border-none outline-none w-[32px] h-[32px] p-1.5 z-10"
              }
            />
            <AppIconButton
              onClick={onNextPageClick}
              icon={<ChevronRight size={20} aria-label="chevron right icon" />}
              intent={"secondary"}
              size={"md"}
              title={"next"}
              className={
                "bg-white hover:!bg-black-10 active:!bg-black-20 shadow-lg border-none outline-none w-[32px] h-[32px] p-1.5 z-10"
              }
            />
          </div>
        </div>
      );
    }

    if (paginationType === "arrowSlider") {
      return (
        <div className="absolute top-0 left-0 flex flex-col justify-center w-full h-full">
          <div className="w-full flex justify-between mx-auto xl:max-w-screen-2xl lg:max-w-screen-xl sm:max-w-screen-md md:max-w-screen-lg sm:py-[80px] py-[48px] px-6">
            <AppIconButton
              onClick={onPrevPageClick}
              icon={<ChevronLeft size={20} aria-label="chevron left icon" />}
              intent={"secondary"}
              size={"md"}
              title={"previous"}
              className={
                "bg-white hover:!bg-black-10 active:!bg-black-20 shadow-lg border-none outline-none focus:ring-0 focus:ring-offset-0 w-[32px] h-[32px] p-1.5 z-10"
              }
            />
            <AppIconButton
              onClick={onNextPageClick}
              icon={<ChevronRight size={20} aria-label="chevron right icon" />}
              intent={"secondary"}
              size={"md"}
              title={"next"}
              className={
                "bg-white hover:!bg-black-10 active:!bg-black-20 shadow-lg border-none outline-none focus:ring-0 focus:ring-offset-0 w-[32px] h-[32px] p-1.5 z-10"
              }
            />
          </div>
        </div>
      );
    }

    if (paginationType === "arrowsNew") {
      return (
        <>
          <div className="md:justify-center absolute top-0 left-0 flex flex-col justify-end h-full sm:py-[80px] py-[48px] px-4 lg:px-8 xl:px-[80px] 2xl:px-[104px] z-10">
            <AppIconButton
              onClick={onPrevPageClick}
              bg={bg}
              icon={<ArrowLeft size={20} aria-label="arrow left icon" />}
              intent={"secondary"}
              title={"previous"}
              className="px-2"
            />
          </div>
          <div className="md:justify-center absolute top-0 right-0 flex flex-col justify-end h-full sm:py-[80px] py-[48px] px-4 lg:px-8 xl:px-[80px] 2xl:px-[104px] z-10">
            <AppIconButton
              onClick={onNextPageClick}
              bg={bg}
              icon={<ArrowRight size={20} aria-label="arrow right icon" />}
              intent={"secondary"}
              title={"next"}
              className="px-2"
            />
          </div>
        </>
      );
    }

    if (paginationType === "pageSelect") {
      return (
        <ArrowPagination
          className={"justify-end mt-[40px] pr-4"}
          currentPage={currentPage}
          pageCount={pageCount}
          onPrevPageClick={onPrevPageClick}
          onNextPageClick={onNextPageClick}
          bg={bg}
        />
      );
    }
  }

  if (withoutWrapper) {
    return (
      <Swiper
        className={className}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        onInit={onSwiperChange}
        onSlideChange={onSwiperChange}
        onBreakpoint={onSwiperChange}
        {...restProps}
      >
        {children}
      </Swiper>
    );
  }

  const handleSlideChange = async (swiper: any) => {
    // on carousel change, find all video elements in the carousel and pause them
    const videos = document.querySelectorAll("video");
    videos.forEach((video) => video.pause());

    // pause YouTube embeds
    const youtubeIframes = document.querySelectorAll("iframe");
    youtubeIframes.forEach((iframe) => {
      iframe.contentWindow?.postMessage('{"event":"command","func":"pauseVideo","args":""}', "*");
    });

    // update swiper
    onSwiperChange(swiper);
  };

  return (
    <>
      <div className={cn("relative", wrapperClassName, cardsOverflow && "lg:w-overflow-right")}>
        <Swiper
          className={className}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          onInit={onSwiperChange}
          onSlideChange={videoPauseOnChange ? handleSlideChange : onSwiperChange}
          onBreakpoint={onSwiperChange}
          {...restProps}
        >
          {children}
        </Swiper>
      </div>

      {renderPagination()}
    </>
  );
}

export default React.memo(ProductDetailsHeaderSectionCustomCarousel);
