/* eslint-disable @next/next/no-img-element */
/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import { ContentLayoutUnbounce } from "../layouts/ContentLayout";
import { colorsMap, fallbackColors } from "./constants";
import { cn } from "@/lib/utils";
import RichTextRenderer from "./RichTextRenderer";
import MiniForm from "../miniForm/MiniForm";
import AppText from "./AppText";
import TextBanner from "./TextBanner";
import ColumnForm from "../form/ColumnForm";
// import ReCaptchaProvider from "../recaptcha/ReCaptchaProvider";
import { useMediaQuery } from "@/hooks/mediaQuery";

export interface IRowBlock {
  id: string;
  paddingBottom?: number;
  paddingTop?: number;
  background: string;
  columns: any;
  flexStyle: string;
  gap: number;
  itemType: string;
}

// ! --------------------------------------------
const RowBlock = ({ id, paddingBottom, paddingTop, background, columns, gap }: IRowBlock) => {
  const { bgColorClass } = colorsMap[background] || fallbackColors;
  return (
    <ContentLayoutUnbounce
      id={id}
      paddingBottom={paddingBottom}
      paddingTop={paddingTop}
      bgColorClass={bgColorClass}
      innerClass="sm:py-0 py-0"
    >
      <div className={`flex flex-col lg:flex-row ${gap} justify-between h-full `}>
        {(columns || []).map((column: any) => renderColumn(column))}
      </div>
    </ContentLayoutUnbounce>
  );
};

export default RowBlock;
// ! --------------------------------------------

export const renderColumn = (column: any) => {
  const { bgColorClass, textColor } = colorsMap[column.background] || fallbackColors;

  const oneImagePerColumn = column.blocks.length === 1 && column.blocks[0].itemType === "image";
  const oneWysiwygPerColumn = column.blocks.length === 1 && column.blocks[0].itemType === "wysiwyg";
  const infoCardColumn =
    column.blocks.length === 2 && column.blocks[0].itemType === "wysiwyg" && column.blocks[1].itemType === "row";
  // const isColumnForm = column.blocks.length === 1 && column.blocks[0].itemType === "columnForm";

  if (oneImagePerColumn) {
    const img = column.blocks[0].image?.[0]?.thumbnails?.transformBaseUrl;
    const aspectRatio = column.blocks[0].aspect || "";

    return (
      <div className={cn(column.flexStyle ? `flex ${column.flexStyle}` : "")}>
        <div
          style={{ maxWidth: `${column.width}%` }}
          className={cn(
            "relative w-full flex-1 overflow-hidden flex-col gap-6 lg:max-h-full lg:flex hidden",
            aspectRatio,
            bgColorClass
          )}
        >
          <img
            src={img ? `${img}?io=transform:fit,width:680` : "/placeholders/no-preview.png"}
            alt={column.blocks[0].image?.[0]?.description || "image"}
            className="object-cover w-full h-full"
          />
        </div>
        <div
          style={{ maxWidth: "100%" }}
          className={cn(
            "relative w-full flex-1 overflow-hidden flex-col gap-6 lg:max-h-full flex lg:hidden",
            aspectRatio,
            bgColorClass
          )}
        >
          <img
            style={{ maxWidth: "100%" }}
            src={img ? `${img}?io=transform:fit,width:1024` : "/placeholders/no-preview.png"}
            alt={column.blocks[0].image?.[0]?.description || "image"}
            className="object-cover w-full h-full"
          />
        </div>
      </div>
    );
  }

  if (infoCardColumn) {
    const { bgColorClass, textColor } = colorsMap[column.background] || fallbackColors;

    const img = column.blocks?.[1]?.columns?.[0]?.blocks?.[0]?.image?.[0]?.thumbnails?.transformBaseUrl;
    const alt = column.blocks?.[1]?.columns?.[0]?.blocks?.[0]?.image?.[0]?.description;
    const content = column.blocks?.[1]?.columns?.[1]?.blocks?.[0]?.content;
    const imgWidth = column.blocks?.[1]?.columns?.[0]?.width;
    const contentWidth = column.blocks?.[1]?.columns?.[1]?.width;

    return (
      <InfoCardColumnWrapper
        id={column.blocks[0].id + "cw"}
        colWidth={column.width}
        bgColorClass={bgColorClass}
        columnFlexStyle={column.flexStyle}
      >
        <RichTextRenderer
          key={column.blocks[0].id + "infoCard"}
          richTextDocument={column.blocks[0].content}
          id={column.blocks[0].id}
          className="mt-0"
          locations
          textColor={textColor}
        />

        <div className="flex gap-6">
          <div className="relative" style={{ width: imgWidth + "%" }}>
            <img
              src={img ? `${img}?io=transform:fit,width:400` : "/placeholders/no-preview.png"}
              alt={alt || "image"}
              className={cn("object-cover w-full aspect-square")}
            />
          </div>

          {content && (
            <div className={cn("flex-1")} style={{ width: contentWidth + "%" }}>
              <RichTextRenderer
                key={column.blocks?.[1]?.columns?.[1]?.blocks?.[0]?.id + "infoCardContent"}
                richTextDocument={content}
                id={column.blocks?.[1]?.columns?.[1]?.blocks?.[0]?.id}
                className="mt-0"
                locations
                textColor={textColor}
              />
            </div>
          )}
        </div>
      </InfoCardColumnWrapper>
    );
  }

  if (oneWysiwygPerColumn) {
    const { bgColorClass, textColor, labelColor } = colorsMap[column.background] || fallbackColors;

    return (
      <>
        <div
          style={{ maxWidth: "100%" }}
          key={column.blocks[0].id + "wysiwygPerColumnLg"}
          className={cn("px-6 flex flex-col gap-2 flex-1 overflow-hidden lg:hidden", bgColorClass, column.flexStyle)}
        >
          <RichTextRenderer
            key={column.blocks[0].id + "wysiwygPerColumnLgRT"}
            richTextDocument={column.blocks[0].content}
            id={column.blocks[0].id}
            className="mt-0"
            locations
            textColor={textColor}
            labelColor={labelColor}
            columnBlock
          />
        </div>

        <div
          style={{ maxWidth: `${column.width}%` }}
          key={column.blocks[0].id + "wysiwygPerColumn"}
          className={cn("px-6 lg:flex hidden flex-col gap-2 flex-1 overflow-hidden", bgColorClass, column.flexStyle)}
        >
          <RichTextRenderer
            key={column.blocks[0].id + "wysiwygPerColumnRT"}
            richTextDocument={column.blocks[0].content}
            id={column.blocks[0].id}
            className="mt-0"
            locations
            textColor={textColor}
            labelColor={labelColor}
            columnBlock
          />
        </div>
      </>
    );
  }

  const columnClass = cn(
    "flex flex-col flex-1 overflow-hidden",
    bgColorClass,
    // !isColumnForm && "pt-4",
    column.flexStyle
  );

  return (
    <ColumnWrapper colWidth={column.width} columnClass={columnClass}>
      {(column.blocks || [])
        .map((block: any) => {
          if (block.itemType === "columnTextImages") {
            return (
              <div key={block.id + "columnTextImages"} className={cn("flex flex-col w-full gap-6")}>
                <ColumnTextImages {...block} />
              </div>
            );
          } else if (block.itemType === "miniForm") {
            return (
              <div
                key={block.id + "miniForm"}
                className={cn("flex flex-col w-full gap-6 shrink-0 items-center min-w-[340px] overflow-hidden")}
              >
                <MiniForm key={block.id} {...block} />
              </div>
            );
          } else if (block.itemType === "wysiwyg") {
            return (
              <div key={block.id + "wysiwyg"}>
                <RichTextRenderer
                  key={block.id + "wysiwygRT"}
                  richTextDocument={block.content}
                  id={block.id}
                  className="mt-0"
                  locations
                  textColor={textColor}
                />
              </div>
            );
          } else if (block.itemType === "image") {
            const aspectRatio = block.aspect || "";

            if (block.image?.[0]?.type === "video") {
              return (
                <div key={block.id + "video"} className={cn("flex items-center justify-center w-full", aspectRatio)}>
                  <video
                    className={cn("object-contain h-full")}
                    controls={true}
                    preload="auto"
                    playsInline={true}
                    poster={block.image?.[0]?.src}
                  >
                    <source src={block.image?.[0]?.videoPreviewURLs?.[0]} type="video/mp4" />
                  </video>
                </div>
              );
            }

            const img = block.image?.[0]?.thumbnails?.transformBaseUrl;

            return (
              <div
                key={block.id + "image"}
                className={cn("relative w-full aspect-square", aspectRatio)}
                // className={cn("relative w-full aspect-square max-h-[400px]", aspectRatio)}
              >
                <img
                  src={img ? `${img}?io=transform:fit,width:1024` : "/placeholders/no-preview.png"}
                  alt={block.image?.[0]?.description || "image"}
                  className="object-cover w-full"
                />
              </div>
            );
          } else if (block.itemType === "column") {
            return renderColumn(column);
          } else if (block.itemType === "columnForm") {
            const tmpBlock = { ...block };
            let zipName = "";

            tmpBlock.originalFields?.forEach((group: any) => {
              if (
                group.itemType === "fieldDropdown" &&
                group.fieldOptionGroups &&
                group.fieldOptionGroups[0]?.fieldOptions
              ) {
                group.options = group.fieldOptionGroups[0]?.fieldOptions;
                delete group.fieldOptionGroups;
              }

              if (group.itemType === "fieldText") {
                const tmpName = group.name.toLowerCase();

                if (tmpName.includes("zip")) {
                  zipName = group.name;
                }
              }
            });

            if (tmpBlock.background) {
              const tmpBlocks = tmpBlock.background.split("-");

              if (tmpBlocks.length > 1) {
                const lastItem = tmpBlocks.pop();
                const isNum = !isNaN(parseInt(lastItem));

                if (lastItem === "softWhite") {
                  tmpBlock.shade = "softWhite";
                  tmpBlock.colorObj = "black";
                } else if (isNum) {
                  tmpBlock.shade = lastItem;
                  tmpBlock.colorObj = tmpBlocks.join("-");
                } else {
                  tmpBlock.colorObj = tmpBlock.background;
                  tmpBlock.shade = "DEFAULT";
                }
              } else {
                tmpBlock.colorObj = tmpBlock.background;
                tmpBlock.shade = "DEFAULT";
              }
            }

            return <ColumnForm key={block.id + "columnForm"} {...tmpBlock} zipName={zipName} />;
            // return (
            //   <ReCaptchaProvider key={block.id + "recaptcha"}>
            //     <ColumnForm {...tmpBlock} zipName={zipName} />
            //   </ReCaptchaProvider>
            // );
          } else if (block.itemType === "textBanner") {
            return <TextBanner key={block.id} {...block} columnTextBanner />;
          } else if (block.itemType === "row") {
            return (
              <div
                key={block.id + "-row"}
                style={block.maxHeight ? { maxHeight: block.maxHeight + "px" } : {}}
                className={cn(`flex flex-col lg:flex-row ${block.gap} justify-between`, block.maxHeight)}
              >
                {(block.columns || []).map((column: any) => renderColumn(column))}
              </div>
            );
          }
        })
        .filter(Boolean)}
    </ColumnWrapper>
  );
};

interface IColumnTextImages {
  heading: string;
  cards: any;
}

const ColumnTextImages = ({ heading, cards }: IColumnTextImages) => {
  return (
    <div className="flex flex-col gap-10">
      <AppText type="HEADLINE_MEDIUM" className="text-balance">
        {heading}
      </AppText>
      <div className="flex flex-col gap-10">
        {(cards || []).map((card: any) => (
          <ColumnTextImageCard key={card.id} {...card} />
        ))}
      </div>
    </div>
  );
};

const ColumnTextImageCard = ({
  id,
  content,
  heading,
  image,
  mediaAlign = "Left",
  aspectRatio = "",
}: {
  id: string;
  content: any;
  heading?: string;
  image: any;
  mediaAlign?: "Left" | "Right";
  aspectRatio?: string;
}) => {
  const img = image?.[0]?.thumbnails?.transformBaseUrl;

  return (
    <div>
      {heading && (
        <AppText type="SUB_HEADING_LARGE" className="text-balance mb-6">
          {heading}
        </AppText>
      )}
      <div className="sm:grid-cols-12 grid grid-cols-4 gap-8">
        {mediaAlign !== "Left" && (
          <div className="sm:col-span-8 col-span-4">
            <RichTextRenderer richTextDocument={content} id={id} locations className="text-balance flex-1" />
          </div>
        )}
        <div
          className={cn(
            "sm:col-span-4 shrink-0 relative w-full col-span-4 aspect-[16/9] sm:aspect-square",
            mediaAlign === "Left" ? "order-first" : "sm:order-last order-first",
            aspectRatio
          )}
        >
          <img
            src={img ? `${img}?io=transform:fit,width:640` : "/placeholders/no-preview.png"}
            alt={image?.[0]?.description || "image"}
            className="aspect-square object-cover w-full"
          />
        </div>

        {mediaAlign === "Left" && (
          <div className="sm:col-span-8 col-span-4">
            <RichTextRenderer richTextDocument={content} id={id} locations className="text-balance flex-1" />
          </div>
        )}
      </div>
    </div>
  );
};

const InfoCardColumnWrapper = ({
  id,
  colWidth,
  bgColorClass,
  children,
  columnFlexStyle,
}: {
  id: string;
  colWidth: string;
  bgColorClass: string;
  children: React.ReactNode;
  columnFlexStyle?: string;
}) => {
  return (
    <div className={columnFlexStyle ? `flex ${columnFlexStyle}` : ""}>
      <div
        key={id}
        style={{ maxWidth: "100%" }}
        className={cn("px-6 py-8 relative w-full flex-1 overflow-hidden flex flex-col gap-6 lg:hidden", bgColorClass)}
      >
        {children}
      </div>
      <div
        key={id}
        style={{ maxWidth: `${colWidth}%` }}
        className={cn("px-6 py-8 relative w-full flex-1 overflow-hidden   flex-col gap-6 lg:flex hidden", bgColorClass)}
      >
        {children}
      </div>
    </div>
  );
};

const ColumnWrapper = ({
  children,
  colWidth,
  columnClass,
}: {
  children: React.ReactNode;
  colWidth: string;
  columnClass: string;
}) => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  return (
    <>
      <div style={{ maxWidth: isDesktop ? `${colWidth}%` : "100%" }} className={cn("flex", columnClass)}>
        {children}
      </div>
    </>
  );
};
