"use client";

import ContentLayout from "@/components/layouts/ContentLayout";
import AppText from "@/components/other/AppText";
import Image from "next/image";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

import { AppIconButton } from "../buttons/AppIconButton";
import { cn } from "@/lib/utils";
import { colorsMap, fallbackColors } from "../other/constants";
import { ChevronRight } from "lucide-react";

export interface ISpotlightCaseStudy {
  id: string;
  heading: string;
  background: string;
  image: string;
  imgAlt: string;
  icoAlt?: string;
  label: string;
  icon?: string;
  subheading: string;
  ctaLabel: string;
  ctaLink: string;
  headingMain: string;
  paddingBottom?: string;
  youtubeVideoId?: string;
  video?: string;
}

const SpotlightCaseStudy: React.FC<ISpotlightCaseStudy> = ({
  id,
  heading,
  background,
  image,
  imgAlt,
  icon,
  icoAlt,
  label,
  subheading,
  ctaLabel,
  ctaLink,
  headingMain,
  paddingBottom,
  youtubeVideoId,
  video,
}) => {
  const { labelColor, textColor, bgColorClass, bg, border } = colorsMap[background] ?? fallbackColors;

  return (
    <ContentLayout bgColorClass={bgColorClass} id={id} paddingBottom={paddingBottom}>
      <AppText className="mb-[64px]" as="h2" type="HEADLINE_SMALL">
        {headingMain}
      </AppText>
      <div className="lg:grid-cols-12 md:gap-8 lg:gap-6 xl:gap-10 lg:items-center grid grid-cols-4 gap-10">
        <div className="lg:col-span-7 relative w-full col-span-4 overflow-hidden aspect-[16/9]">
          {youtubeVideoId ? (
            <LiteYouTubeEmbed id={youtubeVideoId} title={heading} iframeClass="w-full" />
          ) : video ? (
            <video controls={true} preload="auto" playsInline={true} autoPlay={false}>
              <source src={video} type="video/mp4" />
            </video>
          ) : (
            <Image src={image} alt={imgAlt} fill sizes="(max-width: 1024px) 100vw, 800px" className="object-cover" />
          )}

          {icon && !youtubeVideoId && !video && (
            <div className="bottom-4 left-4 absolute lg:w-[159px] lg:h-[82px] w-[130px] h-[67px] bg-white flex justify-center items-center">
              <Image
                alt={icoAlt || "brand logo"}
                className="object-cover w-full"
                sizes="100vw"
                width={0}
                height={0}
                src={icon}
              />
            </div>
          )}
        </div>

        <div className={cn("lg:col-span-5 col-span-4 pl-8 border-l-2", border)}>
          {label && (
            <AppText className={labelColor} type="LABEL_LARGE">
              {label}
            </AppText>
          )}
          <AppText className={cn("mt-2", textColor)} type="HEADLINE_SMALL">
            {heading}
          </AppText>

          <AppText className={cn("mt-4", textColor)} type="BODY_SMALL">
            {subheading}
          </AppText>

          <div className="sm:gap-6 sm:flex-row flex flex-col gap-4 mt-8">
            <AppIconButton
              icon={<ChevronRight size={20} aria-label="chevron right icon" />}
              iconOnRightSide
              label={ctaLabel}
              intent="ghost"
              link={ctaLink}
              bg={bg}
            />
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};

export default SpotlightCaseStudy;
