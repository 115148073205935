"use client";

/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import AppText from "@/components/other/AppText";
import { cn } from "@/lib/utils";
import { AppButton } from "@/components/buttons/AppButton";
import RichTextRenderer from "./RichTextRenderer";
import { colorsMap, fallbackColors } from "./constants";
import { Download } from "lucide-react";
import { AppIconButton } from "../buttons/AppIconButton";
import { useMediaQuery } from "@/hooks/mediaQuery";

interface TextBannerProps {
  id?: string;
  label?: string;
  heading?: string;
  subheading?: string;
  content?: any;
  primaryCtaLabel?: string;
  primaryCtaLink?: string;
  secondaryCtaLink?: string;
  secondaryCtaLabel?: string;
  primaryCtaDownloadIcon?: boolean;
  secondaryCtaDownloadIcon?: boolean;
  background: "royal-blue" | "navy-blue" | "white";
  grid: boolean;
  homepageGetAQuote: boolean;
  isItTopHeading: boolean;
  paddingBottom?: number;
  paddingTop?: number;
  columnTextBanner?: boolean;
}

const renderHeading = (heading: string, textColor: string, isItTopHeading: boolean, columnTextBanner?: boolean) => {
  if (!heading || typeof heading !== "string") return "";

  const test = heading.split("\\n");

  if (test.length === 1)
    return (
      <AppText
        as={isItTopHeading ? "h1" : "h2"}
        type={"HEADLINE_MEDIUM"}
        className={cn(
          "mt-2 mb-3 tracking-tighter flex flex-col",
          columnTextBanner ? "text-left lg:text-36 mb-8" : "text-center",
          textColor
        )}
      >
        {heading}
      </AppText>
    );

  const block = test.map((item: string, index: number) => <span key={index}>{item}</span>);

  return (
    <AppText
      as={isItTopHeading ? "h1" : "h2"}
      type={"HEADLINE_MEDIUM"}
      className={cn("text-center mt-2 tracking-tighter mb-4 flex flex-col", textColor)}
    >
      {block}
    </AppText>
  );
};

const TextBanner: React.FC<TextBannerProps> = ({
  id,
  label,
  heading,
  subheading,
  content,
  primaryCtaLabel,
  primaryCtaLink,
  secondaryCtaLink,
  secondaryCtaLabel,
  background,
  primaryCtaDownloadIcon,
  secondaryCtaDownloadIcon,
  grid,
  homepageGetAQuote,
  isItTopHeading = false,
  paddingBottom,
  paddingTop,
  columnTextBanner,
}) => {
  const isMobile = useMediaQuery("(max-width: 640px)");

  const { labelColor, textColor, bgColorClass, bg } = colorsMap[background] ?? fallbackColors;

  // Custom logic part, as per WEB-922 we need to set the links to phone or chat if homepageGetAQuote is true
  const telLink = "866-893-4660";

  const secondaryCtaLinkCustomLogic = homepageGetAQuote ? undefined : secondaryCtaLink;
  const onClickHandler = homepageGetAQuote
    ? (e: any) => {
        e.preventDefault();
        if (isMobile) {
          console.log(`Opening: tel:${telLink}`);
          window.location.href = `tel:${telLink}`;
          return;
        } else {
          console.log(`Opening: Chat`);
          const button = document.querySelector("#embeddedMessagingConversationButton") as HTMLButtonElement;
          button?.click();
        }
      }
    : undefined;

  return (
    <div
      id={id}
      style={{
        paddingTop: paddingTop ? `${paddingTop / 4}rem` : "",
        paddingBottom: paddingBottom ? `${paddingBottom / 4}rem` : "",
      }}
      className={cn("w-full flex flex-col justify-center", grid && "grid-background", bgColorClass)}
    >
      <div
        className={cn(
          columnTextBanner
            ? "w-full flex justify-center px-4"
            : `w-full mx-auto xl:max-w-screen-2xl lg:max-w-screen-xl sm:max-w-screen-md md:max-w-screen-lg
          px-4 lg:px-8 xl:px-[80px] 2xl:px-[104px] flex flex-col items-center`
        )}
      >
        <div className={cn(columnTextBanner ? "pt-6" : "max-w-[736px] pt-8 pb-[31px]")}>
          {label && !columnTextBanner && (
            <AppText type={"LABEL_LARGE"} className={cn(columnTextBanner ? "text-left" : "text-center", labelColor)}>
              {label}
            </AppText>
          )}

          {heading && renderHeading(heading, textColor, isItTopHeading, columnTextBanner)}

          {subheading && (
            <AppText
              type={"SUB_HEADING_SMALL"}
              as="p"
              className={cn(
                "mt-2 tracking-tighter mb-4",
                columnTextBanner ? "text-left font-[500]" : "text-center font-[400]",
                textColor
              )}
            >
              {subheading}
            </AppText>
          )}

          {content && (
            <RichTextRenderer
              richTextDocument={content}
              id={`rt-${id}`}
              locations
              className={cn("text-center", textColor)}
            />
          )}

          {(primaryCtaLabel || secondaryCtaLabel) && (
            <div className={"flex justify-center max-sm:flex-col items-center mt-8 gap-4 lg:gap-6"}>
              {primaryCtaLabel ? (
                primaryCtaDownloadIcon ? (
                  <AppIconButton
                    icon={<Download size={16} color={bg === "dark" ? "#051c2c" : "white"} aria-label="download icon" />}
                    iconOnRightSide
                    link={primaryCtaLink}
                    label={primaryCtaLabel}
                    bg={bg}
                  />
                ) : (
                  <AppButton label={primaryCtaLabel} link={primaryCtaLink} bg={bg} />
                )
              ) : null}

              {secondaryCtaLabel ? (
                secondaryCtaDownloadIcon ? (
                  <AppIconButton
                    icon={<Download size={16} aria-label="download icon" />}
                    iconOnRightSide
                    intent={"secondary"}
                    link={secondaryCtaLinkCustomLogic}
                    onClick={onClickHandler}
                    label={secondaryCtaLabel}
                    bg={bg}
                  />
                ) : (
                  <AppButton
                    intent={"secondary"}
                    label={secondaryCtaLabel || ""}
                    link={secondaryCtaLinkCustomLogic}
                    onClick={onClickHandler}
                    bg={bg}
                  />
                )
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(TextBanner);
