/* eslint-disable @typescript-eslint/no-explicit-any */
import { KeyboardEvent, useRef } from "react";
import { ChevronRight } from "lucide-react";

import AppText from "./AppText";
import { AppIconButton } from "../buttons/AppIconButton";
import { cn } from "@/lib/utils";

interface DotInfo {
  ctaLink?: string;
  ctaLabel?: string;
  subheading: string;
  heading: string;
}

interface Dot {
  id: string;
  top: number;
  left: number;
  info: DotInfo;
}

interface TooltipState {
  idx: number;
  isLeft: boolean;
}

interface WorkspaceDotProps {
  visibleTooltip: TooltipState | null;
  setVisibleTooltip: (tooltip: TooltipState | null) => void;
  idx: number;
  dot: Dot;
  isMobile: boolean;
}

/**
 * WorkspaceDot component displays an interactive dot on the workspace with a tooltip
 * @param {WorkspaceDotProps} props - Component props
 * @param {TooltipState | null} props.visibleTooltip - Current visible tooltip state
 * @param {Function} props.setVisibleTooltip - Function to update tooltip visibility
 * @param {number} props.idx - Index of the dot
 * @param {Dot} props.dot - Dot data including position and info
 * @param {boolean} props.isMobile - Flag indicating if viewing on mobile device
 */
export const WorkspaceDot = ({ visibleTooltip, setVisibleTooltip, idx, dot, isMobile }: WorkspaceDotProps) => {
  const dotRef = useRef<HTMLDivElement>(null);

  /**
   * Handles dot click event and calculates tooltip position
   */
  const handleDotClick = () => {
    if (dotRef.current) {
      const { left } = dotRef.current.getBoundingClientRect();
      setVisibleTooltip({ idx, isLeft: left > 326 });
    }
  };

  /**
   * Handles keyboard events for accessibility
   * @param {KeyboardEvent} e - Keyboard event
   */
  const handleDotKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleDotClick();
    }
  };

  return (
    <div
      tabIndex={0}
      key={dot.id}
      ref={dotRef}
      onClick={handleDotClick}
      onKeyDown={handleDotKeyDown}
      style={{
        top: `${dot.top < 100 ? dot.top : 99}%`,
        left: `${dot.left < 100 ? dot.left : 99}%`,
      }}
      className={`ring-1 focus:ring-dijon ring-offset-1 ring-offset-transparent 
      ring-transparent absolute w-6 h-6 overflow-visible rounded-full outline-none`}
    >
      <div
        className={`bg-[#F8DD75] z-9 animation-pulse fade-in absolute 
        top-0 left-0 w-6 h-6 rounded-full cursor-pointer`}
      />

      {visibleTooltip && visibleTooltip.idx === idx && (
        <Tooltip
          ctaLink={dot.info.ctaLink}
          read_more={dot.info.ctaLabel}
          subheading={dot.info.subheading}
          isLeft={visibleTooltip.isLeft}
          isMobile={isMobile}
          heading={dot.info.heading}
        />
      )}
    </div>
  );
};

interface TooltipProps {
  ctaLink?: string;
  read_more?: string;
  subheading: string;
  heading: string;
  isMobile: boolean;
  isLeft: boolean;
}

/**
 * Tooltip component displays additional information when a dot is clicked
 * @param {TooltipProps} props - Component props
 * @param {string} [props.ctaLink] - Optional call-to-action link
 * @param {string} [props.read_more] - Optional read more text
 * @param {string} props.subheading - Subheading text
 * @param {string} props.heading - Main heading text
 * @param {boolean} props.isMobile - Flag indicating if viewing on mobile device
 * @param {boolean} props.isLeft - Flag indicating if tooltip should appear on the left
 */
const Tooltip = ({ ctaLink, read_more, subheading, heading, isMobile, isLeft }: TooltipProps) => (
  <div
    className={cn(
      `backdrop-blur-[6px] bg-[#FFFFFFCC] cursor-default flex-col z-50
      border-l-[1px] border-navy-blue pl-8 pt-4 pr-4 pb-2 
      w-max md:max-w-[326px] max-w-[310px] 
      shadow-[10px_10px_34px_-17px_rgba(0,0,0,0.75)]`,
      isMobile
        ? "fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
        : isLeft
        ? "absolute animate-in fade-in duration-500 -top-[80px] -left-[336px] -translate-x-[10px]"
        : "absolute animate-in fade-in duration-500 -top-[80px] left-[30px] translate-x-[10px]"
    )}
  >
    <AppText type="BODY_MEDIUM_EMPHASIS" className="mb-2">
      {heading}
    </AppText>

    <AppText type="BODY_SMALL" className="mb-2">
      {subheading}
    </AppText>

    {ctaLink && (
      <AppIconButton
        intent="ghost"
        label={read_more}
        link={ctaLink}
        icon={<ChevronRight size={20} aria-label="chevron right icon" />}
        iconOnRightSide
      />
    )}
  </div>
);
