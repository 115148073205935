/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @next/next/no-img-element */
"use client";

import React, { useCallback, useEffect, useRef } from "react";
import AppText from "../other/AppText";
// import { WorkspaceDot } from "../WorkspaceDot";
// import { useMediaQuery } from "@/hooks/mediaQuery";
import Image from "next/image";
import { cn } from "@/lib/utils";
import AppButton from "../buttons/AppButton";

export interface IWorksiteTab {
  id: string;
  label: string;
  imgSrc: string;
  dots: {
    top: number;
    left: number;
    id: string;
    info: {
      label: string;
      heading: string;
      subheading: string;
      ctaLink: string;
    };
  }[];
}

export interface IWorksiteExplore {
  id: string;
  label: string;
  heading: string;
  imgAlt: string;
  imgSrc: string;
  placeholder: string;
  dots: {
    top: number;
    left: number;
    id: string;
    info: {
      label: string;
      heading: string;
      subheading: string;
      ctaLink: string;
      ctaLabel: string;
    };
  }[];
  paddingBottom?: string;
  ctaLabel: string;
  ctaLink: string;
}

let mouseDown = false;
let startX = 0,
  scrollLeft = 0;

// old mocks "mockIndustryCategoryHeader"
const IndustryCategoryHeader = ({
  label,
  heading,
  id,
  imgSrc,
  imgAlt,
  paddingBottom /*dots, placeholder */,
  ctaLabel,
  ctaLink,
}: IWorksiteExplore) => {
  // const [visibleTooltip, setVisibleTooltip] = useState<{ idx: number; isLeft: boolean } | null>(null);
  const heroRef = useRef<HTMLDivElement>(null);
  const cursorRef = useRef<HTMLDivElement>(null);

  // const isMobile = useMediaQuery("(max-width: 640px)");

  const move = (e: MouseEvent) => {
    e.preventDefault();

    if (cursorRef?.current) {
      cursorRef.current.style.left = `${e.clientX - 37}px`;
      cursorRef.current.style.top = `${e.clientY - 37}px`;
    }

    if (!mouseDown || !heroRef.current) {
      return;
    }
    const x = e.pageX - heroRef.current.offsetLeft;
    const scroll = x - startX;
    heroRef.current.scrollLeft = scrollLeft - scroll;
  };

  const stopDragging = useCallback(() => {
    mouseDown = false;
  }, []);

  const startDragging = (e: MouseEvent) => {
    e.preventDefault();

    mouseDown = true;

    if (heroRef.current) {
      startX = e.pageX - heroRef.current.offsetLeft;
      scrollLeft = heroRef.current.scrollLeft;
    }
  };

  // const handleKeyDown = (ev: KeyboardEvent) => {
  //   if (ev.key === "Escape") {
  //     setVisibleTooltip(null);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     removeEventListener("keydown", handleKeyDown);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (heroRef.current) {
      heroRef.current.addEventListener("mousemove", move);
      heroRef.current.addEventListener("mousedown", startDragging);
      heroRef.current.addEventListener("mouseup", stopDragging);
      heroRef.current.addEventListener("mouseleave", stopDragging);
    }

    return () => {
      if (heroRef.current) {
        heroRef.current.removeEventListener("mousemove", move);
        heroRef.current.removeEventListener("mousedown", startDragging);
        heroRef.current.removeEventListener("mouseup", stopDragging);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        heroRef.current.removeEventListener("mouseleave", stopDragging);
      }
    };
  }, [stopDragging]);

  // const handleDotsParentClick = (e: React.MouseEvent<HTMLDivElement>) => {
  //   const target = e.target as HTMLDivElement;
  //   if (target && target.id === "dotsParent") setVisibleTooltip(null);
  // };

  // const handleTooltip = () => setVisibleTooltip(null);

  return (
    <div id={id} className={cn("relative", paddingBottom)}>
      <div className="no-scrollbar cursor-grab relative overflow-x-auto overflow-y-auto" ref={heroRef}>
        {/** background image */}
        <div className="w-full touch-pan-x origin-left min-w-[1024px] relative industryCategoryHeader">
          <Image
            priority
            src={imgSrc}
            alt={imgAlt}
            className="industryCategoryHeader object-cover w-full h-full"
            sizes="100vw"
            // fill
            // placeholder="blur"
            // blurDataURL={placeholder}

            width={0}
            height={0}
          />
        </div>

        {/* {visibleTooltip !== null && (
          <div onClick={handleTooltip} className="fixed top-0 bottom-0 left-0 right-0 z-20 bg-transparent" />
        )}

    
        <div onClick={handleDotsParentClick} id="dotsParent" className="absolute inset-0 w-[100%] min-w-[1024px] z-30">
          {dots.map((dot: any, idx: number) => {
            return (
              <WorkspaceDot
                key={dot.id}
                read_more={dot.info.ctaLabel}
                visibleTooltip={visibleTooltip}
                setVisibleTooltip={setVisibleTooltip}
                idx={idx}
                dot={dot}
                isMobile={isMobile}
                closeTooltip={handleTooltip}
              />
            );
          })}
        </div> */}
      </div>

      {/** text */}

      <div className="sm:py-8 bg-gradient-to-b from-white to-transparent lg:py-10 xl:py-14 z-300 absolute top-0 left-0 w-full py-6">
        <div className="max-w-[736px] mx-auto flex flex-col items-center">
          {label && (
            <AppText type="LABEL_LARGE" className="text-royal-blue mb-1 text-center">
              {label}
            </AppText>
          )}

          <AppText type="HEADLINE_MEDIUM" as="h1" className="mb-2 text-center">
            {heading}
          </AppText>

          {ctaLabel && ctaLink && <AppButton label={ctaLabel} link={ctaLink} className="mt-4" bg="light" />}
        </div>
      </div>
    </div>
  );
};

export default IndustryCategoryHeader;
