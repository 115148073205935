"use client";

import React from "react";
import ContentLayout from "../layouts/ContentLayout";
import AppText from "./AppText";
import IndustryCategoryBlock from "../mediaAndText/IndustryCategoryBlock";
import Image from "next/image";
import { AppIconButton } from "../buttons/AppIconButton";

import { ChevronRight } from "lucide-react";

const IndustryCategories = ({
  id,
  heading,
  subheading,
  categories,
  findOutMore,
  paddingBottom,
}: {
  id: string;
  heading: string;
  subheading: string;
  findOutMore: string;
  paddingBottom?: string;
  categories: {
    id: string;
    image: string;
    placeholder: string;
    imgAlt: string;
    heading: string;
    subheading: string;
    ctaLink: string;
  }[];
}) => {
  return (
    <>
      <ContentLayout bgColorClass="bg-white" id={id} innerClass="sm:pb-0 pb-0" paddingBottom={paddingBottom}>
        <div className="flex flex-col gap-16">
          <div className="flex flex-col items-center gap-4">
            <AppText type="HEADLINE_MEDIUM" className="text-center">
              {heading}
            </AppText>

            <AppText type="SUB_HEADING_SMALL" className="text-center max-w-[736px]">
              {subheading}
            </AppText>
          </div>
        </div>
      </ContentLayout>

      {categories[0] && <IndustryCategoryBlock mediaAlign="Left" ctaLabel={findOutMore} {...categories[0]} />}

      {categories[1] && categories[2] && (
        <ContentLayout bgColorClass="bg-white" innerClass="sm:py-0 py-0">
          <div className="sm:grid-cols-8 grid-col-4 xl:gap-10 grid gap-6">
            <CategoryCard {...categories[1]} findOutMore={findOutMore} />
            <CategoryCard {...categories[2]} findOutMore={findOutMore} />
          </div>
        </ContentLayout>
      )}

      {categories[3] && <IndustryCategoryBlock mediaAlign="Right" ctaLabel={findOutMore} {...categories[3]} />}

      {categories[4] && categories[4] && (
        <ContentLayout bgColorClass="bg-white" innerClass="sm:pt-0 pt-0">
          <div className="sm:grid-cols-8 grid-col-4 xl:gap-10 grid gap-6">
            <CategoryCard {...categories[4]} findOutMore={findOutMore} />
            <CategoryCard {...categories[5]} findOutMore={findOutMore} />
          </div>
        </ContentLayout>
      )}
    </>
  );
};

export default IndustryCategories;

const CategoryCard = ({
  id,
  image,
  imgAlt,
  heading,
  subheading,
  findOutMore,
  ctaLink,
  placeholder,
}: {
  id: string;
  image: string;
  imgAlt: string;
  heading: string;
  subheading: string;
  findOutMore: string;
  ctaLink: string;
  placeholder: string;
}) => {
  return (
    <div className="flex flex-col col-span-4 gap-4" id={id}>
      <div className="relative aspect-[16/9]">
        <Image
          className="object-cover w-full"
          sizes="(max-width: 640px) 100vw,(max-width: 1536px) 50vw, 700px"
          fill
          src={image}
          alt={imgAlt}
          placeholder="blur"
          blurDataURL={placeholder}
        />
      </div>

      {heading && (
        <AppText className="line-clamp-2 selection:" type="HEADLINE_SMALL">
          {heading}
        </AppText>
      )}

      {subheading && (
        <AppText className="line-clamp-3 " type="BODY_SMALL">
          {subheading}
        </AppText>
      )}

      <AppIconButton
        tabIndex={-1}
        intent="ghost"
        label={findOutMore}
        icon={<ChevronRight size={20} aria-label="chevron right icon" />}
        iconOnRightSide
        link={ctaLink}
        className="mt-auto"
      />
    </div>
  );
};
