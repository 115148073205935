"use client";
import React from "react";

interface IScriptComponent {
  id?: string;
  script: string;
}

const ScriptComponent = ({ script }: IScriptComponent) => {
  return <div dangerouslySetInnerHTML={{ __html: script }}></div>;
};

export default ScriptComponent;
