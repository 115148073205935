/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React from "react";
import ContentLayout from "@/components/layouts/ContentLayout";
import AppText from "@/components/other/AppText";
import { SwiperSlide } from "swiper/react";
import { cn } from "@/lib/utils";
import { Card } from "../cards/Card";
import { AppButton } from "../buttons/AppButton";
import Carousel from "@/components/other/Carousel";
import { cva, VariantProps } from "class-variance-authority";
import { colorsMap, fallbackColors } from "./constants";

const remapTypes = {
  Solution: "solutions",
  Industry: "industry",
  "Case Study": "caseStudy",
  "Blog Article": "blog",
  "ESG Resource": "esg",
} as { [key: string]: string };

type Article = {
  imgSrc: string;
  date?: string;
  title?: string;
  id: string;
  articleUrl: string;
  type: "blog" | "caseStudy" | "solution" | "industry";
  content?: string;
  brandSrc?: string;
  className?: string;
  buttonLabel: string;
};

interface CardsCarouselProps {
  id: string;
  label: string;
  title: string;
  description: string;
  articles: Article[];
  headerActionLink?: string;
  headerActionLabel?: string;
  background: string;
  numberOfCardsOnMaxView?: number;
  postTypes?: string;
  paddingBottom?: string;
}

const variants = cva([], {
  variants: {
    component: {
      label: [],
      title: [],
      description: [],
      carousel: [],
      buttonBg: [],
    },
    background: {
      "bg-navy-blue": [],
      "bg-black-5": [],
    },
  },
  compoundVariants: [
    {
      component: "label",
      background: "bg-navy-blue",
      className: "text-mellow",
    },
    {
      component: "label",
      background: "bg-black-5",
      className: "text-royal-blue",
    },
    {
      component: "title",
      background: "bg-navy-blue",
      className: "text-white normal-case font-medium",
    },
    {
      component: "description",
      background: "bg-navy-blue",
      className: "text-white",
    },
    {
      component: "buttonBg",
      background: "bg-navy-blue",
      className: "dark",
    },
  ],
  defaultVariants: {
    background: undefined,
    component: undefined,
  },
});

type VartsProps = VariantProps<typeof variants>;
type BgColorClassType = VartsProps["background"];
type ComponentType = VartsProps["component"];
const getVarts = (bgColorClass: string, component: string) => {
  const props: VartsProps = {
    background: bgColorClass as BgColorClassType,
    component: component as ComponentType,
  };
  return variants(props);
};

const CardsCarousel: React.FC<CardsCarouselProps> = ({
  id,
  label,
  title,
  description,
  articles,
  headerActionLink,
  headerActionLabel,
  numberOfCardsOnMaxView,
  background,
  postTypes,
  paddingBottom,
}) => {
  const { labelColor, textColor, bgColorClass, bg } = colorsMap[background] ?? fallbackColors;
  const overflow = postTypes === "Blog Articles" || postTypes === "ESG Resource";

  return (
    <ContentLayout bgColorClass={bgColorClass} id={id} paddingBottom={paddingBottom}>
      {label && (
        <AppText className={labelColor} type={"LABEL_LARGE"}>
          {label}
        </AppText>
      )}
      <div className={"mt-2 max-sm:flex-col flex flex-1 justify-between"}>
        <div className={"flex flex-col flex-[0.8] mr-10"}>
          {title && (
            <AppText type={"HEADLINE_MEDIUM"} className={textColor}>
              {title}
            </AppText>
          )}

          {description && (
            <AppText type={"SUB_HEADING_SMALL"} className={cn("mt-[16px]", textColor)}>
              {description}
            </AppText>
          )}
        </div>

        {headerActionLabel ? (
          <div className={"max-sm:mt-[32px]"}>
            <AppButton
              bg={getVarts(bgColorClass, "buttonBg") === "dark" ? "dark" : undefined}
              intent="secondary"
              label={headerActionLabel}
              link={headerActionLink}
            />
          </div>
        ) : null}
      </div>

      <Carousel
        wrapperClassName={"mt-10 flex"}
        className={"flex-1 flex items-stretch"}
        slidesPerView={1}
        spaceBetween={10}
        paginationType={"pageSelect"}
        bg={getVarts(bgColorClass, "buttonBg") === "dark" ? "dark" : undefined}
        cardsOverflow={overflow}
        breakpoints={
          postTypes === "ESG Resource"
            ? {
                640: {
                  slidesPerView: 2,
                  spaceBetween: 12,
                  slidesPerGroup: 2,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 32,
                  slidesPerGroup: 2,
                },

                1280: {
                  slidesPerView: 2.11,
                  spaceBetween: 32,
                  slidesPerGroup: 1,
                },
                1536: {
                  slidesPerView: 2.5,
                  spaceBetween: 32,
                  slidesPerGroup: 1,
                },
              }
            : {
                640: {
                  slidesPerView: 2,
                  spaceBetween: 12,
                  slidesPerGroup: 2,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 32,
                  slidesPerGroup: 2,
                },
                1024: {
                  slidesPerView: overflow ? 3.2 : numberOfCardsOnMaxView ?? 3,
                  slidesPerGroup: numberOfCardsOnMaxView || 3,
                  spaceBetween: 32,
                },
                1280: {
                  slidesPerView: overflow ? 3.4 : numberOfCardsOnMaxView ?? 3,
                  slidesPerGroup: numberOfCardsOnMaxView || 3,
                  spaceBetween: 32,
                },
                1536: {
                  slidesPerView: overflow ? 3.6 : numberOfCardsOnMaxView ?? 3,
                  slidesPerGroup: numberOfCardsOnMaxView || 3,
                  spaceBetween: 32,
                },
              }
        }
      >
        {articles.map((article) => renderArticle(article, postTypes, bg))}
      </Carousel>
    </ContentLayout>
  );
};

export default React.memo(CardsCarousel);

const renderArticle = (article: any, postTypes: string | undefined, bg: string) => {
  const {
    image,
    id,
    title,
    date,
    type,
    excerpt,
    imgAlt,
    featuredBrandAlt,
    label,
    brandSrc,
    className,
    ctaLabel,
    ctaLink,
    ctaDownloadIcon,
  } = article;

  const _type = type ? type : postTypes ? remapTypes[postTypes] : "solutions";

  const buttonLabel = postTypes === "Industries" ? title : ctaLabel;

  return (
    <SwiperSlide key={id} className="p-1">
      <Card
        key={id}
        title={title}
        imgSrc={image}
        imgAlt={imgAlt}
        date={date}
        articleUrl={ctaLink}
        buttonLabel={buttonLabel}
        type={_type}
        label={label}
        content={excerpt}
        brandSrc={brandSrc}
        brandAlt={featuredBrandAlt}
        className={className}
        darkBg={bg === "dark"}
        ctaDownloadIcon={ctaDownloadIcon}
      />
    </SwiperSlide>
  );
};
