"use client";
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from "react";
import Image from "next/image";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

import ContentLayout from "@/components/layouts/ContentLayout";
import AppText from "@/components/other/AppText";
import RichTextRenderer from "../other/RichTextRenderer";
import AppButton from "@/components/buttons/AppButton";
import Carousel from "./ProductDetailsHeaderSectionCustomCarousel";
import { useSwiper } from "@/hooks/useSwiper";
import { EmblaHorizontalCarousel, EmblaVerticalCarousel } from "../embla/vertical/emblaVerticalCarousel";
import { SwiperSlide } from "swiper/react";
import { useMediaQuery } from "@/hooks/mediaQuery";

export interface Media {
  id: string;
  key: "image" | "youtube video" | "bynder video";
  src: string;
  thumbnail: string;
  alt: string;
}

interface ProductDetailsHeaderSectionProps {
  label: string;
  heading: string;
  subheading: string;
  content: any;
  ctaLabel: string;
  ctaLink: string;
  media: Media[];
  paddingBottom?: string;
}

const renderMedia = ({ id, key, src, alt, thumbnail }: Media) => {
  return (
    <SwiperSlide key={id} className="h-auto">
      <div className="relative mx-auto">
        {key === "youtube video" ? (
          <LiteYouTubeEmbed
            params="enablejsapi=1"
            id={src}
            title={"youtube video"}
            iframeClass="w-full"
            poster="maxresdefault"
          />
        ) : key === "bynder video" ? (
          <video controls={true} preload="auto" playsInline={true} autoPlay={false} poster={thumbnail}>
            <source src={src} type="video/mp4" />
          </video>
        ) : (
          <Image
            src={src}
            alt={alt}
            sizes="100vw"
            className="aspect-[1.7] object-cover w-full max-h-[500px]"
            width={0}
            height={0}
          />
        )}
      </div>
    </SwiperSlide>
  );
};

const ProductDetailsHeaderSection: React.FC<ProductDetailsHeaderSectionProps> = (props) => {
  const { label, heading, subheading, ctaLabel, ctaLink, media, content, paddingBottom } = props;
  const isMobile = useMediaQuery("(max-width: 1024px)");

  const thumbnails = useMemo(() => media.map((item) => item.thumbnail), [media]);

  const mySwiper: any = useSwiper();
  const { currentPage, goToIndex } = mySwiper;

  return (
    <ContentLayout bgColorClass="bg-white" paddingBottom={paddingBottom}>
      <div className="flex flex-col gap-2">
        {label && (
          <AppText type={"LABEL_LARGE"} className="text-royal-blue">
            {label}
          </AppText>
        )}

        <div className="lg:flex-row lg:gap-10 flex flex-col justify-between gap-8">
          <div className="flex flex-col gap-4 max-w-[736px]">
            <AppText type={"HEADLINE_LARGE"}>{heading}</AppText>
            <AppText type="SUB_HEADING_MEDIUM" className={"mb-4 font-bold"}>
              {subheading}
            </AppText>

            <RichTextRenderer richTextDocument={content} id={"rtp"} locations className={"text-sm"} />
          </div>

          <AppButton link={ctaLink} intent={"secondary"} label={ctaLabel || ""} />
        </div>
      </div>

      <div className="lg:grid-cols-12 grid grid-cols-4 gap-5 mt-8 lg:max-h-[500px]  ">
        <div className="lg:col-span-9 flex justify-center col-span-4 gap-6 overflow-hidden">
          <div className="relative flex-1 overflow-hidden">
            <Carousel
              swiperData={mySwiper}
              loop={false}
              slidesPerView={1}
              slidesPerGroup={1}
              spaceBetween={10}
              pagination={true}
              modules={[]}
              centeredSlides={true}
              rewind={true}
              watchOverflow={true}
              watchSlidesProgress={true}
              paginationType={"arrowSlider"}
              withoutWrapper={false}
              videoPauseOnChange={true}
            >
              {(media || []).map((item) => renderMedia(item))}
            </Carousel>
          </div>
        </div>

        {!isMobile && (
          <EmblaVerticalCarousel
            slides={thumbnails}
            options={{
              axis: "y",
              loop: true,
              dragFree: true,
              containScroll: "keepSnaps",
              align: "center",
            }}
            onSlideClick={(index) => goToIndex(index)}
            currentPage={currentPage}
          />
        )}
      </div>

      {isMobile && (
        <EmblaHorizontalCarousel
          slides={thumbnails}
          options={{
            axis: "x",
            loop: true,
            dragFree: true,
            containScroll: "keepSnaps",
            align: "center",
          }}
          onSlideClick={(index) => goToIndex(index)}
          currentPage={currentPage}
        />
      )}
    </ContentLayout>
  );
};

export default React.memo(ProductDetailsHeaderSection);
