/* eslint-disable @next/next/no-img-element */
/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { useEffect, useRef, useState } from "react";
import Image from "next/image";

import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

import AppText from "@/components/other/AppText";
import { CaseStudySection } from "@/mocks/mocks";
import RichTextRenderer from "../other/RichTextRenderer";
import ContentLayout from "../layouts/ContentLayout";
import { Separator } from "../ui/separator";
import { MapPin } from "lucide-react";
import { cn } from "@/lib/utils";
import { customDebounce } from "@/lib/customDebounce";

interface IHeaderCaseStudy {
  id?: string;
  label: string;
  heading: string;
  content: any;
  location: string;
  image: string;
  placeholder: string;
  brand: string;
  imgAlt: string;
  brandAlt: string;
  caseStudySections: CaseStudySection[];
  paddingBottom?: string;
  video?: string;
  youtubeVideoId?: string;
}

const HeaderCaseStudy: React.FC<IHeaderCaseStudy> = ({
  id,
  image,
  placeholder,
  caseStudySections,
  heading,
  brand,
  location,
  content,
  label,
  brandAlt,
  imgAlt,
  paddingBottom,
  video,
  youtubeVideoId,
}) => {
  const wrapper = useRef<HTMLDivElement | null>(null);
  const [heroImageMaxHeight, setHeroImageMaxHeight] = useState("600px");

  useEffect(() => {
    const debouncedHandleResize = customDebounce(() => {
      if (window.innerWidth < 1024) setHeroImageMaxHeight("600px");
      else if (wrapper?.current) {
        const maxHeight = wrapper.current.offsetHeight - 160;
        setHeroImageMaxHeight(`${maxHeight}px`);
      }
    }, 300);

    debouncedHandleResize();

    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  return (
    <div ref={wrapper} className={cn("flex flex-col", paddingBottom)}>
      <ContentLayout
        id={id}
        bgColorClass="bg-navy-blue"
        className="relative"
        innerClass="sm:pt-20 pt-12 sm:pb-10 pb-10 px-4 lg:pl-8 lg:pr-0 xl:pl-20 xl:pr-0 2xl:px-[104px]"
      >
        <div className="lg:grid-cols-12 sm:gap-6 xl:gap-10 grid grid-cols-4 gap-2">
          <div className="lg:col-span-5 lg:order-first flex flex-col order-last col-span-4">
            {label && (
              <AppText type={"LABEL_LARGE"} className="text-mellow">
                {label}
              </AppText>
            )}
            <AppText type={"HEADLINE_MEDIUM"} as="h1" className="mt-2 mb-4 text-white">
              {heading}
            </AppText>

            <div className="lg:min-h-[72px]">
              <RichTextRenderer richTextDocument={content} id={`rt-${id}`} locations className=" text-sm text-white" />
            </div>
            <div className={"flex gap-4 text-white items-center mt-[32px]"}>
              <MapPin size={20} strokeWidth={1.5} aria-label="map pin icon" />
              {location}
            </div>
          </div>
          <div className={cn("col-span-4 lg:col-span-7 relative")}>
            <div className={cn("lg:absolute relative left-0 right-0 top-0", `max-h-[${heroImageMaxHeight}] h-full`)}>
              {youtubeVideoId ? (
                <LiteYouTubeEmbed
                  id={youtubeVideoId}
                  title={heading}
                  iframeClass={`max-h-[${heroImageMaxHeight}] h-full w-full`}
                />
              ) : video ? (
                <video controls={true} preload="auto" playsInline={true} autoPlay={false}>
                  <source src={video} type="video/mp4" />
                </video>
              ) : (
                <img
                  className="customImgPlaceholder object-cover w-full"
                  style={{
                    maxHeight: heroImageMaxHeight,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "100%",
                    backgroundImage: `url(${placeholder})`,
                  }}
                  src={image}
                  alt={imgAlt}
                />
              )}

              {brand && !youtubeVideoId && !video && (
                <div className="bottom-4 left-4 absolute bg-white w-[130px] lg:w-[159px] flex justify-center items-center">
                  <Image
                    className="object-cover w-full aspect-[1.94]"
                    sizes="100vw"
                    width={0}
                    height={0}
                    src={brand}
                    alt={brandAlt}
                    priority
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </ContentLayout>
      <ContentLayout bgColorClass="bg-navy-blue-80" innerClass="sm:pb-20 pb-12 sm:pt-8 pt-8">
        <div className="lg:grid-cols-12 sm:gap-6 xl:gap-10 grid grid-cols-4 gap-2">
          <div className="lg:col-span-5 gap-y-4 flex flex-wrap col-span-4">
            {caseStudySections.map((s, index, array) => {
              const isLast = index === array.length - 1;
              if (!s.title || !s.content) return null;
              return (
                <div key={index} className={"relative flex items-start"}>
                  <div className={"max-w-[150px] flex-1 overflow-hidden"}>
                    <AppText type={"BODY_MEDIUM_EMPHASIS"} className={"text-white"}>
                      {s.title}
                    </AppText>
                    <AppText type={"BODY_MEDIUM"} className="text-black-10">
                      {s.content}
                    </AppText>
                  </div>
                  {!isLast && (
                    <div className="py-2 h-full w-[1px] sm:mx-8 mx-6">
                      <Separator orientation="vertical" className="opacity-30" />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </ContentLayout>
    </div>
  );
};

export default React.memo(HeaderCaseStudy);
