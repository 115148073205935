/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @next/next/no-img-element */
"use client";

import ContentLayout from "@/components/layouts/ContentLayout";
import AppText from "@/components/other/AppText";
import { cn } from "@/lib/utils";
import { colorsMap, fallbackColors } from "../other/constants";
import RichTextRenderer from "../other/RichTextRenderer";
import { IMediaAndTextImage } from "./MediaAndText";
import Image from "next/image";

export interface IImageTextBlock {
  id: string;
  images: IMediaAndTextImage[];
  heading: string;
  content: any;
  background: string;
  mediaAlign: string;
  paddingBottom?: string;
}

const ImagesAndText: React.FC<IImageTextBlock> = ({
  id,
  images,
  heading,
  content,
  background = "white",
  mediaAlign,
  paddingBottom,
}) => {
  const { textColor, bgColorClass, border } = colorsMap[background] ?? fallbackColors;

  return (
    <ContentLayout
      id={id}
      bgColorClass={bgColorClass}
      innerClass={cn(
        mediaAlign === "Left"
          ? "lg:pl-0 lg:pr-[80px] xl:pl-0 xl:pr-[80px] 2xl:pl-[104px] 2xl:pr-[104px] overflow-hidden"
          : "lg:pr-0 lg:pl-[80px] xl:pr-0 xl:pl-[80px] 2xl:pr-[104px] 2xl:pl-[104px] overflow-hidden"
      )}
      paddingBottom={paddingBottom}
    >
      <div className="lg:grid-cols-12 xl:gap-10 lg:gap-6 grid grid-cols-4 gap-10">
        {mediaAlign === "Left" && images && <TwoImageBlock imgOne={images[0]} imgTwo={images[1]} />}

        <div
          className={cn(
            "col-span-4 lg:col-span-5 xxl:col-span-4 flex flex-col lg:justify-center ",
            // background === "dijon-10" ? "border-royal-blue" : "border-mellow",
            mediaAlign !== "Left" && "order-last lg:order-first"
          )}
        >
          <div
            className={cn(
              "col-span-4 lg:col-span-5 xxl:col-span-4 flex flex-col lg:justify-center pl-8 border-l-2",
              border
            )}
          >
            {heading && (
              <AppText className={cn("mt-2 mb-4", textColor)} type="HEADLINE_SMALL">
                {heading}
              </AppText>
            )}

            {content && (
              <RichTextRenderer
                richTextDocument={content}
                id={`rt-${id}`}
                locations
                className={cn("text-sm", textColor)}
              />
            )}
          </div>
        </div>
        {mediaAlign !== "Left" && images && <TwoImageBlock imgOne={images[0]} imgTwo={images[1]} />}
      </div>
    </ContentLayout>
  );
};

export default ImagesAndText;

const TwoImageBlock = ({ imgOne, imgTwo }: { imgOne: IMediaAndTextImage; imgTwo: IMediaAndTextImage }) => (
  <div className=" lg:col-span-7 xxl:col-span-8 col-span-4">
    <div className="sm:flex-row lg:gap-8 xl:gap-10 flex flex-col items-center w-full h-full gap-6">
      <div className="lg:aspect-square aspect-[16/9] relative w-full h-full">
        <Image
          sizes="(max-width:640px) 100vw, (max-width:1536px) 30vw, 500px"
          fill
          src={imgOne.url}
          alt={imgOne.alt}
          className="lg:aspect-square aspect-[16/9] object-cover "
          placeholder="blur"
          blurDataURL={imgOne.placeholder}
        />
      </div>
      <div className="lg:aspect-square aspect-[16/9] relative w-full h-full">
        <Image
          sizes="(max-width:640px) 100vw, (max-width:1536px) 30vw, 430px"
          fill
          src={imgTwo.url}
          alt={imgTwo.alt}
          className="lg:aspect-square aspect-[16/9] object-cover "
          placeholder="blur"
          blurDataURL={imgTwo.placeholder}
        />
      </div>
    </div>
  </div>
);
