import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ExpandMore24 } from "@/assets/Icons";

const MobileDropdown = ({
  groups,
  dict,
  total,
  activeGroup,
  setActiveGroup,
}: {
  groups: string[];
  dict: { [key: string]: string };
  total: { [key: string]: number };
  activeGroup: string;
  setActiveGroup: (group: string) => void;
}) => {
  return (
    <Listbox>
      <div className="relative flex-1 w-full">
        <Listbox.Button
          className={`focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 
              focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 
               relative w-full py-2 px-4 text-left cursor-pointer font-saira text-14`}
        >
          <span className="block truncate">{`${dict[activeGroup]} (${total[activeGroup]})`}</span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ExpandMore24 />
          </span>
        </Listbox.Button>
        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Listbox.Options
            className={`max-h-68 ring-1 ring-dijon focus:outline-none sm:text-sm absolute w-full 
                overflow-auto text-base bg-black-10  shadow-lg text-black`}
          >
            {groups.map((group) => (
              <Listbox.Option
                key={group}
                onClick={() => setActiveGroup(group)}
                className={`relative z-100 cursor-pointer select-none py-2 px-4 text-black-80 hover:bg-navy-blue hover:text-white`}
                value={group}
              >
                {`${dict[group]} (${total[group]})`}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default MobileDropdown;
