export const getDataFromCookie = () => {
  // Parse data from cookie to get utm values from cookie
  const utm_source = document.cookie
    .split("; ")
    .find((row) => row.startsWith("utm_source"))
    ?.split("=")[1];

  const utm_medium = document.cookie
    .split("; ")
    .find((row) => row.startsWith("utm_medium"))
    ?.split("=")[1];

  const utm_campaign = document.cookie
    .split("; ")
    .find((row) => row.startsWith("utm_campaign"))
    ?.split("=")[1];

  const utm_term = document.cookie
    .split("; ")
    .find((row) => row.startsWith("utm_term"))
    ?.split("=")[1];

  const utm_content = document.cookie
    .split("; ")
    .find((row) => row.startsWith("utm_content"))
    ?.split("=")[1];

  return {
    utm_campaign,
    utm_content,
    utm_medium,
    utm_source,
    utm_term,
  };
};

export default getDataFromCookie;
