/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import ContentLayout from "../layouts/ContentLayout";
import AppText from "./AppText";
import { cn } from "@/lib/utils";
import { WorkspaceDot } from "./WorkspaceDot";
import { useMediaQuery } from "@/hooks/mediaQuery";
import Image from "next/image";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { useDebounceValue } from "usehooks-ts";
import AppButton from "../buttons/AppButton";

/**
 * Interface representing a tab in the worksite explorer
 * @interface IWorksiteTab
 */
export interface IWorksiteTab {
  /** Unique identifier for the tab */
  id: string;
  /** Display label for the tab */
  label: string;
  /** Source URL for the tab's image */
  imgSrc: string;
  /** Alt text for the tab's image */
  imgAlt: string;
  /** Array of interactive dots/hotspots on the image */
  dots: {
    /** Vertical position as percentage */
    top: number;
    /** Horizontal position as percentage */
    left: number;
    /** Unique identifier for the dot */
    id: string;
    /** Information displayed in dot's tooltip */
    info: {
      label: string;
      heading: string;
      subheading: string;
      ctaLink?: string;
      ctaLabel?: string;
    };
  }[];
}

/**
 * Interface for the main WorksiteExplore component props
 * @interface IWorksiteExplore
 */
export interface IWorksiteExplore {
  /** Unique identifier for the worksite section */
  id: string;
  /** Section label */
  label: string;
  /** Main heading text */
  heading: string;
  /** Subheading text */
  subheading: string;
  /** Array of worksite tabs */
  tabs: IWorksiteTab[];
  /** Text for the "Find out more" button */
  findOutMore: string;
}

/**
 * Component for displaying an interactive worksite exploration interface
 * Allows users to pan across a large image and interact with hotspots
 */
const WorksiteExplore: React.FC<IWorksiteExplore> = ({ label, heading, subheading, tabs, id, findOutMore }) => {
  // State management
  const [dragState, setDragState] = useState({
    mouseDown: false,
    startX: 0,
    scrollLeft: 300,
  });
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [visibleTooltip, setVisibleTooltip] = useState<{ idx: number; isLeft: boolean } | null>(null);
  const [maxScrollLeft, setMaxScrollLeft] = useState(0);
  const [showArrows, setShowArrows] = useState({ left: false, right: true });
  const [debouncedValue, setValue] = useDebounceValue(0, 200);

  // Refs and hooks
  const heroRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery("(max-width: 640px)");

  /**
   * Initialize scroll position on component mount
   */
  useLayoutEffect(() => {
    if (heroRef?.current) {
      heroRef.current.scrollLeft = 800;
    }
  }, []);

  /**
   * Update arrow visibility based on scroll position
   */
  useEffect(() => {
    const diff = maxScrollLeft - debouncedValue;

    const isMaxRight = Math.abs(diff) <= 10;

    if (isMaxRight) {
      setShowArrows({ left: true, right: false });
    } else if (debouncedValue === 0) {
      setShowArrows({ left: false, right: true });
    } else {
      setShowArrows({ left: true, right: true });
    }
  }, [maxScrollLeft, debouncedValue]);

  /**
   * Handles mouse movement for dragging functionality
   * @param {MouseEvent} e - Mouse event
   */
  const move = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();

      if (!dragState.mouseDown || !heroRef.current) {
        return;
      }
      const x = e.pageX - heroRef.current.offsetLeft;
      const scroll = x - dragState.startX;
      heroRef.current.scrollLeft = dragState.scrollLeft - scroll;
    },
    [dragState]
  );

  /**
   * Updates scroll position tracking
   */
  const scrollListener = useCallback(() => {
    const newScrollLeft = heroRef?.current?.scrollLeft;
    setValue(newScrollLeft || 0);
  }, [setValue]);

  /**
   * Handles the start of a drag operation
   * @param {MouseEvent} e - Mouse event
   */
  const startDragging = useCallback((e: MouseEvent) => {
    e.preventDefault();

    if (heroRef.current) {
      setDragState({
        mouseDown: true,
        startX: e.pageX - heroRef.current.offsetLeft,
        scrollLeft: heroRef.current.scrollLeft,
      });
    }
  }, []);

  /**
   * Stops the dragging operation
   */
  const stopDragging = useCallback(() => {
    setDragState((prev) => ({ ...prev, mouseDown: false }));
  }, []);

  /**
   * Handles main scroll events
   */
  const mainScroll = useCallback(() => {
    setVisibleTooltip(null);
  }, []);

  /**
   * Handles keyboard events
   * @param {KeyboardEvent} ev - Keyboard event
   */
  const handleKeyDown = useCallback((ev: KeyboardEvent) => {
    if (ev.key === "Escape") {
      setVisibleTooltip(null);
    }
  }, []);

  // Setup event listeners
  useEffect(() => {
    const currentRef = heroRef.current; // Store ref value

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("scroll", mainScroll);

    if (currentRef) {
      currentRef.addEventListener("scroll", scrollListener);
      setMaxScrollLeft(currentRef.scrollWidth - currentRef.clientWidth);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("scroll", mainScroll);
      currentRef?.removeEventListener("scroll", scrollListener);
    };
  }, [handleKeyDown, mainScroll, scrollListener]);

  // Setup drag event listeners
  useEffect(() => {
    const currentRef = heroRef.current; // Store ref value

    if (currentRef) {
      currentRef.addEventListener("mousemove", move);
      currentRef.addEventListener("mousedown", startDragging);
      currentRef.addEventListener("mouseup", stopDragging);
      currentRef.addEventListener("mouseleave", stopDragging);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("mousemove", move);
        currentRef.removeEventListener("mousedown", startDragging);
        currentRef.removeEventListener("mouseup", stopDragging);
        currentRef.removeEventListener("mouseleave", stopDragging);
      }
    };
  }, [move, startDragging, stopDragging]);

  /**
   * Handles clicks on the dots container
   */
  const handleDotsParentClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    if (target && target.id === "dotsParent") setVisibleTooltip(null);
  }, []);

  /**
   * Handles tooltip close
   */
  const handleTooltip = useCallback(() => setVisibleTooltip(null), []);

  /**
   * Handles scroll button clicks
   * @param {"left" | "right"} side - Scroll direction
   */
  const handleScroll = useCallback((side: "left" | "right") => {
    if (heroRef.current) {
      heroRef.current.scrollLeft += side === "left" ? -420 : 420;
    }
  }, []);

  return (
    <>
      <ContentLayout
        id={id}
        bgColorClass="bg-[#BCE0EE]"
        innerClass="flex flex-col items-center sm:pb-[2px] pb-[2px] px-4 lg:px-8"
      >
        {label && (
          <AppText type="LABEL_LARGE" className="text-royal-blue mb-1 text-center">
            {label}
          </AppText>
        )}

        <AppText type="HEADLINE_MEDIUM" className="mb-2 text-center">
          {heading}
        </AppText>

        <AppText type="SUB_HEADING_SMALL" className="text-center">
          {subheading}
        </AppText>

        <div className="flex flex-wrap justify-center gap-4 pb-1 mt-8">
          {tabs.map((tab: IWorksiteTab) => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab)}
              className={cn(
                `outline-none focus-visible:outline-royal-blue rounded-[100px] select-none text-14 leading-[170%] 
                font-[500] px-4 py-1 text-black bg-[#00000010] hover:bg-[#334D7C] hover:text-white 
                font-sairaSemiCondensed`,
                activeTab.id === tab.id &&
                  "bg-[#005EB8] active:bg-navy-blue-20 text-white pointer-events-none opacity-100"
              )}
            >
              {tab.label}
            </button>
          ))}
        </div>
      </ContentLayout>

      <div className="relative">
        <div
          className="no-scrollbar cursor-grab scroll-smooth relative overflow-x-auto overflow-y-auto"
          ref={heroRef}
          id={activeTab.id}
        >
          <div className="w-[160%] max-h touch-pan-x origin-left min-w-[1800px]">
            <Image
              priority
              unoptimized={true}
              src={activeTab.imgSrc}
              alt={activeTab.imgAlt}
              className="object-cover w-full"
              sizes="100vw"
              width={0}
              height={0}
            />
          </div>

          {visibleTooltip !== null && (
            <div onClick={handleTooltip} className="fixed top-0 bottom-0 left-0 right-0 z-20 bg-transparent" />
          )}

          <div
            onClick={handleDotsParentClick}
            id="dotsParent"
            className={cn("absolute inset-0 w-[160%] min-w-[1800px] overflow-hidden", visibleTooltip ? "z-50" : "z-30")}
          >
            {activeTab.dots.map((dot: any, idx: number) => (
              <WorkspaceDot
                key={dot.id}
                visibleTooltip={visibleTooltip}
                setVisibleTooltip={setVisibleTooltip}
                idx={idx}
                dot={dot}
                isMobile={isMobile}
              />
            ))}
          </div>
        </div>

        <AppButton label={findOutMore} className="worksite-button" bg="dark" link="/industries" intent="primary" />

        <button
          type="button"
          id="button-worksite-explore-handle-scroll-left"
          aria-label="Scroll left"
          onClick={() => handleScroll("left")}
          className={cn(
            "worksite-arrow font-sairaSemiCondensed transition left-6 sm:left-14 z-40 p-2 sm:p-3",
            !showArrows.left && "hidden"
          )}
        >
          <ArrowLeft color="white" size={isMobile ? 18 : 24} aria-label="arrow left icon" />
        </button>

        <button
          type="button"
          id="button-worksite-explore-handle-scroll-right"
          aria-label="Scroll right"
          onClick={() => handleScroll("right")}
          className={cn(
            "worksite-arrow font-sairaSemiCondensed transition right-6 sm:right-14 z-40 p-2 sm:p-3",
            !showArrows.right && "hidden"
          )}
        >
          <ArrowRight color="white" size={isMobile ? 16 : 24} aria-label="arrow right icon" />
        </button>
      </div>
    </>
  );
};

export default WorksiteExplore;
