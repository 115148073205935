/* eslint-disable @typescript-eslint/no-explicit-any */
import { APIProvider } from "@vis.gl/react-google-maps";
import { CalendarIcon } from "lucide-react";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import { format } from "date-fns";
import Image from "next/image";

import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { FormControl, FormField, FormItem, FormMessage } from "../ui/form";
import { Textarea } from "../ui/textarea";
import { Input } from "../ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Calendar } from "../ui/calendar";
import CustomFormLabel from "../other/CustomFormLabel";
import { cn } from "@/lib/utils";
import { Button as CNButton } from "@/components/ui/button";
import SiteInputRAQB from "./SiteInput";
import AppText from "../other/AppText";
import { FieldError } from "react-hook-form";

export const FieldTextArea = ({
  control,
  name,
  placeholder,
  loading,
  label,
}: {
  control: any;
  name: string;
  placeholder: string;
  loading: boolean;
  label?: string;
}) => (
  <FormField
    control={control}
    name={name}
    render={({ field }) => {
      return (
        <FormItem className="flex-1 space-y-0">
          {label && <CustomFormLabel label={label} />}
          <Textarea
            disabled={loading}
            aria-label="additional info"
            placeholder={placeholder}
            maxLength={5000}
            {...field}
            className="font-saira border-[#898A8D] rounded-[5px]"
          />
          <FormMessage className="font-saira text-14 font-[400]" />
        </FormItem>
      );
    }}
  />
);

export const FieldGoogleAddress = ({
  control,
  label,
  setSelectedPlaceBuy,
  deliveryAddressBlur,
  handleDeliveryAddressOnChange,
  placeholder,
  loading,
  name,
  className,
}: {
  control: any;
  label: string;
  setSelectedPlaceBuy: any;
  deliveryAddressBlur: any;
  handleDeliveryAddressOnChange: any;
  placeholder: string;
  loading: boolean;
  name: string;
  className?: string;
}) => (
  <APIProvider apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API!}>
    <div className="flex-1">
      <FormField
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => {
          return (
            <FormItem className="flex-1 space-y-0">
              <div className="h-0 overflow-hidden">
                <FormControl>
                  <Input
                    tabIndex={-1}
                    {...field}
                    className="hiddenInput"
                    autoComplete="nope"
                    role="presentation"
                    aria-describedby={undefined}
                  />
                </FormControl>
              </div>

              <SiteInputRAQB
                className={cn("border-[#898A8D] rounded-[5px]", className)}
                label={label}
                setSelectedPlace={setSelectedPlaceBuy}
                deliveryAddressBlur={deliveryAddressBlur}
                // error={!!errors?.selectedPlace}
                handleDeliveryAddressOnChange={handleDeliveryAddressOnChange}
                placeholder={placeholder}
                disabled={loading}
                error={!!error}
              />
              <FormMessage className="font-saira text-14 font-[400]" />
            </FormItem>
          );
        }}
      />
    </div>
  </APIProvider>
);

export const FieldCalendar = ({
  control,
  loading,
  name,
  placeholder,
  label,
  className,
}: {
  control: any;
  loading: boolean;
  name: string;
  placeholder: string;
  label: string;
  className?: string;
}) => (
  <FormField
    control={control}
    name={name}
    render={({ field, fieldState: { error } }) => {
      return (
        <FormItem className="flex flex-col flex-1 space-y-0">
          <span
            className={cn("font-saira text-14 font-[400] tracking-[-0.1px] leading-[1.7]", error && "text-red-500")}
          >
            {label}
          </span>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <CNButton
                  aria-describedby={undefined}
                  ref={field.ref}
                  disabled={loading}
                  variant={"outline"}
                  className={cn(
                    "pl-3 text-left font-normal font-saira text-16 border-[#898A8D] focus-visible:ring-blue-500/50 rounded-[5px]",
                    !field.value && "text-muted-foreground",
                    error && "border-red-500",
                    className
                  )}
                >
                  {field.value ? format(field.value, "PPP") : <span className="text-[#64748b]">{placeholder}</span>}
                  <CalendarIcon className="w-4 h-4 ml-auto opacity-50" aria-label="calendar" />
                </CNButton>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={field.value}
                onSelect={field.onChange}
                disabled={(date) => date < new Date() || date > new Date("2100-01-01")}
                initialFocus
                className="font-saira"
              />
            </PopoverContent>
          </Popover>

          <FormMessage className="font-saira text-14 font-[400]" />
        </FormItem>
      );
    }}
  />
);

export const FieldText = ({
  control,
  loading,
  name,
  placeholder,
  label,
  footnote,
  className,
}: {
  control: any;
  loading: boolean;
  name: string;
  placeholder: string;
  label: string;
  footnote?: string;
  className?: string;
}) => (
  <FormField
    control={control}
    name={name}
    render={({ field, fieldState: { error } }) => (
      <FormItem className={cn("flex-1 space-y-0", className)}>
        <CustomFormLabel label={label} />
        <FormControl>
          <Input
            placeholder={placeholder}
            {...field}
            disabled={loading}
            aria-describedby={undefined}
            aria-invalid={error ? true : false}
            className={cn("font-saira border-[#898A8D] rounded-[5px]", error && "border-red-500")}
          />
        </FormControl>
        <FormMessage className="font-saira text-14 font-[400]" />
        {footnote && (
          <AppText className="lg:col-span-2" type="CAPTION_MEDIUM">
            {footnote}
          </AppText>
        )}
      </FormItem>
    )}
  />
);

export const FieldPhone = ({
  control,
  loading,
  name,
  placeholder,
  label,
}: {
  control: any;
  loading: boolean;
  name: string;
  placeholder: string;
  label: string;
}) => (
  <FormField
    control={control}
    name={name}
    rules={{
      validate: (value) =>
        matchIsValidTel(value ?? "", {
          onlyCountries: ["US", "CA"],
        }),
    }}
    render={({ field: { value, ref, onChange, ...fieldProps }, fieldState }) => (
      <FormItem className="flex flex-col flex-1 space-y-0">
        <CustomFormLabel label={label} />

        {/** helper input for scroll to error */}
        <div className="h-0 overflow-hidden">
          <FormControl>
            <Input
              aria-label="telInputScrollTarget"
              id="telInputScrollTarget"
              className="hiddenInput"
              ref={ref}
              tabIndex={-1}
              autoComplete="nope"
              aria-describedby={undefined}
              type="presentation"
            />
          </FormControl>
        </div>

        <div
          className={cn(
            "telInputCustom focus-within:ring-[2px] ring-offset-2 bg-white ring-blue-500/50 outline-none",
            fieldState.error && "!border-red-500"
          )}
        >
          <FormControl>
            <MuiTelInput
              aria-describedby={undefined}
              {...fieldProps}
              inputProps={{ style: { padding: "0.5rem", backgroundColor: "white", borderColor: "#898A8D" } }}
              defaultCountry="US"
              forceCallingCode
              onlyCountries={["US", "CA"]}
              placeholder={placeholder}
              value={value ?? ""}
              disabled={loading}
              onChange={(currentValue) => {
                onChange(currentValue);
                // if (currentValue.startsWith("+1")) {
                //   onChange(currentValue);
                // } else if (currentValue.startsWith("+")) {
                //   const newValue = currentValue.replace("+", "+1");
                //   onChange(newValue);
                // } else {
                //   onChange("+1" + currentValue);
                // }
              }}
              aria-invalid={fieldState.error ? true : false}
              error={fieldState.invalid ? true : false}
              disableDropdown
            />
          </FormControl>
        </div>
        <FormMessage className="font-saira text-14 font-[400]" />
      </FormItem>
    )}
  />
);

interface IOptionIndustry {
  id: string;
  description: string;
  internalLabel: string;
  key: string;
  optionType: string;
  title: string;
}

export const FieldDropdownIndustries = ({
  control,
  name,
  placeholder,
  label,
  options,
}: {
  control: any;
  name: string;
  placeholder: string;
  label: string;
  options: any;
}) => (
  <>
    <FormField
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        return (
          <FormItem className="w-full space-y-0">
            <p className={cn("font-saira text-14 font-[400] tracking-[-0.1px] leading-[1.7]", error && "text-red-500")}>
              {label}
            </p>
            <FormControl>
              <Select onValueChange={field.onChange} value={field.value}>
                <SelectTrigger
                  // title="Choose one of the industries"
                  extra={options.find((ind: IOptionIndustry) => ind.key === field.value)?.title}
                  className={cn(
                    "!mt-0 placeholder:text-[#64748b] border-[#898A8D] rounded-[5px]",
                    error && "border-red-500"
                  )}
                  aria-describedby={undefined}
                  {...field}
                >
                  <SelectValue placeholder={placeholder} />
                </SelectTrigger>

                <SelectContent className="selectContentRadix">
                  <SelectGroup className="flex flex-col gap-2">
                    {/* <SelectLabel className="hidden1">{label}</SelectLabel> */}

                    {options.map((ind: IOptionIndustry) => (
                      <SelectItem key={ind.id} value={ind.key} className="overflow-hidden">
                        <div className=" cursor-pointer">
                          <div className="font-saira font-medium text-[#24262E] tracking-[-0.1px] font-size-4">
                            {ind.title}
                          </div>
                          {ind.description && (
                            <div className="font-saira text-[#24262E] tracking-[-0.1px] font-size-4 break-all whitespace-wrap w-full">
                              {ind.description}
                            </div>
                          )}
                        </div>
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </FormControl>
            <FormMessage className="font-saira text-14 font-[400]" />
            {/* {form?.formState?.errors?.quote_industries && (
            <p className="text-red-500 font-saira text-14 font-[400]">{formCopy["required"]}</p>
          )} */}
          </FormItem>
        );
      }}
    />
  </>
);

export const FieldFallbackIndustries = ({
  control,
  name,
  placeholder,
  label,
  watchIndustry,
}: {
  control: any;
  name: string;
  placeholder: string;
  label: string;
  watchIndustry: string;
}) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        return (
          <FormItem className={cn("flex-1 w-full space-y-0", watchIndustry === "Other" ? "block" : "hidden")}>
            <p className={cn("font-saira text-14 font-[400] tracking-[-0.1px] leading-[1.7]", error && "text-red-500")}>
              {label}
            </p>
            <FormControl>
              <Input
                aria-label="other industry"
                placeholder={placeholder}
                className={cn("border-[#898A8D] rounded-[5px]", error && "border-red-500")}
                autoComplete="nope"
                {...field}
                aria-describedby={undefined}
              />
            </FormControl>
            <FormMessage className="font-saira text-14 font-[400]" />
          </FormItem>
        );
      }}
    />
  );
};

interface IOptionDuration {
  id: string;
  key: string;
  title: string;
  internalLabel: string;
  optionType: string;
}

export const FieldDropdown = ({
  control,
  name,
  placeholder,
  label,
  options,
}: {
  control: any;
  name: string;
  placeholder: string;
  label: string;
  options: IOptionDuration[];
}) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormItem className="flex-1 space-y-0">
          <p className={cn("font-saira text-14 font-[400] tracking-[-0.1px] leading-[1.7]", error && "text-red-500")}>
            {label}
          </p>
          <FormControl>
            <Select onValueChange={field.onChange} defaultValue={field.value}>
              <SelectTrigger
                className={cn(
                  "!mt-0 border-[#898A8D] placeholder:text-[#64748b] rounded-[5px]",
                  error && "border-red-500"
                )}
                ref={field.ref}
                title={label}
              >
                <SelectValue placeholder={placeholder} className="placeholder:text-[#64748b]" />
              </SelectTrigger>

              <SelectContent>
                {options.map((dur: IOptionDuration) => (
                  <SelectItem aria-label={dur.title} key={dur.id} value={dur.key}>
                    {dur.title}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </FormControl>
          <FormMessage className="font-saira text-14 font-[400]" />
        </FormItem>
      )}
    />
  );
};

export const FieldOptionsCards = ({
  group,
  control,
  name,
  handleCheckboxes,
  checkBoxCard,
  updateRadioButtons,
  radioCardsState,
}: {
  group: any;
  control: any;
  name: string;
  handleCheckboxes: (item: string, checked: boolean) => void;
  checkBoxCard: any;
  updateRadioButtons: (group: string, value: string, del: boolean) => void;
  radioCardsState: any;
}) => {
  const { cards = [] } = group;
  return (
    <>
      <FormField
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => {
          return (
            <FormItem className="flex-1 space-y-0 overflow-hidden">
              <div className="h-0 overflow-hidden">
                <FormControl>
                  <Input
                    aria-label="cards target"
                    {...field}
                    autoComplete="nope"
                    role="presentation"
                    className="hiddenInput"
                    aria-describedby={undefined}
                  />
                </FormControl>
              </div>
              {/* {error ? (

                <FormMessage className="font-saira text-14 font-[400] mb-3" />
              ) : (
                <p className="font-saira text-14 font-[400] mb-3">
                  {error?.message}
                </p>
              )} */}

              {/* <FormMessage className="font-saira text-14 font-[400] mb-3" /> */}
              <p className="font-saira text-14 font-[400] mb-3 text-red-500 formMessage_error">
                {error?.message || ""}
              </p>
              <div className={cn("sm:grid-cols-8 grid grid-cols-4 gap-3 pt-1")}>
                {cards.map(
                  ({
                    id,
                    name: cardName,
                    image,
                    heading,
                    options,
                    subheading,
                    label = "",
                    style,
                  }: {
                    id: string;
                    name: string;
                    image: string;
                    heading: string;
                    options: { id: string; key: string; value: string }[];
                    subheading: string;
                    style: string;
                    label?: string;
                  }) => {
                    if (style === "Checkboxes") {
                      return (
                        <FormCardCheckbox
                          key={id}
                          title={heading}
                          label={label}
                          img={image}
                          description={subheading}
                          checkboxes={options}
                          checkBoxCard={checkBoxCard}
                          handleCheckboxes={handleCheckboxes}
                          error={error}
                        />
                      );
                    } else {
                      return (
                        <FormCardRadio
                          key={id}
                          title={heading}
                          label={label}
                          img={image}
                          description={subheading}
                          updateRadioButtons={updateRadioButtons}
                          options={options}
                          groupName={cardName}
                          radioCardsState={radioCardsState}
                          error={error}
                        />
                      );
                    }
                  }
                )}
              </div>
            </FormItem>
          );
        }}
      />
    </>
  );
};

export const FormCardCheckbox = ({
  title,
  label,
  img,
  description,
  checkboxes,
  handleCheckboxes,
  checkBoxCard,
  error,
}: {
  title: string;
  label: string;
  img: string;
  description: string;
  checkboxes: any;
  handleCheckboxes: (item: string, checked: boolean) => void;
  checkBoxCard: any;
  error: FieldError | undefined;
}) => {
  return (
    <div
      data-focus-mark="card-mark"
      className={cn(
        "bg-navy-blue-10 lg:col-span-3 flex flex-col col-span-4 p-4 border-[1px] border-transparent",
        error && "border-red-500"
      )}
    >
      {label && (
        <AppText type="BODY_SMALL_EMPHASIS" className="text-royal-blue text-end font-[600] uppercase">
          {label}
        </AppText>
      )}
      <Image
        src={img}
        sizes="100vw"
        width={0}
        height={0}
        className="flex-1 object-cover w-full max-w-[220px] mx-auto"
        alt={title}
      />

      <div className="flex flex-col flex-1 mb-5">
        <AppText type="SUB_HEADING_SMALL" className="text-wrap text-18">
          {title}
        </AppText>
        <AppText type="BODY_SMALL_EMPHASIS" className="text-wrap font-[400]">
          {description}
        </AppText>
      </div>

      <fieldset className="checkBoxContainer flex flex-col">
        <legend className="hidden">radio buttons</legend>
        {checkboxes.map((item: { id: string; key: string; value: string }) => {
          return (
            <div key={item.id} className="flex flex-row items-center py-[2px] space-x-3 space-y-0">
              <input
                id={item.id}
                autoComplete="nope"
                type="checkbox"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckboxes(item.key, e.target.checked)}
                checked={checkBoxCard[item.key] || false}
                className={cn(
                  "min-w-4 shrink-0 bg-[#e6e9ef] cursor-pointer border-black border-[1px] w-4 h-4 focus-visible:ring-blue-500/50 ring-2 ring-transparent outline-none focus-visible:outline-none",
                  checkBoxCard[item.key] ? "accent-black" : "appearance-none"
                )}
              ></input>
              <label htmlFor={item.id} className="space-y-1 leading-none font-[400] font-saira text-15 cursor-pointer">
                {item.value}
              </label>
            </div>
          );
        })}
      </fieldset>
    </div>
  );
};

export const FormCardRadio = ({
  title,
  img,
  updateRadioButtons,
  options,
  groupName = "",
  radioCardsState,
  description,
  label,
  error,
}: {
  title: string;
  description: string;
  img: string;
  options: { id: string; key: string; value: string }[];
  updateRadioButtons: (group: string, value: string, del: boolean) => void;
  groupName: string | undefined;
  radioCardsState: any;
  label?: string;
  error: FieldError | undefined;
}) => {
  const handleRadioClick = (e: any) => {
    const value = e.target.value;

    /**
     * groupName is the name of the card inside state (work_and_collaborate, educate_train.....)
     * value is the value of the radio button (small, medium, large)
     * third argument is a boolean to determine if the radio button should be deleted (true) or added (false)
     * this logic is because of requirement to uncheck radio button (usually that's not possible, in group must be one checked)
     */
    if (radioCardsState[groupName]) {
      if (radioCardsState[groupName] === value) {
        updateRadioButtons(groupName, value, true);
      } else {
        updateRadioButtons(groupName, value, false);
      }
    } else {
      updateRadioButtons(groupName, value, false);
    }

    // if (value) {
    //   if (value === groupName) {
    //     // Check if the value is the same as the current value

    //     updateRadioButtons(title, value, true);
    //   } else {
    //     updateRadioButtons(title, value, false);
    //   }
    // } else {
    //   if (groupName) {
    //     updateRadioButtons(title, groupName, true);
    //   }
    // }
  };

  return (
    <div
      data-focus-mark="card-mark"
      className={cn(
        "bg-navy-blue-10 lg:col-span-3 flex flex-col col-span-4 p-4 border-[1px] border-transparent",
        error && "border-red-500"
      )}
    >
      {label && (
        <AppText type="BODY_SMALL_EMPHASIS" className="text-royal-blue text-end font-[600] uppercase">
          {label}
        </AppText>
      )}

      {/** add support for blur when refactoring to contentful data */}
      <Image
        src={img}
        sizes="100vw"
        width={0}
        height={0}
        className="flex-1 object-cover w-full max-w-[220px] mx-auto"
        alt={title}
      />

      <div className="flex flex-col flex-1 mb-5">
        <AppText type="SUB_HEADING_SMALL" className="text-wrap text-18">
          {title}
        </AppText>
        <AppText type="BODY_SMALL_EMPHASIS" className="text-wrap font-[400]">
          {description}
        </AppText>
      </div>
      <fieldset className="flex flex-col" id={title}>
        <legend className="hidden">{title}</legend>

        {options.map((item) => {
          // const id = `radio-group-${item}`;

          return (
            <div role="radiogroup" key={item.id} className="flex flex-row items-center py-[2px] space-x-3 space-y-0">
              <input
                id={item.id}
                autoComplete="nope"
                type="radio"
                checked={radioCardsState[groupName] === item.key}
                name={title}
                value={item.key}
                onChange={() => {}}
                onClick={handleRadioClick}
                className="min-w-4 focus-visible:ring-blue-500/50 ring-2 ring-transparent focus-visible:outline-none w-4 h-4 outline-none"
              />
              <label className="font-[400] font-saira text-15 cursor-pointer" htmlFor={item.id}>
                {item.value}
              </label>
            </div>
          );
        })}
      </fieldset>
    </div>
  );
};
