import React, { ChangeEvent, useEffect, useState } from "react";
import { cn } from "@/lib/utils";

import { Close20, Search20 } from "@/assets/Icons";

export interface SearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onSearch: (value: string) => void;
  clearSearch?: number;
}

const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(
  ({ className, type, onSearch, clearSearch, ...props }, ref) => {
    const [value, setValue] = useState("");

    useEffect(() => {
      if (!clearSearch) return;

      setValue("");
    }, [clearSearch]);

    const searchHandler = (event: ChangeEvent<HTMLInputElement>) => {
      const { target } = event;
      setValue(target.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        onSearch(value);
      }
    };

    const handleClick = () => onSearch(value);

    const handleClear = () => {
      setValue("");
      onSearch("");
    };

    return (
      <div
        className={`relative z-0 flex h-[48px] rounded-[6px] border border-black-20 
        bg-white px-3 py-2 text-sm  file:border-0 
        file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 
        focus-within:ring-2 ring-royal-blue ring-offset-2 ring-offset-black-20
        disabled:cursor-not-allowed disabled:opacity-50`}
      >
        <input
          aria-label="search keyword"
          type={type}
          className={cn(
            "w-[calc(100%-65px)] outline-none font-saira text-16 font-[500] leading-[1.75] tracking-[-0.1px]",
            className
          )}
          ref={ref}
          value={value}
          onChange={searchHandler}
          placeholder="Search keyword..."
          onKeyDown={handleKeyDown}
          {...props}
        />
        {value && (
          <button
            id="button-search-clear"
            title="clear"
            onClick={handleClear}
            className="right-[50px] top-[18px] fill-slate-60 outline-none absolute focus:ring-1 ring-offset-4"
          >
            <Close20 />
          </button>
        )}
        <button
          id="button-search"
          title="search"
          onClick={handleClick}
          className="right-[18px] top-[17px] fill-slate outline-none absolute focus:ring-1 ring-offset-4"
        >
          <Search20 />
        </button>
      </div>
    );
  }
);

SearchInput.displayName = "SearchInput";

export { SearchInput };
