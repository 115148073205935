import { WS_PLACEHOLDER_BLUR_DATA } from "@/mocks/constants";

/* eslint-disable @typescript-eslint/no-explicit-any */
export const parseDocuments = async (documents: any, read_more: string, download: string) => {
  const parsed = await Promise.all(
    documents.map(async (doc: any) => {
      const img = doc.fields?.featuredImage?.[0]?.thumbnails?.transformBaseUrl;

      const tmpObj: any = {
        id: doc.sys?.id || "",
        title: doc.fields?.name,
        imgSrc: img ?? "/placeholders/doc-placeholder.png",
        placeholder: WS_PLACEHOLDER_BLUR_DATA,
        imgAlt: doc.fields?.featuredImage?.[0]?.description || "image",
        type: "doc",
      };

      if (doc.fields?.showAs === "Document") {
        tmpObj.articleUrl = doc.fields?.downloadLink;
        tmpObj.buttonLabel = download;
      } else if (doc.fields?.showAs === "Document Article") {
        tmpObj.articleUrl = `/resources/documents/${doc.fields?.slug}`;
        tmpObj.buttonLabel = read_more;
        if (tmpObj.imgSrc === "/placeholders/doc-placeholder.png") {
          tmpObj.imgSrc = "/placeholders/no-preview.png";
        }
      }

      return tmpObj;
    })
  );

  return parsed;
};
