/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import Image from "next/image";
import { SwiperSlide } from "swiper/react";

import AppText from "@/components/other/AppText";
import { cn } from "@/lib/utils";
import Carousel from "@/components/other/Carousel";
import { customDebounce } from "@/lib/customDebounce";

interface Step {
  id: string;
  heading?: string;
  subheading?: any;
  imageSrc: string;
  imageAlt: string;
  placeholder: string;
}
interface StepByStepProps {
  id: string;
  cards: Step[];
  imagePriority: number;
  numberPriority: number;
  textPriority: number;
  isMoreThanFour: boolean;
  paddingBottom?: string;
}

const StepByStep: React.FC<StepByStepProps> = ({
  cards,
  id,
  imagePriority,
  numberPriority,
  textPriority,
  isMoreThanFour,
  paddingBottom,
}) => {
  const duration = 500;
  const divRef = useRef<HTMLDivElement>(null);
  const [startAnimation, setStartAnimation] = useState(false);
  const timeoutRefs = useRef<NodeJS.Timeout[]>([]);
  const [stepsAnimation, setStepsAnimation] = useState<boolean[]>(Array.from(Array(cards.length), () => false));

  const activeStepAnimation = `transition-opacity duration-500 opacity-100`;
  const inactiveStepAnimation = "opacity-0";

  const [windowWidth, setWindowWidth] = useState(0);
  const [slidesOffsetBefore, setSlidesOffsetBefore] = useState(0);

  const debouncedHandleResize = customDebounce(() => {
    setWindowWidth(window.innerWidth);
  }, 300);

  const carouselBreakpoints = useMemo(
    () =>
      isMoreThanFour
        ? {
            320: {
              slidesPerView: 1.3,
              spaceBetween: 20,
            },
            475: {
              slidesPerView: 2.1,
            },
            640: {
              slidesPerView: 2.7,
            },
            786: {
              slidesPerView: 3.1,
            },
            1024: {
              slidesPerView: 4.1,
            },
            1280: {
              slidesPerView: 5,
            },
          }
        : {
            320: {
              slidesPerView: 1.3,
              spaceBetween: 20,
            },
            475: {
              slidesPerView: 2.1,
            },
            640: {
              slidesPerView: 2.7,
            },
            786: {
              slidesPerView: 3.1,
            },
            1024: {
              slidesPerView: 4,
            },
            1280: {
              slidesPerView: 4,
            },
          },
    [isMoreThanFour]
  );

  // we need to calculate window with initially and on every window resize
  useLayoutEffect(() => {
    debouncedHandleResize();

    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // shift slides to the left based on window with, to match general layout
  useLayoutEffect(() => {
    if (windowWidth) {
      if (windowWidth < 1024) setSlidesOffsetBefore(16);
      else if (windowWidth < 1280) setSlidesOffsetBefore(32);
      else if (windowWidth < 1536) setSlidesOffsetBefore(80);
      else setSlidesOffsetBefore(Math.round((windowWidth - 1536) / 2) + 104);
    }
  }, [windowWidth]);

  useEffect(() => {
    if (startAnimation) {
      return;
    }
    const handleScroll = () => {
      if (divRef.current && divRef.current?.getBoundingClientRect()?.top < 500) {
        setStartAnimation(true);
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [startAnimation]);

  useEffect(() => {
    if (!startAnimation) {
      return;
    }
    stepsAnimation.forEach((step, index) => {
      timeoutRefs.current.push(
        setTimeout(() => {
          setStepsAnimation((prevState) => {
            const newState = [...prevState];
            newState[index] = true;
            return newState;
          });
        }, index * duration)
      );
    });

    return () => {
      timeoutRefs.current.forEach(clearTimeout);
      timeoutRefs.current.length = 0;
    };
  }, [startAnimation]);

  const renderStep = (card: Step, index: number) => {
    const animate = stepsAnimation[index];
    const stepIndex = index + 1;

    const orderMap = {
      [imagePriority]: (
        <div className="mt-6 relative aspect-[0.82] w-[223px] 2xl:w-[250px]">
          <Image src={card.imageSrc} alt={card.imageAlt} fill sizes="350px" className="object-cover w-full" />
        </div>
      ),
      [numberPriority]: (
        <div className="relative flex items-center justify-center my-6">
          <div className={"h-[2px] w-full bg-mellow absolute z-0 top-[14px] left-0"} />
          <AppText
            type={"BODY_MEDIUM_EMPHASIS"}
            className={
              "relative z-20 h-[28px] w-[28px] rounded-full bg-mellow flex flex-col items-center justify-center"
            }
          >
            {stepIndex}
          </AppText>
        </div>
      ),
      [textPriority]: (
        <div className="flex flex-col h-full pr-5 mt-6">
          <AppText type="SUB_HEADING_MEDIUM" className={"text-white text-balance"}>
            {card.heading}
          </AppText>
          <AppText type="BODY_SMALL" className={"text-white mt-3 text-balance"}>
            {card.subheading}
          </AppText>
        </div>
      ),
    };

    return (
      <SwiperSlide key={card.id}>
        <div
          className={cn(
            "relative ",
            textPriority === 3 ? "flex flex-col h-full" : "grid grid-rows-[auto_1fr_auto] h-full",
            animate ? activeStepAnimation : inactiveStepAnimation
          )}
        >
          {[1, 2, 3].map((priority) => (
            <div key={priority} className={cn(`order-${priority}`, textPriority === 1 && priority === 2 && "mt-auto")}>
              {orderMap[priority]}
            </div>
          ))}
        </div>
      </SwiperSlide>
    );
  };

  return (
    <>
      {isMoreThanFour && slidesOffsetBefore > 0 ? (
        <div className={cn("bg-royal-blue relative", paddingBottom)}>
          <div id={id} ref={divRef} className="relative flex pb-[80px] bg-royal-blue  w-full mx-auto">
            <Carousel
              withoutWrapper={true}
              className={"flex-1 flex items-stretch"}
              slidesPerView={1}
              cardsOverflow={true}
              paginationType={"none"}
              slidesOffsetBefore={slidesOffsetBefore}
              breakpoints={carouselBreakpoints}
            >
              {cards.map(renderStep)}
            </Carousel>
          </div>
        </div>
      ) : !isMoreThanFour ? (
        <div className={cn("bg-royal-blue relative", paddingBottom)}>
          <div
            id={id}
            ref={divRef}
            className={cn(
              `relative flex pb-[80px] 2xl:px-[104px] xl:px-20 lg:px-8 pl-4
              xl:max-w-screen-2xl w-full mx-auto`
            )}
          >
            <Carousel
              withoutWrapper={true}
              className={"flex-1 flex items-stretch"}
              slidesPerView={1}
              spaceBetween={0}
              cardsOverflow={true}
              paginationType={"none"}
              breakpoints={carouselBreakpoints}
            >
              {cards.map(renderStep)}
            </Carousel>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default React.memo(StepByStep);
