/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/navigation";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "sonner";
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import debounce from "lodash/debounce";

import { Form } from "@/components/ui/form";
import AppText from "@/components/other/AppText";
import AppButton from "@/components/buttons/AppButton";
import { checkZippopotam, preparePayloadRAQColumnForm } from "@/components/form/helpers";
import { FieldDropdown, FieldPhone, FieldText, FieldTextArea } from "./FormElements";
import { createDynamicSchemaColumnForm } from "./schemaHelper";
// import { FormFooterRecaptchaPolicy } from "../recaptcha/FormFooterRecaptchaPolicy";
import { cn } from "@/lib/utils";

import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../../tailwind.config";
import { isPostalCode } from "validator";

const fullConfig = resolveConfig(tailwindConfig);

type Props = {
  id: string;
  shade?: string;
  colorObj?: string;
  originalFields: any;
  zipName?: string;
  submitCtaLabel?: string;
  submitCtaWidth?: string;
  thankYouPage?: any;
};

// !!  recapcha logic is commented out

const ColumnForm = ({
  id,
  shade,
  colorObj,
  originalFields,
  zipName,
  submitCtaLabel,
  submitCtaWidth,
  thankYouPage,
}: Props) => {
  // const { executeRecaptcha } = useGoogleReCaptcha();
  const formSchema = z.object({});
  const [submitting, setSubmitting] = useState(false);
  const [dynamicFormSchema, setDynamicFormSchema] = useState<z.ZodSchema>(formSchema);

  const router = useRouter();

  const formDefaultValues = useMemo(() => {
    const res: any = {};

    (originalFields || []).forEach((field: any) => {
      res[field.name] = "";
    });

    return res;
  }, [originalFields]);

  const form = useForm<z.infer<typeof dynamicFormSchema>>({
    resolver: zodResolver(dynamicFormSchema),
    defaultValues: formDefaultValues,
    mode: "onChange",
  });

  const watchZipName = form.watch(zipName || "NOT_FOUND_ZIP_NAME");

  const updateSchema = () => {
    if (!zipName) return;

    const zObj = createDynamicSchemaColumnForm(originalFields, zipName);

    const updatedSchema = z.object({
      ...zObj,
      [zipName]: z
        .string()
        .optional()
        .refine(
          async (value: any) => {
            const isUsPostal = isPostalCode(value.trim(), "US");
            const isCaPostal = isPostalCode(value.trim(), "CA");

            if (isUsPostal) {
              const newZip = value.replaceAll(" ", "").replaceAll("-", "");
              const usZip = newZip.slice(0, 5); // Zippopotam accepts only first 5 chars for US

              const isValidZippopotamUS = await checkZippopotam(usZip, "us");

              if (!isValidZippopotamUS.valid) {
                form.setError(zipName, { message: "Invalid ZIP code" });
                return false;
              }

              return true;
            } else if (isCaPostal) {
              const newZip = value.replaceAll(" ", "").replaceAll("-", "");
              const caZip = newZip.slice(0, 3); // Zippopotam accepts only first 3 chars for Canada

              const isValidZippopotamCA = await checkZippopotam(caZip, "ca");

              if (!isValidZippopotamCA.valid) {
                form.setError(zipName, { message: "Invalid ZIP code" });
                return false;
              }

              return true;
            } else {
              const newZip = value.replaceAll(" ", "").replaceAll("-", "");

              if (newZip.length === 9) {
                const usZip = newZip.slice(0, 5); // Zippopotam accepts only first 5 chars for US

                const isValidZippopotamUS = await checkZippopotam(usZip, "us");

                if (!isValidZippopotamUS.valid) {
                  form.setError(zipName, { message: "Invalid ZIP code" });
                  return false;
                }

                return true;
              } else {
                form.setError(zipName, { message: "Invalid ZIP code" });
                return false;
              }
            }
          },
          {
            message: "Invalid ZIP code",
            // path: [zipName],
          }
        ),
    });

    setDynamicFormSchema(updatedSchema);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(
    debounce(() => {
      updateSchema();
    }, 500),
    []
  );

  useEffect(() => {
    debounceFn();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchZipName]);

  useEffect(() => {
    const createDynamicSchema = () => {
      const zObj = createDynamicSchemaColumnForm(originalFields, zipName);

      const formSchema = z.object({
        ...zObj,
      });

      setDynamicFormSchema(formSchema);
    };

    createDynamicSchema();
  }, [originalFields, zipName]);

  async function onSubmit() {
    // if (!executeRecaptcha) {
    //   toast.error("Error submitting request - reCAPTCHA not loaded");
    //   return;
    // }

    setSubmitting(true);

    const formValues = form.getValues();

    // ! recapcha logic - disabled for now
    /*  const token = await executeRecaptcha("submit_request_quote");
    
    const recaptchaResponse = await fetch("/api/recaptcha", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token }),
    });

    const recaptchaData = await recaptchaResponse.json();

    if (!recaptchaData.success) {
      console.log(recaptchaData);
      toast.error("reCAPTCHA verification failed");
      setSubmitting(false);
      return;
    }

    console.log("reCAPTCHA verification successful", recaptchaData);
    */

    const payload = await preparePayloadRAQColumnForm(formValues);

    try {
      const response = await fetch("/api/request-quote", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      // request is valid if data is empty string
      if (data.error || data.message) {
        console.log(data);
        toast.error("Error submitting request");
        setSubmitting(false);
      } else {
        router.push(thankYouPage?.slug ? thankYouPage.slug : "/request-quote/thank-you");
      }
    } catch (error) {
      console.log(error);
      setSubmitting(false);
    }
  }

  const handleFormSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e.button > 0) return;

    const btn = document.getElementById("targetSubmit");

    btn && btn.click();
  };

  const getBgColor = () => {
    if (shade && colorObj) {
      const colors = (fullConfig.theme.colors as any)[colorObj]?.[shade] || "#E6EFF8";
      return colors;
    }
    return "#E6EFF8";
  };

  return (
    <div
      id={id}
      className={cn("w-full p-6")}
      style={{
        backgroundColor: getBgColor(),
      }}
    >
      <Form {...form}>
        <form className="flex flex-col gap-6" autoComplete="off" onSubmit={form.handleSubmit(onSubmit)}>
          {(originalFields || []).map((field: any) => {
            if (field.itemType === "fieldText") {
              return (
                <FieldText
                  key={field.id}
                  control={form.control}
                  name={field.name}
                  placeholder={field.placeholder || ""}
                  loading={false}
                  label={field.label}
                />
              );
            } else if (field.itemType === "fieldPhone") {
              return (
                <FieldPhone
                  key={field.id}
                  control={form.control}
                  name={field.name}
                  placeholder={field.placeholder || ""}
                  loading={false}
                  label={field.label}
                />
              );
            } else if (field.itemType === "fieldCalendar") {
              //   return (
              //     <FieldCalendar
              //       key={field.id}
              //       control={form.control}
              //       name={field.name}
              //       placeholder={field.placeholder}
              //       loading={false}
              //       label={field.label}
              //     />
              //   );
            } else if (field.itemType === "fieldTextArea") {
              return (
                <FieldTextArea
                  key={field.id}
                  control={form.control}
                  name={field.name}
                  loading={false}
                  placeholder={field.placeholder || ""}
                  label={field.label}
                />
              );
            } else if (field.itemType === "fieldDropdown") {
              return (
                <FieldDropdown
                  key={field.id}
                  control={form.control}
                  name={field.name}
                  placeholder={field.placeholder || ""}
                  label={field.label}
                  options={field.options}
                />
              );
            } else if (field.itemType === "fieldEmail") {
              return (
                <FieldText
                  key={field.id}
                  control={form.control}
                  name={field.name}
                  placeholder={field.placeholder || ""}
                  loading={false}
                  label={field.label}
                />
              );
            }
            return null;
          })}

          <button id="targetSubmit" className="hidden" aria-label="submit target" type="submit"></button>

          <div className="flex justify-center w-full mt-4 mb-8">
            {submitting ? (
              <button
                id="button-request-quote-buy"
                className={cn(
                  `bg-black-20 text-black-60 disabled:cursor-not-allowed py-3
               transition-all duration-200 rounded-md outline-none gap-3 px-10 shrink-0 text-16 font-[500] tracking-[0.1px] leading-[1.5] 
               !font-sairaSemiCondensed select-none flex items-center justify-center`,
                  submitCtaWidth === "full" ? "w-full max-w-full" : ""
                )}
                disabled
              >
                <div
                  className={`inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid 
                  border-current border-e-transparent align-[-0.125em] 
                  motion-reduce:animate-[spin_1.5s_linear_infinite]`}
                ></div>
                <AppText type={"BUTTON_SMALL"} className={cn("transition whitespace-nowrap opacity-60")}>
                  Submitting...
                </AppText>
              </button>
            ) : (
              <AppButton
                type="button"
                label={submitCtaLabel || "Submit"}
                className={cn(
                  `text-16 font-[500] 
                  tracking-[0.1px] leading-[1.5] !font-sairaSemiCondensed`,
                  submitCtaWidth === "full" ? "w-full max-w-full" : "!max-w-[148px] !w-[148px] shrink-0"
                )}
                disabled={submitting}
                fullLength={submitCtaWidth === "full"}
                onMouseDown={handleFormSubmit}
              />
            )}
          </div>
        </form>
      </Form>

      {/* <FormFooterRecaptchaPolicy /> */}
    </div>
  );
};

export default ColumnForm;
