"use client";

import React from "react";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import { colorsMap, fallbackColors } from "./constants";
import { cn } from "@/lib/utils";

type Props = {
  id: string;
  paddingTop?: number;
  paddingBottom?: number;
  background: string;
  youtubeVideoId?: string;
  video?: string;
};

const VideoSingleBlock = ({ id, paddingTop, paddingBottom, background, youtubeVideoId, video }: Props) => {
  const { bgColorClass } = colorsMap[background] ?? fallbackColors;

  return (
    <div
      id={id}
      className={cn("w-full flex flex-col justify-center", {
        [bgColorClass]: bgColorClass,
      })}
    >
      <div
        className={cn(
          `w-full mx-auto xl:max-w-screen-2xl lg:max-w-screen-xl sm:max-w-screen-md md:max-w-screen-lg
      sm:py-20 py-12 px-4 lg:px-8 xl:px-20 2xl:px-[104px]`
        )}
        style={{
          paddingTop: paddingTop ? `${paddingTop / 4}rem` : "",
          paddingBottom: paddingBottom ? `${paddingBottom / 4}rem` : "",
        }}
      >
        {youtubeVideoId ? (
          <LiteYouTubeEmbed id={youtubeVideoId} title={""} iframeClass="w-full" />
        ) : video ? (
          <video controls={true} className="w-full" preload="auto" playsInline={true} autoPlay={false}>
            <source src={video} type="video/mp4" />
          </video>
        ) : null}
      </div>
    </div>
  );
};

export default VideoSingleBlock;
